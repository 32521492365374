import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Support} from '../../../models/support.model';
import {SupportService} from '../../../core/services/support.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Sign} from '../../../models/sign.model';
import {routerLink} from '../../../../assets/config/routerLink';
import {UserService} from "../../../core/services/user.service";
import {Code, SelectCode} from "../../../models/code.model";
import {Message} from "primeng/components/common/api";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {SignService} from "../../../core/services/sign.service";
import {LayoutComponent} from "../../layout/layout.component";
import {SupportTypeService} from "../../../core/services/support-type.service";
import {SupportGestionService} from "../../../core/services/support-gestion.service";
import {ConfirmationService} from "primeng/api";
import {SupportSpecificityService} from "../../../core/services/support-specificity.service";
import {LayoutService} from '../../../core/services/layout.service';
import {AutoComplete} from 'primeng/primeng';
import {SupportUpdate} from '../../../models/support-update.model';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SignOrderService} from '../../../core/services/sign-order.service';
import {RcService} from '../../../core/services/rc.service';
import {Rc} from '../../../models/rc.model';
import { MapService } from '../../../core/services/map.service';
import * as ol from 'openlayers';
import {SignComponent} from '../../signs/sign/sign.component';

@Component({
   selector: 'support',
   templateUrl: './support.component.html',
   styleUrls: ['./support.component.scss'],
   providers: [ConfirmationService]
})

export class SupportComponent implements OnInit
{
  translate: TranslateService = this.injector.get(TranslateService);

  get id(): number{
    return this.supportService.support ? this.supportService.support.id : null;
  };

  _editSupport: Support = new Support({});

  get editSupport():Support{
    return this._editSupport;
  }
  set editSupport(support:Support){
    this._editSupport = new Support(support);
    if(this.supportService.editMode){
      if(!this.editSupport.orientation){
        this.editSupport.orientation = 0;
      }
      if(!this.editSupport.translation){
        this.editSupport.translation = 0;
      }
      if(!this.editSupport.gestion){
        this.editSupport.gestion = this.supportGestionService.default;
      }
      if(!this.editSupport.specificity){
        this.editSupport.specificity = this.supportSpecificityService.default;
      }
      if(!this.editSupport.type){
        this.editSupport.type = this.supportTypeService.default;
      }
    }else{
      this.updateSupportSigns();
    }
  }

  typeOther: string = 'OTHERS';


  placementDate:Date = null;
  removalDate:Date = null;

  oldSigns:Sign[] = new Array();


  supportForm: FormGroup;

  errorMessages: Message[] = [];


  /*------SELECT OPTIONS------*/
  supportGestionOptions: SelectCode[] = new Array();
  supportTypeOptions: SelectCode[] = new Array();
  supportSpecificityOptions: SelectCode[] = new Array();

  supportChanges:Subscription;

  constructor(private supportService: SupportService,
              private userService: UserService,
              private router: Router,
              private fb: FormBuilder,
              private signService: SignService,
              private supportTypeService: SupportTypeService,
              private supportGestionService: SupportGestionService,
              private supportSpecificityService: SupportSpecificityService,
              protected layout: LayoutComponent,
              private route: ActivatedRoute,
              private layoutService: LayoutService,
              private confirmationService: ConfirmationService,
              private injector: Injector,
              private mapService:MapService,
              private signOrderService: SignOrderService) {
  }

  ngOnInit() {

    this.initComponent();

    this.supportForm = this.fb.group({
      geom: this.fb.control(''),
      supportGestion: this.fb.control(''),
      supportType: this.fb.control(''),
      typeOthers: this.fb.control(''),
      supportSpecificity: this.fb.control(''),
      heightOffGround: this.fb.control(''),
      placementDate: this.fb.control(''),
      removalDate: this.fb.control(''),
      address: this.fb.control(''),
      number: this.fb.control(''),
      note: this.fb.control(''),
      other: this.fb.control(''),
      orientation: this.fb.control(''),
      translation: this.fb.control(''),
    });

    this.supportChanges = this.supportService.supportHandler.subscribe((support: Support) => {
      this.updateSupportSigns();
      this.update_date();
    });
    this.update_date();

  }

  ngOnDestroy() {
    this.supportChanges.unsubscribe();
  }

  async initComponent(){
    this.layoutService.loading = true;
    try{
      await this.loadLists();
      if(!this.supportService.support){
        this.supportService.support = new Support({});
      }
      this.editSupport = new Support(this.supportService.support);
      console.info('GEOM',this.editSupport.geom);
      if(this.supportService.support.id){
        /*
        let rcs = await this.rcService.getRCForSupportId(this.supportService.support.id);
        this.rcsPending = rcs.filter(rc => rc.status == "PENDING");
        this.rcsOk = rcs.filter(rc => rc.status == "ACTIVE");
        this.rcsRemoved = rcs.filter(rc => rc.status == "OLD");
        */
      }else{
        let point:any = JSON.parse(this.editSupport.geom);
        let address = await this.supportService.getAddressFromXY(this.translate.currentLang,point.coordinates[0],point.coordinates[1]);

        console.info(address);
      }
      this.showSupportOnMap();
    }catch(e){

    }
    this.layoutService.loading = false;

  }


  async loadLists(){
    return await Promise.all([
      this.loadCode(this.supportGestionService,this.supportGestionOptions, null),
      this.loadCode(this.supportTypeService,this.supportTypeOptions, null),
      this.loadCode(this.supportSpecificityService,this.supportSpecificityOptions, null)
    ]);
  }

  async loadCode(service, options: SelectCode[], empty:string):Promise<undefined>{
    let values:Code[] = await service.findAll();
    values = empty?[new Code(null,empty)].concat(values):values;
    let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
    translatedValues.sort((a, b) => {
      if(a.label>b.label){
        return 1;
      }else if(a.label<b.label){
        return -1;
      }else{
        return 0;
      }
    });
    translatedValues.forEach(val => options.push(val));
    // await Promise.all(values.map(async val=>
    //   options.push(new SelectCode(await this.translate.get(val.code).toPromise(), val))
    // ));
    return;
  }

  update_date(){
    if(this.supportService.support && this.supportService.support.placementDate){
      this.placementDate = new Date(this.supportService.support.placementDate);
    }
    if(this.supportService.support && this.supportService.support.removalDate){
      this.removalDate = new Date(this.supportService.support.removalDate);
    }
  }

  async updateSupportSigns(){
    if(this.supportService.support && this.supportService.support.id) {
      try{
        this.layoutService.loading = true;
        let signs = await this.signService.getSignsForSupportId(this.supportService.support.id);
        this.signOrderService.signs = new Array();
        for (let sign of signs) {
          this.signOrderService.signs.push(new Sign(sign));
        }
      }catch(error){
        //TODO error handling
      }
      this.layoutService.loading = false;
    }else{
      this.signOrderService.signs = new Array();
    }
  }

  edit(){
    this.supportService.editMode = true;
    this.supportVectorModify.on('modifyend',(e:ol.interaction.Modify.Event)=>{
      let feature: ol.Feature = e.features.getArray()[0];
      let geometry = <ol.geom.Point> feature.getGeometry();
      let coords = geometry.getCoordinates();
      let geom = '{"type":"Point","coordinates":['+coords[0]+','+coords[1]+']}';
      this.editSupport.geom = geom;
    });
    this.mapService.olMap.addInteraction(this.supportVectorModify);
  }


  async submit(){

    if(!this.supportForm.invalid){
      this.errorMessages = [];

      if(this.editSupport.placementDate && this.editSupport.removalDate && this.editSupport.placementDate > this.editSupport.removalDate)
      {
        console.error('error entre les dates');
      }

      //Si c'est un nouveau support, il faut absolument lui associer une signalisation.
      if(this.editSupport.id === undefined || this.editSupport.id === null){
        this.createNew();
      }else{
        this.update();
      }

    }

  }

  createNew(){
    this.signService.newSupport = this.editSupport;
    this.signService.editMode = true;
    this.signService.sign = new Sign({});
    this.layoutService.rightPanelContent = SignComponent;
    this.layoutService.rightPanelVisible = true;

  }

  async update(){
    let supportUpdate = new SupportUpdate(this.editSupport);
    supportUpdate.signOrders = this.signOrderService.signs && this.signOrderService.signs.map(s=>{
      return {order:s.order,id:s.id};
    });


    if(this.signService.signOrder) {
      supportUpdate.signOrders = this.signService.signOrder;
    }

    try{
      let support:Support = await this.supportService.createOrUpdate(supportUpdate);
      this.editSupport = new Support(this.supportService.support);
      this.updateSupportSigns();
      this.supportService.editMode = false;
    }catch(error){
      if(typeof(error.error) == typeof("string")){
        this.errorMessages.push({severity:'error', summary:'Error', detail:error.error});
      }else{
        this.errorMessages.push({severity:'error', summary:'Error', detail:error.message});
      }
    }
  }

  cancel(){
    if(this.supportService.editMode == true && this.editSupport.isEqual(this.supportService.support)){
      console.log("support has change...");
    }
    this.supportService.editMode = false;

    this.mapService.olMap.removeInteraction(this.supportVectorModify);
  }

  remove(){
    this.confirmationService.confirm({
      message: '',
      accept: () => {
        this.confirmationRemove();
      }
    });
  }

  async confirmationRemove(){
    try{
      let rep = await this.supportService.deleteSupport(this.supportService.support.id);
      this.router.navigate([routerLink.map]);
    }catch(error){
      if(typeof(error.error) == typeof("string")){
        this.errorMessages.push({severity:'error', summary:'Error', detail:error.error});
      }else{
        this.errorMessages.push({severity:'error', summary:'Error', detail:error.message});
      }
    }
  }
  supportVectorSource: ol.source.Vector=new ol.source.Vector({wrapX: false});
  supportVectorLayer: ol.layer.Vector=new ol.layer.Vector({
    source: this.supportVectorSource,
    style:new ol.style.Style({
      image: new ol.style.Circle({
        radius: 5,
        fill: new ol.style.Fill({color: '#ffee00'}),
        stroke: new ol.style.Stroke({color: '#ffee00', width: 1})
      })
    })
  });
  supportVectorModify = new ol.interaction.Modify({source: this.supportVectorSource});

  showSupportOnMap(){
      let map:ol.Map=this.mapService.olMap;
      map.addLayer(this.supportVectorLayer);
      this.supportVectorSource.clear();
      let geom:any = JSON.parse(this.editSupport.geom);
      let feature:ol.Feature = new ol.Feature(new ol.geom.Point(<[number,number]>geom.coordinates));
      this.supportVectorLayer.setZIndex(20);
      this.supportVectorSource.addFeature(feature);
  }

  public async search(event: any) {
    try{
      let res = await this.supportService.getStreets(this.translate.currentLang,event.query);
      let data:any[]=res.result.map(resVal=>{
        let street = resVal.address.street;
        street.label = street.name + ' ( '+street.postCode+' )';
        return street;
      });

      if(data && data.length == 0) this.newData = event.query;
      else this.newData = null;

      this.results = data;
    }catch(error){
      //TODO error handling
    }
  }
  set editAddress(street:any){
    this.editSupport.address=JSON.stringify(street);
  }
  get editAddress():any{
    let address:any;
    try{
      address=JSON.parse(this.editSupport.address);
    }catch(e){

    }
    return address;
  }
  newData:any;
  results:string[]=[];
  @ViewChild('autoComplete')
  autoComplete:AutoComplete;

  close(){
    this.layoutService.closeRightPanel();
  }

  activeRepositioning(){
    alert("NOT IMPLEMENTED");
  }
  activeOrientation(){
    alert("NOT IMPLEMENTED");
  }
  activeTranslating(){
    alert("NOT IMPLEMENTED");
  }

}
