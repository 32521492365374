import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import {LayoutService} from '../../../core/services/layout.service';
import { ContainerComponent } from '../../../shared/components/container/container.component';
import { Observable } from 'rxjs';
import { UserService } from '../../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import {OauthService} from '../../../core/services/oauth.service';

@Component({
  selector: 'language-icon',
  templateUrl: './layout.language-icon.component.html',
  styleUrls: ['./layout.language-icon.component.scss']
})
export class LanguageIconComponent{
  activeActionItem:any;
  constructor(public userService:UserService,
              public i18n:TranslateService) {
  }

  get isMultiLang() : boolean {
    return (this.i18n.langs && this.i18n.langs.length > 1);
  }

  onLangChange(selectedLang:string) {
    this.userService.changeUserLanguage(selectedLang.toUpperCase());
    this.i18n.use(selectedLang);
  }
  changeLanguage(lang:string) {
    let index =(this.i18n.langs.findIndex(lang=>lang==this.i18n.currentLang)+1)%this.i18n.langs.length;
    this.onLangChange(lang);
  }
  get frSelected():boolean{
    return this.i18n.currentLang=="fr";
  }
  
  get nlSelected():boolean{
    return this.i18n.currentLang=="nl";
  }
  ngOnInit() {
    
  }
}
