
import {SignType} from './code.model';

export class SignTemplate{

  public signType?: SignType;
  public rcTemplate?: string[];
  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
    if(obj && obj.signType){
      this.signType = new SignType(obj.signType.id, obj.signType.code, obj.signType.typeCategorie, obj.signType.generic);
    }
  }

}
