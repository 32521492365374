import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {LayoutService} from "../../../core/services/layout.service";
import {MapService} from "../../../core/services/map.service";
import {SupportService} from "../../../core/services/support.service";
import {TranslateService} from "@ngx-translate/core";
import {MobileWizardComponentStep} from "../mobile-wizard-step.component";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import * as ol from 'openlayers';
import Utils from "../../../shared/services/utils";
import {UserService} from "../../../core/services/user.service";
import {MunicipalityService} from "../../../core/services/municipality.service";


@Component({
  selector: 'support-geometry-selection',
  templateUrl: './support-geometry-selection.component.html'
})
export class SupportGeometrySelectionComponent extends MobileWizardComponentStep implements OnInit, OnDestroy
{

  @Input()
  support: Support;

  map: ol.Map;

  mapPositionSelected: boolean;

  translate: TranslateService = this.injector.get(TranslateService);

  currentCoord = null;

  onMapClickedCallback: any;

  highlightVectorSource: ol.source.Vector=new ol.source.Vector({wrapX: false});
  highlightVectorLayer: ol.layer.Vector=new ol.layer.Vector({
    source: this.highlightVectorSource,
    style:new ol.style.Style({
      image: new ol.style.Circle({
        radius: 5,
        fill: new ol.style.Fill({color: '#00ffff'}),
        stroke: new ol.style.Stroke({color: '#00ffff', width: 1})
      })
    })
  });

  constructor(layoutService: LayoutService,
              private mapService: MapService,
              private supportService: SupportService,
              private userService: UserService,
              private injector: Injector,
              private municipalityService: MunicipalityService,
              confirmationDialogService: ConfirmationDialogService) {
    super(layoutService, confirmationDialogService);
  }

  ngOnInit() {
    try{
      this.mapPositionSelected = this.support.geom != null;
      this.map = this.mapService.olMap;
      this.map.addLayer(this.highlightVectorLayer);
      this.sendValidation(this.mapPositionSelected);
      this.onMapClickedCallback = this.mapClickedCallBack.bind(this);
      this.mapService.allowAddSupportHandler.subscribe(res => {
        if (res) {
          this.layoutService.rightPanelVisible = true;
          this.highlightVectorSource.clear();
        }
      })
    }catch(e){
      this.mapService.allowAddSupportFromButton = true;
      this.confirmationDialogService.yesCallBack = this.backOnMapCallback.bind(this);
      this.onError();
    }
  }

  mapClickedCallBack = async function(event){
    {
      this.currentCoord = this.map.getEventCoordinate(event);
      this.support.geom = '{"type":"Point","coordinates":['+this.currentCoord[0]+','+this.currentCoord[1]+']}';
      this.highlightVectorSource.clear();
      let feature = new ol.Feature(new ol.geom.Point(<[number,number]>this.currentCoord));
      this.highlightVectorSource.addFeature(feature);

      if(this.userService.user.municipalities && this.userService.user.municipalities.length > 0){
        let olGeom = Utils.getOlGeomFromMunicipality(this.userService.geom);
        if(olGeom.intersectsCoordinate(this.currentCoord)){
          await this.initAndShowConfirmDialog();
        }else{
          await this.initAndShowCannotAddSignHereDialog();
        }
      }else{
        let inBxl: boolean = false;
        let municipalities = await this.municipalityService.findAll();
        municipalities.forEach(async municipality =>{
          let olGeom = Utils.getOlGeomFromMunicipality(municipality.geom);
          if(olGeom.intersectsCoordinate(this.currentCoord)){
            inBxl = true;
          }
        });
        if(inBxl){
          await this.initAndShowConfirmDialog();
        }else{
          await this.initAndShowCannotAddSignHereDialog();
        }
      }
    }
  }

  ngOnDestroy() {
    this.map.getViewport().removeEventListener('click', this.onMapClickedCallback);
    this.mapService.allowAddSupportFromButton = true;
    this.highlightVectorSource.clear();
  }

  async initAndShowConfirmDialog() {
    this.confirmationDialogService.notificationMode = false;
    this.confirmationDialogService.yesCallBack = this.mapPositionConfirmed.bind(this);
    this.confirmationDialogService.noCallBack = this.noClicked.bind(this);
    this.confirmationDialogService.content = await this.translate.get('POSITION_CONFIRMATION').toPromise();
    this.layoutService.leftPanelContent = ConfirmationDialogComponent;
    this.layoutService.leftPanelVisible = true;
  }

  async initAndShowCannotAddSignHereDialog() {
    this.confirmationDialogService.notificationMode = true;
    this.confirmationDialogService.yesCallBack = this.hideBottomPanelAndClearHighlightLayer.bind(this);
    this.confirmationDialogService.content = await this.translate.get('CANT_ADD_SIGN_HERE').toPromise();
    this.layoutService.leftPanelContent = ConfirmationDialogComponent;
    this.layoutService.leftPanelVisible = true;
  }

  hideBottomPanelAndClearHighlightLayer(){
    this.highlightVectorSource.clear();
    this.layoutService.leftPanelVisible = false;
  }

  addressValidityChanged(event){
    this.sendValidation(event.validity || this.mapPositionSelected);
  }

  showMapPosition() {
    this.mapService.allowAddSupportFromButton = false;
    this.map.removeLayer(this.highlightVectorLayer);
    this.map.addLayer(this.highlightVectorLayer);
    this.layoutService.rightPanelVisible = false;
    if(this.mapPositionSelected && this.support.geom){
      let point = JSON.parse(this.support.geom);
      if(point.coordinates){
        let feature = new ol.Feature(new ol.geom.Point(point.coordinates));
        this.currentCoord = point.coordinates;
        this.highlightVectorSource.addFeature(feature);
        this.initAndShowConfirmDialog();
      }
    }
    this.map.getViewport().addEventListener('click',this.onMapClickedCallback)
  }

  async mapPositionConfirmed() {
    await this.noClicked();
    this.layoutService.rightPanelVisible = true;
    this.layoutService.loading = true;
    await this.getAdresseFromXY();
    this.mapPositionSelected = true;
    this.layoutService.loading = false;
    this.map.removeLayer(this.highlightVectorLayer);
    this.map.getViewport().removeEventListener('click', this.onMapClickedCallback);
  }

  async noClicked() {
    this.layoutService.leftPanelVisible = false;
  }

  async getMyPosition() {
    try{
      this.layoutService.loading = true;
      let position = await this.mapService.mapManager.geocodingMgr.getGeolocation(this.mapService.mapManager.view.mapProjection);
      this.currentCoord = position.coords;
      let geom = '{"type":"Point","coordinates":['+this.currentCoord[0]+','+this.currentCoord[1]+']}';
      this.support.geom = geom;
      await this.getAdresseFromXY();
      this.layoutService.loading = false;
      this.mapPositionSelected = true;
    }catch(e){
      this.confirmationDialogService.yesCallBack = this.backOnMapCallback.bind(this);
      this.onError();
    }
  }

  async getAdresseFromXY() {
    let address = await this.supportService.getAddressFromXY(this.translate.currentLang, this.currentCoord[0], this.currentCoord[1]);
    if(!address.error && address.status === "success"){
      address.result.address.street.label = address.result.address.street.name + ' ( '+address.result.address.street.postCode+' )';
      if(this.supportService.support == null){
        this.supportService.support = new Support({});
      }
      this.supportService.support.address = JSON.stringify(address.result.address.street);
      if(address.result.address.number){
        this.supportService.support.number = parseInt(address.result.address.number);
      }
      this.support.address = this.supportService.support.address;
      this.support.number = this.supportService.support.number;
    }
    return;
  }

  sendValidation(validity: boolean) {
    this.validityChanged.emit({validity: validity});
  }

}
