import {Component, Injector, Input, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {TranslateService} from "@ngx-translate/core";
import {Code, SelectCode} from "../../../models/code.model";
import {Sign} from "../../../models/sign.model";
import {SignFormatService} from "../../../core/services/sign-format.service";
import {SignSpecificityService} from "../../../core/services/sign-specificity.service";
import {SupportService} from "../../../core/services/support.service";
import {NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery";
import {SignService} from "../../../core/services/sign.service";
import {PictureServiceSign} from "../../../core/services/picture.service";
import {LayoutService} from "../../../core/services/layout.service";
import {ViewPanelsOfSupportComponent} from "../view-panels-of-support/view-panels-of-support.component";
import {CanThrowErrorComponent} from "../can-throw-error.component";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";


@Component({
  selector: 'create-panel-review',
  templateUrl: './create-panel-review.component.html'
})
export class CreatePanelReviewComponent extends CanThrowErrorComponent implements OnInit
{
  @Input()
  support: Support;

  @Input()
  sign: Sign;

  galleryImages: NgxGalleryImage[] = [];

  galleryOptions: NgxGalleryOptions[];

  imagesLoaded = false;

  loaded = false;

  title = '';

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private injector: Injector,
              private signService: SignService,
              private pictureService: PictureServiceSign,
              confirmationService: ConfirmationDialogService,
              layoutService: LayoutService) {
    super(layoutService, confirmationService);
  }

  async ngOnInit() {
    this.title = await this.translate.get("SIGN_DETAILS").toPromise();
    this.galleryOptions = [
      { "imageSize": "contain", "width": "100%", "height": "200px", "thumbnailsColumns": 3, "thumbnailsPercent":100, "previewCloseOnClick": true }
    ];
    if(this.signService.readMode){
      this.layoutService.loading = true;
      this.sign = this.signService.sign;
      try{
        this.sign.imgs = await this.pictureService.getPictureFull(this.sign.id);
      }catch(error){
        this.confirmationDialogService.yesCallBack = this.backOnMapCallback.bind(this);
        this.onError();
      }
      this.layoutService.loading = false;
    }
    if(this.sign.imgs && this.sign.imgs.length){
      this.sign.imgs.forEach(image =>{
        this.galleryImages.push({
          small: image.file,
          medium: image.file,
          big: image.file
        })
      });
      this.imagesLoaded = true;
    }
    this.loaded = true;
  }

  backToViewPanelsOfSupport() {
    this.layoutService.loading = true;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = ViewPanelsOfSupportComponent;
    this.layoutService.loading = false;
  }

}
