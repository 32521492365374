import {Component, Injector, Input, OnInit} from '@angular/core';
import {Task} from '../../../models/task.model';
import {TaskService} from '../../../core/services/task.service';
import {Session} from '../../../app.session';
import { LayoutService } from '../../../core/services/layout.service';
import { Subscription } from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RcService} from '../../../core/services/rc.service';
import {RcComponent} from '../../rc/rc.component';
import {Rc} from '../../../models/rc.model';
import {UserService} from '../../../core/services/user.service';
import {Code, SelectCode} from '../../../models/code.model';
import {TranslateService} from '@ngx-translate/core';
import {Sign} from '../../../models/sign.model';
import {SignService} from '../../../core/services/sign.service';
import {SignHistory} from '../../../models/sign-history.model';

@Component({
  selector: 'task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit
{

  translate: TranslateService = this.injector.get(TranslateService);

  _status = {
    NEW:"NEW",
    CLAIM:"CLAIM",
    UNCLAIM:"UNCLAIM",
    COMPLETE:"COMPLETE"
  };

  _intervention = {
    add: new Code(1, "SIGN:INTERVENTION:ADD"),
    none: new Code(2, "SIGN:INTERVENTION:NONE"),
    remove: new Code(3, "SIGN:INTERVENTION:DONE"),
  };

  get intervention(){
    return this._intervention;
  }

  statusOptionValide:SelectCode[];
  statusOptionNoChange:SelectCode[];
  statusOptionUnvalide:SelectCode[];

  _allSign:Sign[];
  _allSignHistory:SignHistory[];


  file: any;
  communalPvSize:number = 50000000;

  decisionForm: FormGroup;
  opinionForm: FormGroup;
  approvalForm: FormGroup;
  finalForm: FormGroup;
  selectedValueCOM_T01:string;
  selectedValueCOM_T02:string;
  selectedValueCOM_T03:string;
  motivationValueCOM_T02:string;
  motivationValueCOM_T03:string;

  showTaskEvent(part: string):boolean{
    return this.taskService.showTaskEvent(part);
  }

  waitingTaskEvent(part: string):boolean{
    return this.taskService.waitingTaskEvent(part);
  }

  get task():Task{
    return this.taskService.task;
  }

  get rcTask():Rc{
    return this.taskService.rcTask;
  }

  taskChanges:Subscription;

  displayInfoMissingRC:Boolean = false;

  constructor( private taskService: TaskService,
               private userService: UserService,
               private layoutService: LayoutService,
               private rcService: RcService,
               private signService: SignService,
               private session:Session,
               private injector: Injector,
               private fb: FormBuilder) {}


  async ngOnInit() {
    this.taskChanges = this.taskService.taskHandler.subscribe((task:Task)=>{
      this.init();
    });
    this.init();
  }

  ngOnDestroy() {
    this.taskChanges.unsubscribe();
  }

  async init(){
    this.allSign = [];
    this.file = null;
    this.decisionForm = this.fb.group({
      check: this.fb.control(''),
    });
    this.opinionForm = this.fb.group({
      check: this.fb.control(''),
      motivation: this.fb.control(''),
    });
    this.approvalForm = this.fb.group({
      check: this.fb.control(''),
      motivation: this.fb.control(''),
    });
    this.finalForm = this.fb.group({
      action: this.fb.control(''),
    });

    if(this.showFromStatus('COM_T04') || this.showFromStatus('COM_T05') || this.showFromStatus('REG_T03') || this.showFromStatus('REG_T04') ){
      await Promise.all(this.rcTask.signs.map(async sign => {
        sign.rcs = await this.rcService.getRCForSignId(sign.id);
      }));

      if(this.rcTask.lastTask.isValid){
        await this.laodHistorySign();
      }else{
        await this.laodAllSign();

        await this.loadStatusOption();
      }

    }

  }

  async laodHistorySign(){
    this.allSignHistory = [];

    try{
      this.allSignHistory = await this.signService.historyGetSignsFullByRcIds([this.rcTask.id]);
    }catch(error){
      console.info('handling error', error);
    }
  }

  async laodAllSign(){
    this.allSign = [];
    try{

      let rcIds:number[] = [];
      this.rcTask.signs.forEach(sign => {
        let active_rcs = sign.rcs.filter(rc => rc.status == Rc.validStatus().active);
        if(active_rcs.length>0){
          rcIds.push( active_rcs[0].id);
        }
      });

      rcIds.push(this.rcTask.id);
      this.allSign = (await this.signService.getSignsFullForRcIds(rcIds));

      await this.setStatusSign();

    }catch(error){
      console.info('handling error', error);
    }
  }

  setStatusSign(){
    this.allSign.forEach(sign => {
      if(sign.rcs.length==1){
        sign.signStatus = new Code(this.intervention.add.id, this.intervention.add.code);
      }else{
        sign.signStatus = new Code(this.intervention.add.id, this.intervention.none.code);
      }
    });
  }

  set allSign(signs:Sign[]){
    this._allSign = signs;
  }
  get allSign():Sign[]{
    return this._allSign;
  }
  set allSignHistory(signs:SignHistory[]){
    this._allSignHistory = signs;
  }
  get allSignHistory():SignHistory[]{
    return this._allSignHistory;
  }

  get validStatus(){
    return this.rcService.validStatus;
  }

  async loadStatusOption(){

    this.statusOptionValide = [];
    this.statusOptionNoChange = [];
    this.statusOptionUnvalide = [];
    this.statusOptionValide.push(new SelectCode(await this.translate.get(this.intervention.add.code).toPromise(), new Code(this.intervention.add.id, this.intervention.add.code)));
    this.statusOptionNoChange.push(new SelectCode(await this.translate.get(this.intervention.none.code).toPromise(), new Code(this.intervention.none.id, this.intervention.none.code)));
    this.statusOptionUnvalide.push(new SelectCode(await this.translate.get(this.intervention.none.code).toPromise(), new Code(this.intervention.none.id, this.intervention.none.code)));
    this.statusOptionUnvalide.push(new SelectCode(await this.translate.get(this.intervention.remove.code).toPromise(), new Code(this.intervention.remove.id, this.intervention.remove.code)));
  }

  actionSign(sign:Sign):number{
    if(sign.rcs.length==1){
      return 1;
    }else{
      if(this.rcTask.signs.filter(s => s.id == sign.id).length>0){
        return 2;
      }else{
        return 3;
      }
    }
  }

  communale_communalApproveOrNotCR(){
    if(this.selectedValueCOM_T01 && this.file){

      try {
        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        let ctxt = this;
        reader.onloadend = async () => {
          let base64data = reader.result;
          this.layoutService.loading = true;
          try{
            let data = {
              rcId : this.rcTask.id,
              taskKey: this.rcTask.lastTask.taskKey,
              taskId : this.rcTask.lastTask.taskId,
              value : this.selectedValueCOM_T01=="true"?true:false,
              file : base64data,
              fileName : this.file.name,
            };
            let ret = await this.taskService.updateTaskRc(data);
            this.update();
          }catch(error){
            //TODO error handling
            console.log(error);
          }

          this.layoutService.loading = false;

        };

      }catch(error){
        //TODO error handling
        console.log(error);
      }
    }else{
      this.displayInfoMissingRC = true;
    }



  }

  async communale_favorableOrUnfavorableOpinion(){
    if(this.selectedValueCOM_T02 == "true" || (this.selectedValueCOM_T02 == "false" && this.motivationValueCOM_T02)){
      this.layoutService.loading = true;
      try{
        let data = {
          rcId : this.rcTask.id,
          taskKey: this.rcTask.lastTask.taskKey,
          taskId : this.rcTask.lastTask.taskId,
          value : this.selectedValueCOM_T02=="true"?true:false,
          message : this.motivationValueCOM_T02?this.motivationValueCOM_T02:""
        };
        let ret = await this.taskService.updateTaskRc(data);
        this.update();
      }catch(error){
        //TODO error handling
        console.log(error);
      }
      this.layoutService.loading = false;
    }

  }

  async communale_ministerApproveOrNotCR(){
    if(this.selectedValueCOM_T03 == "true" || (this.selectedValueCOM_T03 == "false" && this.motivationValueCOM_T03)) {
      this.layoutService.loading = true;
      try {
        let data = {
          rcId : this.rcTask.id,
          taskKey: this.rcTask.lastTask.taskKey,
          taskId : this.rcTask.lastTask.taskId,
          value : this.selectedValueCOM_T03=="true"?true:false,
          message : this.motivationValueCOM_T03?this.motivationValueCOM_T03:"",
        };
        let ret = await this.taskService.updateTaskRc(data);
        this.update();
      } catch (error) {
        //TODO error handling
        console.log(error);
      }
      this.layoutService.loading = false;
    }
  }

  async communale_finalValidation(){
    this.layoutService.loading = true;
    try {
      let action = [];

      this.allSign.forEach(sign => {
        action.push({signId:sign.id, intervention:sign.signStatus});
      });

      let data = {
        rcId : this.rcTask.id,
        taskKey: this.rcTask.lastTask.taskKey,
        taskId : this.rcTask.lastTask.taskId,
        action : action,
      };
      let ret = await this.taskService.updateTaskRc(data);
      this.update();
    } catch (error) {
      //TODO error handling
      console.log(error);
    }
    this.layoutService.loading = false;
  }

  async update(){

    try{
      this.layoutService.loading = true;
      let rcTasks:Rc[] = await this.rcService.getTasks();

      rcTasks = rcTasks.sort((a:Rc, b:Rc)=>{
        if (a.creationDate > b.creationDate) {
          return 1;
        }
        if (a.creationDate < b.creationDate) {
          return -1;
        }
        return 0;
      });

      this.taskService.rcTasks=rcTasks;

    }catch(error){
      //TODO error handling
      console.log(error);
    }
    this.layoutService.loading = false;

    this.layoutService.closeRightPanel();
  }

  close(){
    this.layoutService.closeRightPanel();
  }
  expand(){
    this.layoutService.leftPanelFull = false;
    this.layoutService.rightPanelFull = !this.layoutService.rightPanelFull;
  }

  todo(text:string){
    console.info("TODO : ", text);
  }

  async downloadRcDocument(gedId:string){
    try{
      let file = await this.rcService.getGedDocument(gedId);
      //alert("show file");
    }catch(error){
      console.info("TODO HANDLE ERROR", error);
    }
  }
  async generateRcDocument(){
    try{
      let file = await this.rcService.generateRcDocument(this.taskService.rcTask.id);
    }catch(error){
      console.info("TODO HANDLE ERROR", error);
    }
  }

  updateRC(){
    this.rcService.rc = this.taskService.rcTask;
    this.rcService.editMode = true;
    this.rcService.fromTaskView = this.taskService.rcTask;
    this.layoutService.rightPanelContent = RcComponent;
    this.layoutService.rightPanelVisible;
  }

  uploadhandler($event){
    try{
      if($event.files && $event.files.length > 0 && $event.files[0].size <= this.communalPvSize){
        this.file = $event.files[0];
      }else{
        this.file = null;
      }
    }catch(error){
      console.info("TODO HANDLE ERROR", error);
    }
  }

  get hasfile(){
    if(this.file){
      return true;
    }
    return false;
  }

  hideInfoMissingRC(){
    this.displayInfoMissingRC = false;
  }

  isInProgress(code:string):boolean {
    let tasks = this.rcTask.tasks.filter( task => task.taskKey == code);

    if(tasks.length == 1){
      return tasks[0].validationDate === undefined || tasks[0].validationDate === null ? true : false;
    }
    return false;
  }
  isDone(code:string):boolean {
    let tasks = this.rcTask.tasks.filter( task => task.taskKey == code);

    if(tasks.length == 1){
      return tasks[0].validationDate === undefined || tasks[0].validationDate === null ? false : true;
    }
    return false;
  }
  showFromStatus(code:string):boolean{
    let tasks = this.rcTask.tasks.filter( task => task.taskKey == code);
    if(tasks.length == 1){
      return true;
    }
    return false;
  }


}
