import {SupportGestion, SupportSpecificity, SupportType} from './code.model';
import {Sign} from './sign.model';
import {Rc} from './rc.model';

export class Support {

  public id?: number;
  public geom?: string;
  public gestion?: SupportGestion;
  public type?: SupportType;
  public typeOthers?: string;
  public specificity?: SupportSpecificity;
  public heightOffGround?: string;
  public placementDate?: Date;
  public removalDate?: Date;
  public address?: string;
  public number?: number;
  public note?: string;
  public other?: string;
  public orientation?: number;
  public translation?: number;
  public placementUser?: string;
  public removalUser?: string;
  public placementNote?: string;
  public removalNote?: string;

  public signs?: Sign[];
  public oldSigns?: Sign[];

  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
    this.placementDate = obj.placementDate?new Date(obj.placementDate):null;
    this.removalDate = obj.removalDate?new Date(obj.removalDate):null;
    if(obj && obj.signs){
      this.signs = obj.signs.map(sign=>new Sign(sign));
    }
    if(obj && obj.oldSigns){
      this.oldSigns = obj.oldSigns.map(sign=>new Sign(sign));
    }
  }


  isEqual(support: Support): boolean{
    let change: boolean = false;
    if(this.geom != support.geom)
      change = true;
    if(this.gestion && support.gestion && this.gestion.code != support.gestion.code)
      change = true;
    if(this.type && support.type && this.type.code != support.type.code)
      change = true;
    if(this.typeOthers != support.typeOthers)
      change = true;
    if(this.specificity && support.specificity && this.specificity.code != support.specificity.code)
      change = true;
    if(this.heightOffGround != support.heightOffGround)
      change = true;
    if(this.placementDate != support.placementDate)
      change = true;
    if(this.removalDate != support.removalDate)
      change = true;
    if(this.address != support.address)
      change = true;
    if(this.number != support.number)
      change = true;
    if(this.note != support.note)
      change = true;
    if(this.other != support.other)
      change = true;
    if(this.orientation != support.orientation)
      change = true;
    if(this.translation != support.translation)
      change = true;
    if(this.placementUser != support.placementUser)
      change = true;
    if(this.removalUser != support.removalUser)
      change = true;
    if(this.placementNote != support.placementNote)
      change = true;
    if(this.removalNote != support.removalNote)
      change = true;
    return change;
  }

  get addressLabel():string{
    try{
      return JSON.parse(this.address).label || this.address;
    }catch(e){

    }
    return this.address;
  }

}
