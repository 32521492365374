import {Component, Injector, OnInit} from '@angular/core';
import {MobileWizardComponent} from "../mobile-wizard.component";
import {LayoutService} from "../../../core/services/layout.service";
import {Sign} from "../../../models/sign.model";
import {SignService} from "../../../core/services/sign.service";
import {ViewPanelsOfSupportComponent} from "../view-panels-of-support/view-panels-of-support.component";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {MapService} from "../../../core/services/map.service";


@Component({
   selector: 'update-panel',
   templateUrl: './update-panel.component.html',
   styleUrls: ['./update-panel.component.scss']
})
export class UpdatePanelComponent extends MobileWizardComponent implements OnInit
{

  selectedSign: Sign;

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private signService: SignService,
              private injector: Injector,
              private mapService: MapService,
              confirmationService: ConfirmationDialogService,
              i18n: TranslateService,
              layoutService: LayoutService) {
    super({}, layoutService, confirmationService, i18n);
    this.stepsNumber = 4;
  }

  async ngOnInit() {
    await this.initConfig();
    this.initWizard();
    this.selectedSign = this.signService.sign;
    this.signService.readMode = false;
  }

  async initConfig() {
    let title = await this.translate.get('UPDATE_SIGN').toPromise();
    this.config = {
      0: {
        title: title
      },
      1: {
        title: title
      },
      2: {
        title: title
      },
      3: {
        title: title
      }
    }
  }

  async save() {
    try{
      this.layoutService.loading = true;
      this.selectedSign.mobileStatus = this.selectedSign.mobileStatus != null ? this.selectedSign.mobileStatus : this.signService.MOBILE_STATUS_EDIT;
      await this.signService.updateSign(this.selectedSign);
      this.mapService.refresh();
      this.layoutService.loading = false;
      this.backToViewPanelsOfSupport();
    }catch(e){
      this.confirmationDialogService.yesCallBack = this.onErrorCallback.bind(this);
      this.onError();
    }
  }

  onErrorCallback(){
    this.dismissErrorDialog();
    this.backToViewPanelsOfSupport();
  }

  async backToViewPanelsOfSupport() {
    this.layoutService.loading = true;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = ViewPanelsOfSupportComponent;
    this.layoutService.loading = false;
  }

}
