export const environment = {
  production: false,
  backendURL: '//esign-services.sprb-gob.brussels/',
  identityPath: 'ESign.Identity.Host/',
  supportPath: 'ESign.Support.Host/',
  signPath: 'Esign.Sign.Host/',
  taskPath: 'ESign.Task.Host/',
  picturePath: 'Esign.Picture.Host/',
  rcPath: 'Esign.RC.Host/',
  municipalityPath: 'Esign.Municipality.Host/',
  gedPath: 'Esign.GED.Host/',
  resourcePath: 'Esign.Resource.Host/',
  imageTypePath:'//esign-services.sprb-gob.brussels/Esign.Sign.Host/api/sign/getSignTypeFile?path=',
  translationPath:'api/resource/translations/',
  oAuthClientId: 'Esign-Mobile',
  oAuthClientSecret: '614F19A7-F4A4-4B4D-BB46-1F7EF584E3D1',
  accessTokenUri: 'ESign.Identity.Host/OAuth/Token',
  grantType: 'password',
  grantTypeReload: 'refresh_token',
  code:'prod'
};
