import {LayoutService} from "../../core/services/layout.service";
import {ConfirmationDialogService} from "../../core/services/confirmation-dialog.service";
import {Component} from "@angular/core";
import {ConfirmationDialogComponent} from "./confirmation-dialog/confirmation-dialog.component";

@Component({
  entryComponents: [ConfirmationDialogComponent],
  providers: [LayoutService, ConfirmationDialogService]
})
export class CanThrowErrorComponent {

  protected layoutService: LayoutService;

  protected confirmationDialogService: ConfirmationDialogService;

  constructor(layoutService: LayoutService,confirmationDialogService: ConfirmationDialogService){
    this.layoutService = layoutService;
    this.confirmationDialogService = confirmationDialogService;
  }

  onError(){
    this.layoutService.leftPanelVisible = true;
    this.confirmationDialogService.notificationMode = true;
    this.confirmationDialogService.content = 'Error occured try again';
    this.layoutService.leftPanelContent = ConfirmationDialogComponent;
  }

  dismissErrorDialog() {
    this.layoutService.loading = false;
    this.layoutService.leftPanelVisible = false;
  }

  protected backOnMapCallback() {
    this.layoutService.loading = false;
    this.layoutService.leftPanelVisible = false;
  }
}
