import {Component, OnInit} from '@angular/core';
import {RcService} from '../../../core/services/rc.service';
import {LayoutService} from '../../../core/services/layout.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PanelSearchRcResultComponent} from '../panel-search-rc-result/panel-search-rc-result.component';
import {Rc} from '../../../models/rc.model';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'panel-show-multi-rc',
  templateUrl: './panel-show-multi-rc.component.html',
  styleUrls: ['./panel-show-multi-rc.component.scss']
})

export class PanelShowMultiRcComponent implements OnInit {


  uniqueRegulationForm: FormGroup;

  private _uniqueRegulation: string = null;
  get uniqueRegulation(): string {
    return this._uniqueRegulation;
  }
  set uniqueRegulation(value: string) {
    this._uniqueRegulation = value;
  }


  rcs:Rc[];


  constructor(private layoutService: LayoutService,
              private rcService: RcService,
              private userService: UserService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.uniqueRegulationForm = this.fb.group({
      body: this.fb.control('')
    });
    this.init();
  }

  async init(){
    this.uniqueRegulation = "";
    this.layoutService.loading = true;
    this.generateUniqueRegulation();
    this.layoutService.loading = false;
  }

  generateUniqueRegulation(){
    this.rcService.rcs.forEach(rc => {
      this.uniqueRegulation = this.uniqueRegulation.concat(rc.header, rc.body, rc.footer, `<p><br></p>`);
    })
  }

  download(){
    alert("not implemented");
    try{
      this.layoutService.loading = true;
      this.rcService.generateWord(this.uniqueRegulation);
    }catch(error){

    }
    this.layoutService.loading = false;
  }

  close() {
    this.layoutService.closeRightPanel();
  }
}
