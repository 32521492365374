import {Component, Input} from '@angular/core';
import {Sign} from '../../../models/sign.model';
import {Router} from '@angular/router';
import {SignService} from '../../../core/services/sign.service';
import {ConfirmationService} from 'primeng/api';
import {DragulaService} from 'ng2-dragula';
import {LayoutService} from '../../../core/services/layout.service';
import {SignComponent} from '../sign/sign.component';
import { Subscription } from 'rxjs';
import {SignOrderService} from '../../../core/services/sign-order.service';
import {Support} from '../../../models/support.model';

@Component({
  selector: 'edit-sign-order',
  templateUrl: './edit-sign-order.component.html',
  styleUrls: ['./edit-sign-order.component.scss'],
  providers: [ConfirmationService]
})
export class EditSignOrderComponent
{

  @Input() supportId: number;
  @Input() edit: boolean;

  deleteSelect: number = null;

  sign_order = new Array();
  divSignGroup;
  dragulaDropChanges:Subscription;

  reloadPage:boolean = false;
  signsChanges:Subscription;

  constructor(private router: Router,
              private signService: SignService,
              private confirmationService: ConfirmationService,
              private dragulaService: DragulaService,
              private layoutService: LayoutService,
              private signOrderService: SignOrderService){

    this.dragulaDropChanges = dragulaService.drop.subscribe((value) => {
      this.onDrop(value.slice(1));
    });

    this.signsChanges = this.signOrderService.signsHandler.subscribe((signs: Sign[]) => {
      this.reload();
    });

  }

  ngOnDestroy() {
    this.dragulaDropChanges.unsubscribe();
    this.signsChanges.unsubscribe();
  }

  reload(){
    this.reloadPage = true;
    this.reloadPage = false;
  }



  private onDrop(args) {
    let [e, el] = args;
    this.divSignGroup = el;
    this.calculateOrder();
  }


  getSign(i:number):Sign{
    if(this.signOrderService.signs) {
      for (let sign of this.signOrderService.signs) {
        if (sign.order == i) {
          return sign;
        }
      }
    }
    return null;
  }

  get visuelSigns():number[]{
    let signs:number[]= Array.from(Array(this.ordermax()+1), (e,i)=>i+1).reverse();
    return signs
  }

  ordermax():number{
    let ordermax = 0;
    this.sign_order = new Array();
    if(this.signOrderService.signs) {
      for (let sign of this.signOrderService.signs) {
        this.sign_order.push({id: sign.id, order: sign.order});
        if (ordermax < sign.order) {
          ordermax = sign.order;
        }
      }
    }
    return ordermax;
  }

  showSign(sign:Sign){
    this.signService.sign = new Sign(sign);
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = SignComponent;
  }

  displayDelete(id:number){
    this.deleteSelect = id;
    this.confirmationService.confirm({
      message: '',
      icon: 'icon fa fa-trash',
      accept: () => {
        this.deleteSign();
        this.deleteSelect = null;
      },
      reject: () => {
        this.deleteSelect = null;
      }
    });
  }
  async deleteSign(){
    try{
      this.layoutService.loading = true;
      let ret = await this.signService.deleteSign(this.deleteSelect);
      this.layoutService.loading = false;
    }catch(error){
      this.layoutService.loading = false;
    }
  }
  newSign(){
    this.signService.editMode = true;
    this.signService.sign = new Sign({supportId:this.supportId});
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = SignComponent;
  }

  calculateOrder(){
    let data = new Array();
    for(let sign of this.sign_order){
      data.push({id:sign.id, order:(this.divSignGroup.children[this.divSignGroup.childElementCount-sign.order]).getAttribute("data-order")});
    }
    this.signService.signOrder = data;
  }

}
