export class Municipality {

  public id?: number;
  public name?: string;
  public nameFr?: string;
  public nameNl?: string;
  public nis?: number;
  public geom?: string;

  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
  }

}
