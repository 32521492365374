import {Injectable} from '@angular/core';
import {User} from "../../models/user.model";
import * as ol from 'openlayers';

import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';
import {roleLink} from "../../../assets/config/roleLink";
import { Role } from '../../models/role.model';

@Injectable()
export class UserService extends BaseAbstractService{

  ROLE_CREATE: boolean = false;
  ROLE_UNACTIVATE: boolean = false;
  ROLE_EDIT: boolean = false;
  ROLE_READ: boolean = false;
  ROLE_WORKFLOW: boolean = false;
  ROLE_CREATE_RC: boolean = false;

  secretaryCode: string = "SECRETARY_CC";
  agentCode: string = "MUNICIPALITY_AGENT";
  sprbCode: string = "SPRB_AGENT";


  get user():User{
    return new User(this._session.user);
  }

  set user(user:User){
    this._session.user=user;
  }

  get logged():boolean{
    return this.hasToken();
  }

  get roles():Role[]{
    return this._session.roles;
  }

  hasToken():boolean{
    return this._session.oAuthtoken!=null && typeof this._session.oAuthtoken!='undefined';
  }

  checkRole(){
    this.ROLE_CREATE = false;
    this.ROLE_UNACTIVATE = false;
    this.ROLE_EDIT = false;
    this.ROLE_READ = false;
    this.ROLE_WORKFLOW = false;
    this.ROLE_CREATE_RC = false;
    if(this.user && this.user.roles.length > 0){
      for(let role of this.user.roles){
        if(roleLink[role.code].CREATE){
          this.ROLE_CREATE = true;
        }
        if(roleLink[role.code].UNACTIVATE){
          this.ROLE_UNACTIVATE = true;
        }
        if(roleLink[role.code].EDIT){
          this.ROLE_EDIT = true;
        }
        if(roleLink[role.code].READ){
          this.ROLE_READ = true;
        }
        if(roleLink[role.code].WORKFLOW){
          this.ROLE_WORKFLOW = true;
        }
        if(roleLink[role.code].CREATE_RC){
          this.ROLE_CREATE_RC = true;
        }
      }
    }
  }


  get url():string {
    return environment.backendURL+environment.identityPath + this.configService.appConfig.apiUrl + 'User/';
  }

  async getUser(): Promise<User> {
    let jsonUser = await this.get<any>(this.url);
    return new User(jsonUser);
  }

  async createUser(user: User): Promise<User> {
    let jsonUser = await this.post<User>(this.url, user);
    return new User(user);
  }

  async updateUser(user: User): Promise<User> {
    let jsonUser = await this.put<User>(this.url, user);
    return new User(jsonUser);
  }

  async findUser(login: string): Promise<User> {
    let jsonUser = await this.get(`${this.url}${login}`);
    return new User(jsonUser);
  }

  async queryUsers(req?: any): Promise<User[]> {
    return (await this.get<any[]>(this.url)).map(json => new User(json));
  }

  deleteUser(login: string): Promise<boolean> {
    return this.delete<boolean>(`${this.url}${login}`);
  }

  changePasswordUser(password: string): Promise<boolean> {
    return this.post<boolean>(`${this.url}change_password`, password);
  }


  async checkLogin(): Promise<any> {
    await this.checkCredentials();
    if (this._session.oAuthtoken) {
      let user:User = await this.getUser();
      if(!user.encoder){
        // alert("Utilisateur non mobile");
      }
      this._session.user = user;
      this.checkRole();
    }
  }

  changeUserLanguage(language: string): Promise<User> {
    if (this.user){
      this.user.language=language;
    }
    return super.put<User>(`${this.url}${language}`, language);
  }

  isRole(customRole:string){
    for(let role of this.user.roles){
      if(role.code==customRole){
        return true;
      }
    }
    return false;
  }

  get geom():string{
    if(this.user.municipalities && this.user.municipalities.length > 0){
      return this.user.municipalities[0].geom;
    }
    return null;
  }

  canUpdateFromGeom(geom:string):boolean{
    let feature = (new ol.format.GeoJSON()).readFeature(geom);
    let coordinate = (<ol.geom.Point>feature.getGeometry()).getCoordinates();
    if(this.ROLE_EDIT){
      let jsonGeom = this.geom;
      if(jsonGeom){
        let geom = JSON.parse(jsonGeom);
        let trueGeom = new ol.geom.MultiPolygon(geom.coordinates);
        if(trueGeom.intersectsCoordinate(coordinate)){
          return true;
        }else{
          return false;
        }
      }else{
        return true;
      }
    }
    return false
  }

}
