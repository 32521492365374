import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import { LayoutService } from '../../../core/services/layout.service';
import {Support} from "../../../models/support.model";
import {Sign} from "../../../models/sign.model";
import {SupportService} from "../../../core/services/support.service";
import {MobileWizardComponent} from "../mobile-wizard.component";
import {ViewSupportComponent} from "../view-support/view-support.component";
import {TranslateService} from "@ngx-translate/core";
import {ViewPanelsOfSupportComponent} from "../view-panels-of-support/view-panels-of-support.component";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {MapService} from "../../../core/services/map.service";
import {SignService} from "../../../core/services/sign.service";
import {SupportGeometrySelectionComponent} from "../support-geometry-selection/support-geometry-selection.component";


@Component({
   selector: 'create-support',
   templateUrl: './create-support.component.html',
   styleUrls: ['./create-support.component.scss']
})
export class CreateSupportComponent extends MobileWizardComponent implements OnInit
{

  stepsNumber = 8;
  support: Support;
  sign: Sign;

  @ViewChild('geometrySelection')
  geometrySelection: SupportGeometrySelectionComponent;

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private supportService: SupportService,
              private injector: Injector,
              private signService: SignService,
              private mapService: MapService,
              confirmationService: ConfirmationDialogService,
              i18n: TranslateService,
              layoutService: LayoutService) {
      super({}, layoutService, confirmationService, i18n);
  }

  async ngOnInit() {
    await this.initConfig();
    this.initWizard();
    this.initComponent();
  }

  async initConfig() {
    let supportEncode = await this.translate.get("SUPPORT_ENCODING").toPromise();
    let signEncode = await this.translate.get("SIGN_ENCODING").toPromise();
    this.config = {
      0: {
        title: supportEncode
      },
      1: {
        title: supportEncode
      },
      2: {
        title: supportEncode
      },
      3: {
        title: supportEncode
      },
      4: {
        title: signEncode
      },
      5: {
        title: signEncode
      },
      6: {
        title: signEncode
      },
      7: {
        title: signEncode
      }};
  }

  initComponent() {
    this.support = this.supportService.createMode ? this.supportService.support : new Support({});
    this.sign = new Sign({
      requestType: '[mobile]'
    });
  }

  onClose = function() {
    if(this.geometrySelection) {
      this.geometrySelection.ngOnDestroy();
    }
    this.layoutService.rightPanelVisible = false;
  }.bind(this);

  async save(){
    try{
      this.layoutService.loading = true;
      this.sign.mobileStatus = this.signService.MOBILE_STATUS_ADD;
      this.sign.mobileStatusDate = new Date();
      let createdSupport = await this.supportService.createWithSigns(this.support, [this.sign]);
      this.supportService.support = createdSupport;
      this.mapService.refresh();
      this.layoutService.rightPanelVisible = true;
      this.backToViewPanelsOfSupport();
    }catch(error){
      this.confirmationDialogService.yesCallBack = this.backToViewPanelsOfSupport.bind(this);
      this.onError();
    }
  }

  backToViewPanelsOfSupport() {
    this.dismissErrorDialog();
    this.layoutService.rightPanelContent = ViewPanelsOfSupportComponent;
  }

}
