import {Component, Injector, OnInit} from '@angular/core';
import {RcService} from '../../../core/services/rc.service';
import {LayoutService} from '../../../core/services/layout.service';
import {UserService} from '../../../core/services/user.service';
import {MunicipalityService} from '../../../core/services/municipality.service';
import {Rc} from '../../../models/rc.model';
import {RcComponent} from '../rc.component';
import {SelectCode} from '../../../models/code.model';
import {TranslateService} from '@ngx-translate/core';
import {TaskService} from '../../../core/services/task.service';
import {TaskViewComponent} from '../../tasks/task-view/task-view.component';

@Component({
  selector: 'panel-search-rc-result',
  templateUrl: './panel-search-rc-result.component.html',
  styleUrls: ['./panel-search-rc-result.component.scss']
})
export class PanelSearchRcResultComponent implements OnInit
{
  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private layoutService: LayoutService,
              private rcService: RcService,
              private taskService: TaskService,
              private userService: UserService,
              private injector: Injector,) {}

  async ngOnInit() {
  }

  async showTaskRC(rc:Rc){
    try{
      this.layoutService.loading = true;
      this.taskService.task = await this.taskService.getTaskRc(rc.id);
      this.layoutService.rightPanelContent = TaskViewComponent;
      this.layoutService.rightPanelVisible = true;
    }catch(error){
      console.info('HANDLE ERROR ', error);
    }

    this.layoutService.loading = false;
  }

  close(){
    this.layoutService.closeLeftPanel();
  }
}
