import {Component, Injector, OnInit} from '@angular/core';
import {SupportService} from "../../../core/services/support.service";
import {Support} from "../../../models/support.model";
import {LayoutService} from "../../../core/services/layout.service";
import {UpdateSupportComponent} from "../update-support/update-support.component";
import {ViewPanelsOfSupportComponent} from "../view-panels-of-support/view-panels-of-support.component";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../core/services/user.service";
import {CanThrowErrorComponent} from "../can-throw-error.component";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";


@Component({
  selector: 'view-support',
  templateUrl: './view-support.component.html',
  styleUrls: ['./view-support.component.scss']
})
export class ViewSupportComponent extends CanThrowErrorComponent implements OnInit
{

  selectedSupport: Support;
  compTitle = '';

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private supportService: SupportService,
              private injector: Injector,
              private userService: UserService,
              confirmationService: ConfirmationDialogService,
              layoutService: LayoutService) {
    super(layoutService, confirmationService)

  }

  async ngOnInit() {
    this.compTitle = await this.translate.get('SUPPORT_INFORMATION').toPromise();
    this.selectedSupport = this.supportService.support;
  }

  updateSupport() {
    if(this.canUpdate()){
      this.layoutService.loading = true;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = UpdateSupportComponent;
      this.layoutService.loading = false;
    }
  }

  viewPanels() {
    this.layoutService.loading = true;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = ViewPanelsOfSupportComponent;
    this.layoutService.loading = false;
  }

  canUpdate() {
    if(this.supportService.support){
      return this.userService.canUpdateFromGeom(this.supportService.support.geom);
    }else{
      return false;
    }
  }

}
