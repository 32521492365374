import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from '../../app.session';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {CryptoService} from './crypto.service';


@Injectable()
export class OauthService {

  constructor(
    protected _router: Router,
    protected _http: HttpClient,
    protected _session: Session
  ) {
  }

  async handleOAuthHeader(options?: any): Promise<any> {
    options = options ? options : {};
    let auth: string = await this.getBearerAuthorization();
    let headers: HttpHeaders = options.headers ? options.headers : new HttpHeaders({
      'Authorization': auth
    });
    headers.set('Authorization', auth);
    options.headers = headers;
    return options;
  }

  async getBearerAuthorization(): Promise<string> {
    this.checkCredentials();
    if (!this.isValid) {
      try {
        let data = await this.reloadAccessToken().first().toPromise();
        this.saveToken(data);
      } catch (e) {
        this.logout();
      }
    }
    return `Bearer ${this._session.oAuthtoken.access_token}`;
  }


  async obtainAccessToken(loginData): Promise<any> {

    let promise: Promise<any> = new Promise((resolve, reject) => {

      let params = new URLSearchParams();

      let cryptedPassword = btoa(CryptoService.cryptAsSHA256(loginData.password));

      params.append('username', loginData.username);
      params.append('password', cryptedPassword);
      params.append('grant_type', environment.grantType);
      params.append('client_id', environment.oAuthClientId);
      params.append('client_secret', environment.oAuthClientSecret);

      let headers = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'});

      this._http
        .post(`${environment.backendURL}${environment.accessTokenUri}`, params.toString(), {headers: headers})
        .map((res: Response) => {
          return res;
        })
        .subscribe(
          data => {
            this.saveToken(data);
            resolve(null);
          },
          err => {
            reject('Invalid Credentials');
          });
    });

    return promise;

  }

  async saveToken(token) {
    this._session.oAuthtoken = token;
  }

  reloadAccessToken(): Observable<any> {

    let params = new URLSearchParams();

    params.append('refresh_token', this._session.oAuthtoken.refresh_token);
    params.append('grant_type', environment.grantTypeReload);
    params.append('client_id', environment.oAuthClientId);
    params.append('client_secret', environment.oAuthClientSecret);

    let headers = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'});
    return this._http.post(`${environment.backendURL}${environment.accessTokenUri}`, params.toString(), {
      headers: headers,
    });
  }

  get isValid(): boolean {
    if (this._session.oAuthtoken && new Date() < new Date(this._session.oAuthtoken['.expires'])) {
      return true;
    }
    return false;
  }

  getResource(resourceUrl): Observable<Response> {
    var headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Authorization': 'Bearer ' + this._session.oAuthtoken.access_token
    });

    return this._http.get(resourceUrl, {headers: headers})
      .map((res: Response) => {
        return res;
      })
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  checkCredentials() {
    if (!this._session.oAuthtoken) {
      this.logout();
    }
  }

  logout() {
    this._session.invalidate();
    this._router.navigate(['/login']);
  }
}
