import {Component, Injector, OnInit} from '@angular/core';
import {SupportService} from "../../../core/services/support.service";
import {Support} from "../../../models/support.model";
import {LayoutService} from "../../../core/services/layout.service";
import {ViewSupportComponent} from "../view-support/view-support.component";
import {SignService} from "../../../core/services/sign.service";
import {Sign} from "../../../models/sign.model";
import {ViewPanelsOfSupportComponent} from "../view-panels-of-support/view-panels-of-support.component";
import {TranslateService} from "@ngx-translate/core";
import {CanThrowErrorComponent} from "../can-throw-error.component";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {MapService} from "../../../core/services/map.service";


@Component({
   selector: 'reorder-panels',
   templateUrl: './reorder-panels.component.html',
   styleUrls: ['./reorder-panels.component.scss']
})
export class ReorderPanelsComponent extends CanThrowErrorComponent implements OnInit
{

  selectedSupport: Support;
  signs: Sign[];
  loaded = false;
  signsLength = 0;
  compTitle = '';

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private supportService: SupportService,
              private mapService: MapService,
              layoutService: LayoutService,
              confirmationService: ConfirmationDialogService,
              private injector: Injector,
              private signService: SignService) {
    super(layoutService, confirmationService);
  }

  async ngOnInit() {
    this.compTitle = await this.translate.get('REORDERING_SIGNS').toPromise();
    this.initComponent();
  }

  initComponent(){
    this.selectedSupport = this.supportService.support;
    this.signs = this.selectedSupport.signs;
    this.sortSigns();
    this.signsLength = this.signs.length;
  }

  backToViewPanelsSupport() {
    this.layoutService.loading = true;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = ViewPanelsOfSupportComponent;
    this.layoutService.loading = false;
  }

  async saveModifications() {
    try{
      this.layoutService.loading = true;
      this.selectedSupport.signs = this.signs;
      let signOrder = [];
      this.signs.forEach(sign =>{
        signOrder.push({id: sign.id, order: sign.order});
      })
      this.selectedSupport['signOrders'] = signOrder;
      await this.supportService.updateSupportAndSign(this.selectedSupport);
      signOrder = this.supportService.support['signOrders'];
      let newOrderedSigns:Sign[]=[];
      if(signOrder && signOrder.length > 0){
        signOrder.forEach(order =>{
          let currentSign = this.supportService.support.signs.find(x => x.id == order.id);
          currentSign.order = order.order;
          newOrderedSigns.push(currentSign);
        })
        this.supportService.support.signs = newOrderedSigns;
      }
      this.mapService.refresh();
      this.layoutService.loading = false;
      this.backToViewPanelsSupport();
    }catch(e){
      this.confirmationDialogService.yesCallBack = this.errorCallBack.bind(this);
      this.onError();
    }
  }

  errorCallBack() {
    this.dismissErrorDialog();
    this.backToViewPanelsSupport();
  }

  getClass(direction, sign) {
    let returnClass = "ui-g-12";
    returnClass += direction == 'up' ? ' far fa-chevron-up' : ' far fa-chevron-down';
    if(this.signs.length == 1){
      returnClass += ' reorder-button-disabled';
    }else{
      if(sign.order == 1){
        if(direction ==  'down'){
          returnClass += ' reorder-button-disabled';
        }else{
          returnClass += ' reorder-button';
        }
      }else if(sign.order == this.signsLength){
        if(direction ==  'up'){
          returnClass += ' reorder-button-disabled';
        }else{
          returnClass += ' reorder-button';
        }
      }else{
        returnClass += ' reorder-button';
      }
    }
    return returnClass;
  }

  move(direction, sign){
    let indexOfCurrentSign = this.signs.indexOf(sign);
    let indexToMove = direction == 'up' ? indexOfCurrentSign - 1 : indexOfCurrentSign + 1;
    if(this.signs[indexToMove] == null){
      return;
    }
    let triggerSignOrder = sign.order;
    sign.order = this.signs[indexToMove].order;
    this.signs[indexToMove].order = triggerSignOrder;
    this.sortSigns();
  }

  sortSigns() {
    this.signs.sort((a, b)=>{
      if(a.order < b.order){
        return 1;
      }else if(b.order < a.order){
        return -1;
      }else{
        return 0;
      }
    });
  }
}
