import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as ol from 'openlayers';
import { Support } from '../../models/support.model';
import { Map, View, MapMgr, Viewer, WMTS } from '@nsi/gis-core';
import { Observable } from 'rxjs';

@Injectable()
export class MapService {
  _addSignModeActivated: boolean;
  _selectSignModeActivated: boolean;
  _highlightedSupports: Support[];
  _selectedSupports: Support[];
  _highlightedZone: string[];

  _viewer: Viewer;
  private _language: string;
  private _languageSource: Subject<string> = new Subject<string>();
  private _languageHandler: Observable<string> = this._languageSource.asObservable();
  set viewer(viewer: Viewer) {
    this._viewer = viewer;
  }
  get viewer(): Viewer {
    return this._viewer;
  }

  private _allowAddSupportSource: Subject<boolean> = new Subject<boolean>();
  private _allowAddSupportHandler: Observable<boolean> = this._allowAddSupportSource.asObservable();
  get allowAddSupportHandler(): Observable<boolean> {
    return this._allowAddSupportHandler;
  }
  set allowAddSupportHandler(value: Observable<boolean>) {
    this._allowAddSupportHandler = value;
  }
  private _allowAddSupportFromButton: boolean = true;
  get allowAddSupportFromButton(): boolean {
    return this._allowAddSupportFromButton;
  }
  set allowAddSupportFromButton(value: boolean) {
    this._allowAddSupportFromButton = value;
    this._allowAddSupportSource.next(this._allowAddSupportFromButton);
  }

  get mapManager(): MapMgr {
    return this.viewer.mapMgr;
  }
  get map(): Map {
    return this.mapManager.map;
  }
  get view(): View {
    return this.mapManager.view;
  }
  get olMap(): ol.Map {
    return this.map.olMap;
  }
  get olView(): ol.View {
    return this.view.olView;
  }

  get mapClickedListener(): Observable<MouseEvent> {
    return this.map.getEventListener('click').observable;
  }
  get mapContextMenuListener(): Observable<MouseEvent> {
    return this.map.getEventListener('contextmenu').observable;
  }

  refresh() {
    this.map.refresh();
  }



  zoomToFeatures(extent) {
    this.olView.fit(extent);
  }

  private addSignModeActivatedSource = new Subject<boolean>();

  addSignModeActivatedHandler: Observable<boolean> = <Observable<boolean>>this.addSignModeActivatedSource.asObservable();

  private highlightedSupportsSource = new Subject<Support[]>();
  highlightedSupportsHandler: Observable<Support[]> = <Observable<Support[]>>this.highlightedSupportsSource.asObservable();
  set highlightedSupports(val: Support[]) {
    this._highlightedSupports = val;
    this.highlightedSupportsSource.next(val);
  }
  get highlightedSupports(): Support[] {
    return this._highlightedSupports;
  }

  private highlightedZoneSource = new Subject<string[]>();
  highlightedZoneHandler: Observable<string[]> = <Observable<string[]>>this.highlightedZoneSource.asObservable();
  set highlightedZone(val: string[]) {
    this._highlightedZone = val;
    this.highlightedZoneSource.next(val);
  }
  get highlightedZone(): string[] {
    return this._highlightedZone;
  }

  private selectedSupportsSource = new Subject<Support[]>();

  selectedSupportsHandler: Observable<Support[]> = <Observable<Support[]>>this.highlightedSupportsSource.asObservable();
  set selectedSupports(val: Support[]) {
    this._selectedSupports = val;
    this.selectedSupportsSource.next(val);
  }
  get selectedSupports(): Support[] {
    return this._selectedSupports;
  }

  set addSignModeActivated(val: boolean) {
    this._addSignModeActivated = val;
  }
  get addSignModeActivated(): boolean {
    return this._addSignModeActivated;
  }

  set selectSignModeActivated(val: boolean) {
    this._selectSignModeActivated = val;
  }
  get selectSignModeActivated(): boolean {
    return this._selectSignModeActivated;
  }

  /**
   * Getter language
   * @return {string}
   */
  public get language(): string {
    return this._language;
  }

  /**
   * Setter language
   * @param {string} value
   */
  public set language(value: string) {
    this._language = value.toLowerCase();
    if (this.viewer) {
      this.viewer.language = this.language;
      let urbisPlan = <WMTS>this.viewer.mapMgr.basemaps.find(basemap => {
        return basemap.id == 'urbis_plan';
      })
      urbisPlan.layerId = 'urbis' + this.language.toUpperCase();
    }

    this.languageSource.next(value);
  }

  /**
   * Getter languageSource
   * @return {Subject<string> }
   */
  public get languageSource(): Subject<string> {
    return this._languageSource;
  }

  /**
   * Setter languageSource
   * @param {Subject<string> } value
   */
  public set languageSource(value: Subject<string>) {
    this._languageSource = value;
  }

  /**
   * Getter languageHandler
   * @return {Observable<string> }
   */
  public get languageHandler(): Observable<string> {
    return this._languageHandler;
  }

  /**
   * Setter languageHandler
   * @param {Observable<string> } value
   */
  public set languageHandler(value: Observable<string>) {
    this._languageHandler = value;
  }


  constructor() { }
}
