import {Component, Injector, OnInit} from '@angular/core';
import {MobileWizardComponent} from "../mobile-wizard.component";
import {LayoutService} from "../../../core/services/layout.service";
import {Sign} from "../../../models/sign.model";
import {SignService} from "../../../core/services/sign.service";
import {Support} from "../../../models/support.model";
import {SupportService} from "../../../core/services/support.service";
import {ReorderPanelsComponent} from "../reorder-panels/reorder-panels.component";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {MapComponent} from "../../map/map.component";
import {MapService} from "../../../core/services/map.service";


@Component({
  selector: 'add-sign-to-support',
  templateUrl: './add-sign-to-support.component.html',
  styleUrls: ['./add-sign-to-support.component.scss']
})
export class AddSignToSupportComponent extends MobileWizardComponent implements OnInit
{

  newSign: Sign;
  selectedSupport: Support;
  mode: string;

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private signService: SignService,
              layoutService: LayoutService,
              private injector: Injector,
              private mapService: MapService,
              i18n: TranslateService,
              confirmationService: ConfirmationDialogService,
              private supportService: SupportService) {
    super({}, layoutService, confirmationService, i18n);
    this.stepsNumber = 4;
  }

  async ngOnInit() {
    await this.initConfig();
    this.initWizard();
    this.newSign = new Sign({});
    this.selectedSupport = this.supportService.support;
    this.mode = this.signService.addToSupportMode;
    this.signService.readMode = false;
  }

  async initConfig() {
    let title = await this.translate.get("SIGN_ENCODING").toPromise();
    this.config = {
      0: {
        title: title
      },
      1: {
        title: title
      },
      2: {
        title: title
      },
      3: {
        title: title
      }
    }
  }

  async save() {
    this.layoutService.loading = true;
    this.newSign.supportId = this.selectedSupport.id;
    try{
      this.newSign.mobileStatus = this.signService.MOBILE_STATUS_ADD;
      this.newSign = await this.signService.createOrUpdateSign(this.newSign);
      this.selectedSupport.signs = await this.signService.getSignsForSupportId(this.selectedSupport.id);
      this.supportService.support = this.selectedSupport;
      this.layoutService.loading = false;
      this.layoutService.backComponent = ReorderPanelsComponent;
      this.mapService.refresh();
      this.backToReorderPanelsOfSupport(true);
    }catch(error){
      this.confirmationDialogService.yesCallBack = function(){
        this.layoutService.leftPanelVisible = false;
        this.layoutService.loading = false;
        this.backToReorderPanelsOfSupport(true);
      }.bind(this);
      this.onError();
    }
  }

  async backToReorderPanelsOfSupport(forceReorder: boolean) {
    if(this.signService.addToSupportMode != 'FROM MAP' || forceReorder!= null){
      this.layoutService.loading = true;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = this.layoutService.backComponent;
      this.layoutService.loading = false;
    }else{
      this.layoutService.rightPanelVisible = false;
    }
  }

}
