import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {MapRoutingModule} from './map-routing.module';
import {MapComponent} from './map.component';
import {GisViewerUIModule} from '@nsi/gis-viewer-ui';
import {PanelSupportListComponent} from '../supports/panel-support-list/panel-support-list.component';
import {PanelSignListComponent} from "../signs/panel-sign-list/panel-sign-list.component";
import {ContextMenuModule} from 'primeng/contextmenu';
import {MapMenuContentDirective} from "./map.menu.content.directive";
import {RcComponent} from '../rc/rc.component';
import { LayoutActionsPanelModule } from '../actions-panel/layout.actions-panel.module';

const MODULES = [
  SharedModule,
  MapRoutingModule,
  GisViewerUIModule,
  ContextMenuModule,
  LayoutActionsPanelModule
];

const DECLARATIONS = [
  MapComponent,
  PanelSupportListComponent,
  PanelSignListComponent,
  RcComponent
];


@NgModule({
  imports: MODULES,
  declarations: [[MapMenuContentDirective],...DECLARATIONS],
  entryComponents:DECLARATIONS
})
export class MapModule {}
