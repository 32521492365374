import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {SupportsRoutingModule} from './supports-routing.module';
import {SupportsComponent} from './supports.component';
import {SupportComponent} from './support/support.component';
import {AutoCompleteModule} from 'primeng/primeng';
import {DragulaModule} from "ng2-dragula";
import { EditSignOrderComponent } from '../signs/edit-sign-order/edit-sign-order.component';
import { SignComponent } from '../signs/sign/sign.component';

const MODULES = [
  SharedModule,
  SupportsRoutingModule
];

const DECLARATIONS = [
  SupportsComponent,
  SupportComponent
];


@NgModule({
    imports: MODULES,
    declarations: DECLARATIONS,
    entryComponents: [SupportComponent]
})
export class SupportsModule {}
