import {Component, Injector, Input, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {TranslateService} from "@ngx-translate/core";
import {Code, SelectCode, SignCategory} from "../../../models/code.model";
import {Sign} from "../../../models/sign.model";
import {SignTypeService} from "../../../core/services/sign-type.service";
import {SignCategoryService} from "../../../core/services/sign-category.service";
import {FormBuilder} from "@angular/forms";
import {LayoutService} from "../../../core/services/layout.service";
import {SignGestionService} from "../../../core/services/sign-gestion.service";
import {MobileWizardComponentStep} from "../mobile-wizard-step.component";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {UserService} from "../../../core/services/user.service";


@Component({
  selector: 'create-panel',
  templateUrl: './create-panel.component.html'
})
export class CreatePanelComponent extends MobileWizardComponentStep implements OnInit
{
  @Input() support: Support;

  @Input() sign: Sign;

  selectedCategory: SignCategory = null;

  signTypeOptions: SelectCode[] = new Array();
  signCategoryOptions: SelectCode[] = new Array();
  signGestionOptions: SelectCode[] = new Array();

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private signTypeService: SignTypeService,
              private injector: Injector,
              private signCategoryService: SignCategoryService,
              private signGestionService: SignGestionService,
              private fb: FormBuilder,
              public userService: UserService,
              confirmationService: ConfirmationDialogService,
              layoutService: LayoutService) {
    super(layoutService, confirmationService);
  }

  async ngOnInit() {
    this.layoutService.loading = true;
    if(this.sign == null){
      this.sign = new Sign({});
    }
    if(this.sign.type){
      this.selectedCategory = this.sign.type.typeCategorie;
    }
    this.form = this.fb.group({
      type: this.fb.control(''),
      gestion: this.fb.control(''),
      category: this.fb.control('')
    });
    this.registerListener();
    await Promise.all([
      this.loadCode(this.signCategoryService,this.signCategoryOptions, "EMPTY"),
      this.loadCode(this.signGestionService,this.signGestionOptions, "EMPTY")]);
    if(this.selectedCategory != null){
      await Promise.all([this.loadSignTypesForCategory()]);
      this.loaded = true;
      this.layoutService.loading = false;
    }else{
      this.sign.gestion = this.signGestionService.default;
      this.loaded = true;
      this.layoutService.loading = false;
    }
  }

  async loadSignTypesForCategory() {
    this.signTypeOptions = [];
    let values:Code[];
    try{
      values = await this.signTypeService.filter([this.selectedCategory.id]);
      let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
      translatedValues.sort((a, b) => {
        if(a.label>b.label){
          return 1;
        }else if(a.label<b.label){
          return -1;
        }else{
          return 0;
        }
      });
      this.signTypeOptions = [];
      translatedValues.forEach(async val => {
        this.signTypeOptions.push(val);
      });
      this.sign.type = null;
      if(this.signTypeOptions.length == 0){
        this.sign.type = null;
      }else{
        this.sign.type = this.sign.type == null ? this.signTypeService.defaultFiltered : this.sign.type;
        this.sign.type.typeCategorie = this.selectedCategory;
      }
    }catch(error){
      this.sign.type = null;
    }
    return;
  }

  signTypeSelected() {
    this.sign.type.typeCategorie = this.selectedCategory;
  }

  async loadCode(service, options: SelectCode[], empty:string):Promise<undefined>{
    let values:Code[] = await service.findAll();

    let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
    translatedValues.sort((a, b) => {
      if(a.label>b.label){
        return 1;
      }else if(a.label<b.label){
        return -1;
      }else{
        return 0;
      }
    });
    if(empty){
      options.push(new SelectCode("", new Code(null,empty)));
    }
    translatedValues.forEach(val => options.push(val));
    return;
  }

}
