enum roles {
  MUNICIPALITY_AGENT="MUNICIPALITY_AGENT",
  SPRB_AGENT="SPRB_AGENT",
  SECRETARY_CC="SECRETARY_CC",
  PARTNER="PARTNER"
}

export class Role {

  public id?: number;
  public code?: string;

  constructor(
    obj:any
  ) {
    Object.assign(this,obj)
  }

  static rolesAvaible(){
    return roles;
  }
}
