import {environment} from '../../environments/environment';

export class Code{

  public id?: number;
  public code?: string;
  constructor(
    id?: number,
    code?: string
  ) {
    this.id = id ? id : null;
    this.code = code ? code : null;
  }

}

export class SelectCode{

  public label?: string;
  public value?: Code;
  constructor(
    label?: string,
    value?: Code
  ) {
    this.label = label ? label : null;
    this.value = value ? value : null;
  }
}

export class SupportGestion extends Code{}

export class SupportType extends Code{}

export class SupportSpecificity extends Code{}



export class SignCategory extends Code{}

export class SignGestion extends Code{}

export class SignPlacement extends Code{}

export class SignSpecificity extends Code{}

export class SignFormat extends Code{}

export class SignType extends Code{
  public typeCategorie?: TypeCategorie;
  public generic?: boolean;
  public fr?: string;
  public nl?: string;

  constructor(
    id?: number,
    code?: string,
    typeCategorie?: TypeCategorie,
    generic?: boolean
  ) {
    super(id, code);
    this.typeCategorie = typeCategorie ? typeCategorie : null;
    this.generic = generic ? generic : false;
  }

  get label():string{
    return 'SIGN:LABEL_'.concat(this.code);
  }

  get img():string{
    return environment.imageTypePath+this.code+'.png';
  }
}


export class TypeCategorie extends Code{}

export class Intervention extends Code{}
