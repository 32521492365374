import {Component, OnInit} from '@angular/core';
import { LayoutService } from '../../../core/services/layout.service';
import {SupportManagementComponent} from "../../mobile/support-management/support-management.component";


@Component({
   selector: 'mobile-add-support-button',
   templateUrl: './layout.mobile-add-support-button.component.html',
   styleUrls: ['./layout.mobile-add-support-button.component.scss']
})
export class MobileAddSupportButtonComponent implements OnInit
{

  constructor(
      private layoutService: LayoutService) {

  }

  ngOnInit() {

  }

  async showAddSupport() {
    this.layoutService.rightPanelContent=SupportManagementComponent;
    this.layoutService.rightPanelVisible=true;
  }

}
