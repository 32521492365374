import {Component, Injector, OnInit} from '@angular/core';
import {LayoutService} from '../../../core/services/layout.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserService} from '../../../core/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {Message} from 'primeng/components/common/api';
import {Code, SelectCode} from '../../../models/code.model';
import {SignTypeService} from '../../../core/services/sign-type.service';
import {SignCategoryService} from '../../../core/services/sign-category.service';
import {SignGestionService} from '../../../core/services/sign-gestion.service';
import {SignPlacementService} from '../../../core/services/sign-placement.service';
import {SignSpecificityService} from '../../../core/services/sign-specificity.service';
import {SupportGestionService} from '../../../core/services/support-gestion.service';
import {SupportTypeService} from '../../../core/services/support-type.service';
import {SupportSpecificityService} from '../../../core/services/support-specificity.service';
import {Role} from '../../../models/role.model';
import {Municipality} from '../../../models/municipality.models';
import {MunicipalityService} from '../../../core/services/municipality.service';
import {SupportService} from '../../../core/services/support.service';
import {PanelSearchRcResultComponent} from '../../rc/panel-search-rc-result/panel-search-rc-result.component';
import {PanelSignListComponent} from '../panel-sign-list/panel-sign-list.component';
import {SignService} from '../../../core/services/sign.service';

@Component({
   selector: 'panel-search-sign',
   templateUrl: './panel-search-sign.component.html',
   styleUrls: ['./panel-search-sign.component.scss']
})
export class PanelSearchSignComponent implements OnInit
{

  translate: TranslateService = this.injector.get(TranslateService);
  errorMessages: Message[] = [];

  searchSignForm: FormGroup;

  signGestionOptions: SelectCode[] = new Array();
  signMuncipalityOptions: SelectCode[] = new Array();
  signPlacementOptions: SelectCode[] = new Array();
  signTypeCategoryOptions: SelectCode[] = new Array();
  signTypeOptions: SelectCode[] = new Array();
  signSpecificityOptions: SelectCode[] = new Array();
  supportGestionOptions: SelectCode[] = new Array();
  supportTypeOptions: SelectCode[] = new Array();
  supportSpecificityOptions: SelectCode[] = new Array();


  displayNoResult:boolean = false;


  signSearch = {
    signGestion: [],
    supportMunicipality: [],
    signPlacement: [],
    signRequestType: null,

    signTypeCategory: [],
    signType: [],
    signSpecificity: [],

    signPlacementDateStart: null,
    signPlacementDateEnd: null,
    signRemovalDateStart: null,
    signRemovalDateEnd: null,

    supportGestion: [],
    supportType: [],
    supportSpecificity: [],
    supportAddress: [],
    supportNumber: null
  };

  signSearchSend = {
    signGestionId: [],
    supportMunicipalityId: [],
    signPlacementId: [],
    signRequestType: null,

    signTypeCategoryId: [],
    signTypeId: [],
    signSpecificityId: [],

    signPlacementDateStart: null,
    signPlacementDateEnd: null,
    signRemovalDateStart: null,
    signRemovalDateEnd: null,

    supportGestionId: [],
    supportTypeId: [],
    supportSpecificityId: [],
    supportAddress: [],
    supportNumber: null
  };

  notOnFieldCheck:boolean = false;


  constructor(private layoutService: LayoutService,
              private userService: UserService,
              private signGestionService: SignGestionService,
              private municipalityService: MunicipalityService,
              private signPlacementService: SignPlacementService,
              private signCategoryService: SignCategoryService,
              private signTypeService: SignTypeService,
              private signSpecificityService: SignSpecificityService,
              private supportGestionService: SupportGestionService,
              private supportTypeService: SupportTypeService,
              private supportSpecificityService: SupportSpecificityService,
              private supportService: SupportService,
              private signService: SignService,
              private injector: Injector,
              private fb: FormBuilder) {}
  ngOnInit() {
    this.initComponent();

    this.searchSignForm = this.fb.group({
      signGestion: this.fb.control(''),
      supportMunicipality: this.fb.control(''),
      signPlacement: this.fb.control(''),
      signAskingType: this.fb.control(''),

      signTypeCategory: this.fb.control(''),
      signType: this.fb.control(''),
      signSpecificity: this.fb.control(''),

      notOnFieldCheck: this.fb.control(''),
      signPlacementDateStart: this.fb.control(''),
      signPlacementDateEnd: this.fb.control(''),
      signRemovalDateStart: this.fb.control(''),
      signRemovalDateEnd: this.fb.control(''),

      supportGestion: this.fb.control(''),
      supportType: this.fb.control(''),
      supportSpecificity: this.fb.control(''),
      supportAddress: this.fb.control(''),
      supportNumber: this.fb.control(''),
    });

  }


  async initComponent(){

    this.layoutService.loading = true;
    try{
      this.signSearch = {
        signGestion: [],
        supportMunicipality: [],
        signPlacement: [],
        signRequestType: null,

        signTypeCategory: [],
        signType: [],
        signSpecificity: [],

        signPlacementDateStart: null,
        signPlacementDateEnd: null,
        signRemovalDateStart: null,
        signRemovalDateEnd: null,

        supportGestion: [],
        supportType: [],
        supportSpecificity: [],
        supportAddress: [],
        supportNumber: null
      };
      this.loadLists();
    }catch(e){

    }
    this.layoutService.loading = false;

  }

  async loadLists(){
    return await Promise.all([
      this.loadCode(this.signGestionService,this.signGestionOptions, "ALL"),
      this.loadMunicipality(this.municipalityService,this.signMuncipalityOptions),
      this.loadCode(this.signPlacementService,this.signPlacementOptions, "ALL"),
      this.loadCode(this.signCategoryService,this.signTypeCategoryOptions, "ALL"),
      this.loadCode(this.signTypeService,this.signTypeOptions, "ALL"),
      this.loadCode(this.signSpecificityService,this.signSpecificityOptions, "ALL"),
      this.loadCode(this.supportGestionService,this.supportGestionOptions, "ALL"),
      this.loadCode(this.supportTypeService,this.supportTypeOptions, "ALL"),
      this.loadCode(this.supportSpecificityService,this.supportSpecificityOptions, "ALL"),
    ]);
  }

  async loadCode(service, options: SelectCode[], other:string):Promise<undefined>{

    try{
      options.splice(0,options.length);
      let values:Code[] = await service.findAll();

      let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
      translatedValues.sort((a, b) => {
        if(a.label>b.label){
          return 1;
        }else if(a.label<b.label){
          return -1;
        }else{
          return 0;
        }
      });
      translatedValues.forEach(val => options.push(val));
      if(other){
        let otherCode:Code = new Code(0, await this.translate.get(other).toPromise());
        let otherSelectCode:SelectCode = new SelectCode(await this.translate.get(otherCode.code).toPromise(), otherCode);
        options.unshift(otherSelectCode);
      }
    }catch(error){
      console.log(error);

      let tmp1 = new SelectCode("aaa", new Code(1, "aaa"));
      let tmp2 = new SelectCode("bbb", new Code(2, "bbb"));
      let tmp3 = new SelectCode("ccc", new Code(3, "ccc"));
      let tmp4 = new SelectCode("ddd", new Code(4, "ddd"));
      options.push(tmp1);
      options.push(tmp2);
      options.push(tmp3);
      options.push(tmp4);

    }


    return;
  }

  async loadMunicipality(service, options: SelectCode[]):Promise<undefined>{
    try{
      options.splice(0,options.length);
      let values:Municipality[] = await service.findAll();
      values.sort((a, b) => {
        if(a.name>b.name){
          return 1;
        }else if(a.name<b.name){
          return -1;
        }else{
          return 0;
        }
      });
      options.push(new SelectCode(await this.translate.get("ALL").toPromise(), new Code(0,"all")));
      values.forEach(val => {
        let selectCode:SelectCode = new SelectCode (val.name, new Code(val.id, val.name));
        options.push(selectCode);
      });
      this.signSearch.supportMunicipality = [new Code(0, "all")];

    }catch(error){
      //TODO REMOVE
      options.push(new SelectCode(await this.translate.get("ALL").toPromise(), new Code(0, "all")));
      options.push(new SelectCode("Bruxelle", new Code(1, "Bruxelle")));
      options.push(new SelectCode("Anderlecht", new Code(2, "Anderlecht")));
      options.push(new SelectCode("Scharbeek", new Code(3, "Scharbeek")));
      this.signSearch.supportMunicipality = [new Code(0, "all")];
    }


    return;
  }



  async updateType(){
    //TODO REMOVE
    let tmp = this.signTypeOptions.slice(0,5);

    //clean Array
    this.signTypeOptions.splice(0,this.signTypeOptions.length);
    try{
      let values:Code[] = await this.signTypeService.filter([]);

      let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
      translatedValues.sort((a, b) => {
        if(a.label>b.label){
          return 1;
        }else if(a.label<b.label){
          return -1;
        }else{
          return 0;
        }
      });
      translatedValues.forEach(val => this.signTypeOptions.push(val));
      let other = "ALL";
      if(other){
        let otherCode:Code = new Code(0, await this.translate.get(other).toPromise());
        let otherSelectCode:SelectCode = new SelectCode(await this.translate.get(otherCode.code).toPromise(), otherCode);
        this.signTypeOptions.unshift(otherSelectCode);
      }
    }catch(error){
      console.log(error);
      //TODO REMOVE
      tmp.forEach(t => this.signTypeOptions.push(t))
    }
  }

  address: string;
  newData:any;
  results:string[]=[];

  public async search(event: any) {
    try{
      let res = await this.supportService.getStreets(this.translate.currentLang,event.query);
      let data:any[]=res.result.map(resVal=>{
        let street = resVal.address.street;
        street.label = street.name + ' ( '+street.postCode+' )';
        return street;
      });

      if(data && data.length == 0) this.newData = event.query;
      else this.newData = null;

      this.results = data;
    }catch(error){
      //TODO error handling
    }
  }

  set editAddress(street:any){
    this.address=JSON.stringify(street);
  }
  get editAddress():any{
    let address:any;
    try{
      address=JSON.parse(this.address);
    }catch(e){

    }
    return address;
  }


  async submit(){

    if(!this.searchSignForm.invalid){
      if(this.notOnFieldCheck){
        this.signSearch.signPlacementDateStart = null;
        this.signSearch.signPlacementDateEnd = null;
        this.signSearch.signRemovalDateStart = null;
        this.signSearch.signRemovalDateEnd = null;
      }
      this.layoutService.loading = true;
      this.errorMessages = [];

      try {
        this.signService.signsSelected = [];

        this.signSearchSend.signGestionId = this.signSearch.signGestion.map(data=>data.id);
        this.signSearchSend.supportMunicipalityId = this.signSearch.supportMunicipality.map(data=>data.id);
        this.signSearchSend.signPlacementId = this.signSearch.signPlacement.map(data=>data.id);
        this.signSearchSend.signRequestType = this.signSearch.signRequestType;
        this.signSearchSend.signTypeCategoryId = this.signSearch.signTypeCategory.map(data=>data.id);
        this.signSearchSend.signTypeId = this.signSearch.signType.map(data=>data.id);
        this.signSearchSend.signSpecificityId = this.signSearch.signSpecificity.map(data=>data.id);
        this.signSearchSend.signPlacementDateStart = this.signSearch.signPlacementDateStart;
        this.signSearchSend.signPlacementDateEnd = this.signSearch.signPlacementDateEnd;
        this.signSearchSend.signRemovalDateStart = this.signSearch.signRemovalDateStart;
        this.signSearchSend.signRemovalDateEnd = this.signSearch.signRemovalDateEnd;
        this.signSearchSend.supportGestionId = this.signSearch.supportGestion.map(data=>data.id);
        this.signSearchSend.supportTypeId = this.signSearch.supportType.map(data=>data.id);
        this.signSearchSend.supportSpecificityId = this.signSearch.supportSpecificity.map(data=>data.id);
        this.signSearchSend.supportAddress = this.signSearch.supportAddress.map(data=>JSON.stringify(data));
        this.signSearchSend.supportNumber = this.signSearch.supportNumber;

        this.signService.signsSelected = await this.signService.searchFull(this.signSearchSend);
        if(this.signService.signsSelected.length==0){
          this.displayNoResult = true;
        }else{
          this.layoutService.leftPanelContent = PanelSignListComponent;
          this.layoutService.leftPanelVisible = true;
        }
      }catch(error){
        console.log(error);
        if(typeof(error.error) == typeof("string")){
          this.errorMessages.push({severity:'error', summary:'Error', detail:error.error});
        }else{
          this.errorMessages.push({severity:'error', summary:'Error', detail:error.message});
        }

      }
      this.layoutService.loading = false;
    }
  }

  reset(){
    this.initComponent();
  }

  close(){
    this.layoutService.closeRightPanel();
  }
}
