import {Layout} from "app/models/layout.model";
export class AppConfig
{
    // Global
    apiUrl: string = 'api/';
    lang:string = 'fr';
    languages:string[] = ['fr'];

    // Layout
    layout:Layout = new Layout();
}
