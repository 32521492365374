import {Rc} from './rc.model';
import {Sign} from './sign.model';


enum taskKey {
  COM_T01=1,
  COM_T02=2,
  COM_T03=3,
  COM_T04=4,
  COM_T05=5,

  REG_T01=2,
  REG_T02=3,
  REG_T03=4,
  REG_T04=5,
}


export class Task {
  public taskId: number;
  public businessId: number;
  public taskKey: string;
  public processInstanceId?: number;
  public processDefinitionId?: number;
  public assignee?: number;
  public startDate?: Date;
  public dueDate?: Date;
  public remainingDays?: number;
  public aprobationDate?: Date;
  public isApproval?: boolean;
  public opinionDate?: Date;
  public isFavorable?: boolean;
  public approvalDate?: Date;
  public isMinisterApproval?: boolean;
  public finalDate?: Date;

  public validationDate?: Date;
  public isValid?: boolean;

  public rc: Rc;


  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
    this.dueDate = obj.dueDate?new Date(obj.dueDate):null;
    this.validationDate = obj.validationDate?new Date(obj.validationDate):null;
    // this.approvalDate = obj.approvalDate?new Date(obj.approvalDate):null;
    // this.opinionDate = obj.opinionDate?new Date(obj.opinionDate):null;
    // this.aprobationDate = obj.aprobationDate?new Date(obj.aprobationDate):null;
    this.startDate = obj.startDate?new Date(obj.startDate):null;
    this.finalDate = obj.finalDate?new Date(obj.finalDate):null;

    if(obj && obj.rc){
      this.rc = new Rc(obj.rc);
    }

  }

  get statusTitle():string{
    switch (taskKey[this.taskKey]){
      case taskKey.COM_T01:
        return "PANEL_TASK_LIST:COM_T01:TITLE";
      case taskKey.COM_T02:
        return "PANEL_TASK_LIST:COM_T02:TITLE";
      case taskKey.COM_T03:
        return "PANEL_TASK_LIST:COM_T03:TITLE";
      case taskKey.COM_T04:
      case taskKey.COM_T05:
        return "PANEL_TASK_LIST:COM_T04:TITLE";
    }
  }

  get signsAndSupports():Sign[]{
    return this.rc.signs;
  }

}
