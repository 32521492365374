import {Component, Input, OnInit} from '@angular/core';
import {LayoutService} from '../../../core/services/layout.service';
import {SignService} from '../../../core/services/sign.service';

@Component({
   selector: 'title-header',
   templateUrl: './title-header.component.html',
   styleUrls: ['./title-header.component.scss']
})
export class TitleHeaderComponent implements OnInit
{
  @Input() title: string;
  @Input() icon: boolean = false;
  @Input() iconClass: string;
  @Input() button: boolean = false;
  @Input() buttonClass: string;
  @Input() buttonIcon: string;
  @Input() buttonAction;
  @Input() direction: string;
  @Input() buttonActionClose;

  constructor(private layoutService: LayoutService,
              private signService: SignService) {}
  ngOnInit() {
    if(this.buttonActionClose){
      this.close = this.buttonActionClose;
    }
  }


  get right(){
    if(this.direction == "RIGHT"){
      return true;
    }
    return false;
  }
  get left(){
    if(this.direction == "LEFT"){
      return true;
    }
    return false;
  }

  uiG():string{

    let max:number = 12;
    let close:number = 3;
    let expand:number = 0;
    let icon:number = 0;
    let button:number = 2;

    max -= close;

    if(this.right || this.left){
      max -= expand;
    }
    if(this.icon){
      max -= icon;
    }
    if(this.button){
      max -= button;
    }

    return `ui-g-${max}`;
  }

  expand(){
    //alert("Not implemented : expand");
    if(this.right){
      this.layoutService.leftPanelFull = false;
      this.layoutService.rightPanelFull = !this.layoutService.rightPanelFull;
    }else if(this.left){
      this.layoutService.rightPanelFull = false;
      this.layoutService.leftPanelFull = !this.layoutService.leftPanelFull;
    }
  }

  close(){
    console.log(this.direction);
    switch(this.direction){
      case "LEFT":
        this.layoutService.closeLeftPanel();
        break;
      case "RIGHT":
        this.layoutService.closeRightPanel();
        break;
    }
  }

}
