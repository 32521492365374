// Angular Modules
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {
  AutoCompleteModule,
  CarouselModule, ConfirmDialogModule, DataListModule, FieldsetModule, FileUploadModule, GalleriaModule,
  MultiSelectModule,
  RadioButtonModule, TabViewModule,
  ToggleButtonModule
} from 'primeng/primeng';
import {MenubarModule} from 'primeng/menubar';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {DataGridModule} from 'primeng/datagrid';
import {PanelModule} from 'primeng/panel';
import {InputTextModule} from 'primeng/inputtext';
import {SidebarModule} from 'primeng/sidebar';
import {GMapModule} from 'primeng/gmap';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DropdownModule} from 'primeng/dropdown';
import {DragDropModule} from 'primeng/dragdrop';
import {CalendarModule} from 'primeng/calendar';
import {LightboxModule} from 'primeng/lightbox';
import {AccordionModule} from 'primeng/accordion';
import {TableModule} from 'primeng/table';
import {EditorModule} from 'primeng/editor';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';

// Pipes
import {PropercasePipe} from './pipes/propercase.pipe';
import {EllipsisPipe} from './pipes/ellipsis.pipe';

// Components
import {PageHeaderComponent} from './components/page-header.component';
import {AccessDeniedComponent} from "./components/access-denied/access-denied.component";
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { PictureViewComponent } from "./components/picture-view/picture-view.component";
import { TitleHeaderComponent } from "./components/title-header/title-header.component";
import {LoaderComponent} from "../features/layout/loader/loader.component";
import {Ng2ImgMaxModule} from "ng2-img-max";
import {EditSignOrderComponent} from "../features/signs/edit-sign-order/edit-sign-order.component";
import {DragulaModule} from "ng2-dragula";
import {MenuModule} from "primeng/menu";


const MODULES: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,
  TranslateModule,
  TabViewModule,
  MenubarModule,
  ButtonModule,
  ToggleButtonModule,
  DialogModule,
  DataGridModule,
  PanelModule,
  InputTextModule,
  SidebarModule,
  GMapModule,
  MessagesModule,
  MessageModule,
  OverlayPanelModule,
  DropdownModule,
  DragDropModule,
  CalendarModule,
  LightboxModule,
  ConfirmDialogModule,
  DataListModule,
  AccordionModule,
  ScrollPanelModule,
  TableModule,
  CarouselModule,
  FileUploadModule,
  EditorModule,
  RadioButtonModule,
  MultiSelectModule,
  CheckboxModule,
  ChipsModule,
  AutoCompleteModule,
  FieldsetModule,
  Ng2ImgMaxModule,
  DragulaModule,
  GalleriaModule,
  MenuModule
];

const DECLARATIONS = [
  PropercasePipe,
  EllipsisPipe,
  PageHeaderComponent,
  AccessDeniedComponent,
  PictureViewComponent,
  TitleHeaderComponent,
  LoaderComponent,
  EditSignOrderComponent
];

@NgModule({
   imports: MODULES,
   declarations: DECLARATIONS,
   exports: [...MODULES, ...DECLARATIONS]
})
export class SharedModule { }
