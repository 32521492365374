import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'action-icons',
  templateUrl: './layout.action-icons.component.html',
  styleUrls: ['./layout.action-icons.component.scss']
})
export class LayoutActionIconsComponent {
  activeActionItem:any;
  constructor(public userService:UserService) {
  }

  ngOnInit() {
    
    
  }
}
