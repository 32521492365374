import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
   selector: 'stepper',
   templateUrl: './stepper.component.html',
   styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit
{

  @Input()
  stepsNumber: number;

  @Input()
  actualStep: number;

  forwardDisabled = false;
  backwardDisabled = false;

  @Output()
  outputStepsMoved  = new EventEmitter<number>();

  constructor() {

  }

  ngOnInit() {
    if(this.actualStep == null){
      this.actualStep = this.stepsNumber - this.stepsNumber + 1;
    }
    this.backwardDisabled = this.actualStep == 1;
    this.forwardDisabled = this.actualStep == this.stepsNumber-1;
  }

  goBackward() {
    if(!this.backwardDisabled){
      this.actualStep -= 1;
      this.outputStepsMoved.emit(this.actualStep);
      this.updateButtons();
    }
  }

  goForward() {
    if(this.actualStep == this.stepsNumber) {
      this.outputStepsMoved.emit(-1);
      return;
    }
    if(!this.forwardDisabled){
      this.actualStep += 1;
      this.outputStepsMoved.emit(this.actualStep);
      this.updateButtons();
    }
  }

  updateButtons() {
    this.forwardDisabled = this.actualStep == this.stepsNumber;
    this.backwardDisabled = this.actualStep == 1;
  }

  getButtonClass(id) {
    if(id == -1){
      return this.backwardDisabled ? 'stepButtonDisabled' : 'stepButton';
    }else{
      return this.forwardDisabled ? 'stepButtonDisabled' : 'stepButton';
    }
  }

}
