import {environment} from "../../../../environments/environment";
import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer">
            <div class="card clearfix">
                <span class="footer-text-left">
                    Version : {{ environment.version }}
                    - Build date : {{ environment.buildDate | date:'dd-MM-y' }}
                </span>
                <span class="footer-text-right">
                    <span class="material-icons ui-icon-copyright"></span>  
                    <span>All Rights Reserved</span>
                </span>
            </div>
        </div>
    `
})
export class LayoutFooterComponent {
    environment = environment;
}
