import {Injectable} from '@angular/core';
import {Code} from '../../models/code.model';
import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';
import {Observable} from 'rxjs/Observable';
import {HttpEvent} from '@angular/common/http';

@Injectable()
export class SupportSpecificityService extends BaseAbstractService
{
  _codes:Code[];

  /*
  For Mobile
   */
  _defaults = {
    SPRB_AGENT:"URBAN",
    MUNICIPALITY_AGENT:"GALVANIZED",
    CONTRACTOR: "GOLDEN"
  };

  get url():string {
    return environment.backendURL + environment.supportPath+ this.configService.appConfig.apiUrl + 'Support/SupportSpecificities/';
  }

  async findAll(): Promise<Code[]> {
    this._codes= this._codes ? this._codes: await this.get<Code[]>(`${this.url}`);
    return this._codes;
  }

  /*
  For Mobile
   */
  get default():Code{
    let role = this._session.roles[0].code;
    return this._codes.find((gestion)=>{
      return this._defaults[role]?gestion.code == this._defaults[role]:true;
    });
  }
}
