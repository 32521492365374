import {Sign} from './sign.model';
import Utils from '../shared/services/utils';
import {Task} from './task.model';

enum status_enum {
  pending="RC_PENDING",
  active="RC_ACTIVE",
  old="RC_OLD",
  reject="RC_REJECT"
}

enum state_enum {
  soumis="PANEL_SEARCH_RC:SEARCH:STATUS:1",
  approuveConseil="PANEL_SEARCH_RC:SEARCH:STATUS:2",
  refuseConseil="PANEL_SEARCH_RC:SEARCH:STATUS:3",
  avisFavorable="PANEL_SEARCH_RC:SEARCH:STATUS:4",
  avisDefavorable="PANEL_SEARCH_RC:SEARCH:STATUS:5",
  vigueur="PANEL_SEARCH_RC:SEARCH:STATUS:6",
  abroge="PANEL_SEARCH_RC:SEARCH:STATUS:7",
  refuseMinistre="PANEL_SEARCH_RC:SEARCH:STATUS:8",
}

export class Rc {

  public id?: number;
  public rcGedId?: string;
  public title?: string;
  public municipalityId?: number;
  public header?: string;
  public body?: string;
  public footer?: string;
  public status?: string;
  public creationDate?: Date;
  public effectiveDate?: Date;
  public signs?: Sign[];
  public tasks?: Task[];
  public communalPvGedId?: string;
  public secretariaNote?: string;
  public secretariaRcGedId?: string;
  public ministerNote?: string;


  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
    this.effectiveDate = obj.effectiveDate?new Date(obj.effectiveDate):null;
    this.creationDate = obj.creationDate?new Date(obj.creationDate):null;
    if(obj && obj.signs){
      this.signs = obj.signs.map(sign=>new Sign(sign));
    }
  }

  static validStatus(){
    return status_enum;
  }

  taskStatus(code:string):boolean{
    let tmp_tasks = this.tasks.filter(task => task.taskKey == code);
    if(tmp_tasks.length == 1){
      return tmp_tasks[0].isValid;
    }
    return false;
  }

  taskDate(code:string):Date{
    let tmp_tasks = this.tasks.filter(task => task.taskKey == code);
    if(tmp_tasks.length == 1){
      return tmp_tasks[0].validationDate ? new Date(tmp_tasks[0].validationDate) : null;
    }
    return null;
  }


  get lastTask():Task{
    let lastTask:Task = null;
    this.tasks.forEach(task => {
      if(lastTask){
        if(task.startDate > lastTask.startDate){
          lastTask = task;
        }
      }else{
        lastTask = task;
      }
    });
    return lastTask;
  }
}
