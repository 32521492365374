import {Component, OnInit} from '@angular/core';
import {LoggedComponent} from '../../shared/components/logged/logged.component';

@Component({
   selector: 'signs',
   templateUrl: './signs.component.html',
   styleUrls: ['./signs.component.scss']
})
export class SignsComponent extends LoggedComponent{

}
