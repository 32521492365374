import {Component, OnInit} from '@angular/core';
import {TaskService} from "../../../core/services/task.service";
import {Task} from "../../../models/task.model";
import {TaskViewComponent} from "../task-view/task-view.component";
import { LayoutService } from '../../../core/services/layout.service';
import {Rc} from '../../../models/rc.model';

@Component({
  selector: 'panel-task-list',
  templateUrl: './panel-task-list.component.html',
  styleUrls: ['./panel-task-list.component.scss']
})
export class PanelTaskListComponent implements OnInit
{

  signsTypeUnique(rc:Rc){
    let signsTypeUnique = new Set();
    rc.signs.forEach(sign => signsTypeUnique.add(sign.type));
    return Array.from(signsTypeUnique);
  };

  streetsUnique(rc:Rc){
    let streetUnique = new Set();

    rc.signs.forEach(sign => {
      //TODO REFACTOR
      if(sign.support){
        let tmp_Addr = sign.support.addressLabel;
        try {
          let tmp2 = JSON.parse(sign.support.address);
          tmp_Addr = tmp2.label;
        } catch (e) {
          tmp_Addr = sign.support.address;
        }
        streetUnique.add(tmp_Addr)
      }

    });
    return Array.from(streetUnique);
  };


  constructor(private taskService: TaskService,
              private layoutService: LayoutService) {}
  ngOnInit() {
  }

  closePanel(){
    this.layoutService.closeRightPanel();
  }

  selectTask(rc: Rc){
    this.taskService.rcTask = rc;
    this.layoutService.rightPanelContent=TaskViewComponent;
    this.layoutService.rightPanelVisible = true;
  }

  isOver(rc: Rc):boolean{
    return (rc.lastTask ? (rc.lastTask.remainingDays !== undefined ? rc.lastTask.remainingDays >= 0 : false):false);
  }

  close(){
    this.layoutService.leftPanelVisible=false;
  }

  status(rc: Rc):string {

    if (typeof (rc.taskStatus("COM_T01")) == "undefined" || rc.taskStatus("COM_T01") == null) {
      return "COM_T01";
    }else if (typeof (rc.taskStatus("COM_T02")) == "undefined" || rc.taskStatus("COM_T02") == null) {
      return "COM_T02";
    }else if (typeof (rc.taskStatus("COM_T03")) == "undefined" || rc.taskStatus("COM_T03") == null) {
      return "COM_T03";
    }else if( (typeof (rc.taskStatus("COM_T04")) == "undefined"  || rc.taskStatus("COM_T04") == null) && rc.taskStatus("COM_T03") == true){
      return "COM_T04";
    }else if( (typeof (rc.taskStatus("COM_T05")) == "undefined"  || rc.taskStatus("COM_T04") == null) && rc.taskStatus("COM_T03") == false){
      return "COM_T05";
    }


    return '';
  }
}
