import {Component, OnInit} from '@angular/core';
import {Sign} from '../../../models/sign.model';
import {SignService} from '../../../core/services/sign.service';
import {Router} from '@angular/router';
import {SupportService} from '../../../core/services/support.service';
import {SignComponent} from "../../../features/signs/sign/sign.component";
import {MapService} from "../../../core/services/map.service";
import {LayoutService} from '../../../core/services/layout.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../../core/services/user.service';
import {RcComponent} from '../../rc/rc.component';
import {Rc} from '../../../models/rc.model';
import {RcService} from '../../../core/services/rc.service';
import Utils from '../../../shared/services/utils';
import {notImplemented} from '@angular/core/src/render3/util';

@Component({
  selector: 'panel-sign-list',
  templateUrl: './panel-sign-list.component.html',
  styleUrls: ['./panel-sign-list.component.scss'],
})
export class PanelSignListComponent
{
  multiSelect: boolean = true;

  signSelectedBool: boolean = false;
  selectedSupportsIdsChanges:Subscription;

  constructor(private signService: SignService,
				      private supportService:SupportService,
              private router: Router,
              private layoutService:LayoutService,
              private mapService: MapService,
              private userService: UserService,
              private rcService: RcService) {}


  ngOnInit() {
    let ids:number[] = this.supportService.selectedSupportsIds;
    ids && this.addSupportsSigns(ids);
    this.selectedSupportsIdsChanges = this.supportService.selectedSupportsIdsHandler.subscribe((ids:number[])=>{
      ids && this.addSupportsSigns(ids);
    });

  }
  ngOnDestroy() {
    this.selectedSupportsIdsChanges.unsubscribe();
  }

  async addSupportsSigns(supportIds:number[]){

    try{
      let signs = await this.signService.getSignsFullForSupportsIds(supportIds);
      signs = this.signService.signsSelected.concat(signs);
      this.signService.signsSelected = await Utils.makeUniqueArray(signs);
    }catch(error){
      console.log("//TODO ERROR HANDLER");
    }

  }

  isSelected(sign: Sign): boolean{
    return this.signService.signsSelected.find(s => s.id === sign.id)?true:false;
  }

  unselectSign(sign: Sign){
    if(this.multiSelect){
      let index = this.signService.signsSelected.indexOf(this.signService.signsSelected.find(s => s.id === sign.id));
      if (index != -1){
        this.signService.signsSelected.splice(index, 1);
      }
      if(!(this.signService.signsSelected.length>0))
        this.signSelectedBool = false;
    }else{
      this.signService.signsSelected = new Array();
      this.signSelectedBool = false;
    }
  }

  get numberOfElements():number{
    return this.signService.signs.length;
  }

  showSign(sign:Sign){
    this.signService.sign = sign;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = SignComponent;
  }

  generateRC(){
    this.rcService.signs = this.signService.signsSelected;
    this.rcService.rc = new Rc({});
    this.rcService.editMode = true;
    this.layoutService.rightPanelVisible=true;
    this.layoutService.rightPanelContent=RcComponent;
    this.mapService.selectSignModeActivated = false;
    this.close();
  }


  cancelSelect(){
    this.mapService.selectSignModeActivated = false;
    this.resetSelect();
    this.supportService.selectedSupportsIds=new Array();
    this.signService.signsSelected = new Array();
    this.layoutService.leftPanelVisible=false;
  }

  resetSelect(){
    this.signService.signsSelected = new Array();
    this.signSelectedBool = false;
  }

  mouseEnter(sign){
    this.mapService.highlightedSupports=[sign.support];
  }
  mouseLeave(sign){
    this.mapService.highlightedSupports=[];
  }

  showSignComponent(){
    this.layoutService.rightPanelVisible = true;
    this.signService.sign = this.signService.signsSelected[0];
    this.layoutService.rightPanelContent = SignComponent;
  }
  hideSignComponent(){
    this.layoutService.closeRightPanel();
  }

  async export(){
    try{
      let ids:number[] = [];
      this.signService.signsSelected.forEach(sign => {
        ids.push(sign.id);
      });
      let file = await this.signService.export(ids);
    }catch(error){

    }
  }

  close(){
    this.layoutService.leftPanelVisible=false;
  }
}
