import {Injectable} from '@angular/core';
import {BaseAbstractService} from './base-abstract.service';
import {environment} from '../../../environments/environment';
import {Task} from '../../models/task.model';
import {Subject} from '../../../../node_modules/rxjs';
import {Observable} from 'rxjs/Observable';
import {Rc} from '../../models/rc.model';

@Injectable()
export class TaskService extends BaseAbstractService
{
  _tasks:Task[] = new Array();
  private tasksSource = new Subject<Task[]>();
  tasksHandler:Observable<Task[]> = <Observable<Task[]>>this.tasksSource.asObservable();

  set tasks(tasks:Task[]){
    this._tasks = tasks;
  }
  get tasks():Task[]{
    return this._tasks;
  }


  _task:Task = null;
  private taskSource = new Subject<Task>();
  taskHandler:Observable<Task> = <Observable<Task>>this.taskSource.asObservable();

  set task(task:Task){
    this._task = task;
    this.taskSource.next(task);
  }
  get task():Task{
    return this._task;
  }

  _taskKey = {
    "COM_T01":1,
    "COM_T02":2,
    "COM_T03":3,
    "COM_T04":4,
    "COM_T05":5,
    "REG_T01":2,
    "REG_T02":3,
    "REG_T03":4,
    "REG_T04":5,

  };

  _rcTask: Rc;
  private rcTaskSource = new Subject<Rc>();
  rcTaskHandler:Observable<Rc> = <Observable<Rc>>this.rcTaskSource.asObservable();
  get rcTask():Rc{
    return this._rcTask;
  }
  set rcTask(rc:Rc){
    this._rcTask = rc;
    this.rcTaskSource.next(rc);
  }

  _rcTasks: Rc[] = new Array();
  private rcTasksSource = new Subject<Rc[]>();
  rcTasksHandler:Observable<Rc[]> = <Observable<Rc[]>>this.rcTasksSource.asObservable();
  get rcTasks():Rc[]{
    return this._rcTasks;
  }
  set rcTasks(rcs:Rc[]){
    this._rcTasks = rcs;
    this.rcTasksSource.next(rcs);
  }


  get taskKey(){
    return this._taskKey[this.task.taskKey];
  }

  showTaskEvent(part: string):boolean{
    switch(this._taskKey[part]){
      case 1:
        return true;
      case 2:
        if(this.taskKey >= 2){
          return true;
        }
        break;
      case 3:
        if(this.taskKey >= 3){
          return true;
        }
        break;
      case 4:
        if(this.taskKey == 4){
          return true;
        }
        break;
      case 5:
        if(this.taskKey == 5){
          return true;
        }
        break;
      default:
        return false;
    }
    return false;
  }

  waitingTaskEvent(part: string):boolean{
    switch(this._taskKey[part]){
      case 1:
        if(this.taskKey == 1){
          return true;
        }
        break;
      case 2:
        if(this.taskKey == 2){
          return true;
        }
        break;
      case 3:
        if(this.taskKey == 3){
          return true;
        }
        break;
      case 4:
        if(this.taskKey == 4 && !this.task.finalDate){
          return true;
        }
        break;
      case 5:
        return false;
      default:
        return false;
    }
    return false;
  }



  get url():string {
    return environment.backendURL+environment.taskPath + this.configService.appConfig.apiUrl + 'Task/';
  }

  createTasks(data): Promise<boolean>{
    return this.put<boolean>(`${this.url+'process'}`, data);
  }

  async getTask(id:number):Promise<Task>{
    return new Task (await this.get<Task>(`${this.url}${id}`));
  }

  async getTasksUser(userId:number):Promise<Task[]>{

    let tasks =  (await this.get<any[]>(`${this.url}userId/${userId}`)).map(json => new Task(json));

            //TODO removing this block when Service will filtering data

            tasks = tasks.filter(t => {
              return !t.rc.communalT04Status && !t.rc.communalT05Status ;
            });
            tasks = tasks.filter(t => {
              return t.rc.communalT01Status === true ||  t.rc.communalT01Status === null;
            });

    return tasks;
  }

  async getTaskRc(rcId:number):Promise<Task>{
    return new Task (await this.get<Task>(`${this.url}rcId/${rcId}`));
  }


  async updateTaskRc(data):Promise<boolean>{
    /*
    data = {
      taskId:taskId,
      taskKey:string
      rcId:rcId,
      value:boolean
      file:file,
      message:text
     }
     */
    return this.post<boolean>(`${this.url+'update/'}`, data);
  }


}
