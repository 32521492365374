import {Component, Input, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {FormBuilder} from "@angular/forms";
import {MobileWizardComponentStep} from "../mobile-wizard-step.component";
import {LayoutService} from "../../../core/services/layout.service";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";


@Component({
  selector: 'edit-support-technical-data-height',
  templateUrl: './edit-support-technical-data-height.component.html'
})
export class EditSupportTechnicalDataHeightComponent extends MobileWizardComponentStep implements OnInit
{
  @Input()
  support: Support;

  constructor(private fb: FormBuilder,
              layoutService: LayoutService,
              confirmationService: ConfirmationDialogService) {
    super(layoutService, confirmationService);
  }

  ngOnInit() {
    this.form = this.fb.group({
      note: this.fb.control(''),
      height: this.fb.control('')
    });
    this.registerListener();
  }
}
