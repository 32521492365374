import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {ConfigService} from './config.service';
import {Code} from '../../models/code.model';
import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';
import {Sign} from '../../models/sign.model';

@Injectable()
export class SignCategoryService extends BaseAbstractService
{
  get url():string {
    return environment.backendURL + environment.signPath+ this.configService.appConfig.apiUrl + 'Sign/SignTypeCategories';
  }

  findAll(): Promise<Code[]> {
    return this.get<Code[]>(`${this.url}`);
  }
}
