import {Component, Injector, OnInit} from '@angular/core';
import {SupportService} from "../../../core/services/support.service";
import {Support} from "../../../models/support.model";
import {Sign} from "../../../models/sign.model";
import {LayoutService} from "../../../core/services/layout.service";
import {ViewSupportComponent} from "../view-support/view-support.component";
import {SignService} from "../../../core/services/sign.service";
import {UpdatePanelComponent} from "../update-panel/update-panel.component";
import {ReorderPanelsComponent} from "../reorder-panels/reorder-panels.component";
import {ConfirmationService} from "primeng/api";
import {CreatePanelReviewComponent} from "../create-panel-review/create-panel-review.component";
import {AddSignToSupportComponent} from "../add-sign-to-support/add-sign-to-support.component";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {UserService} from "../../../core/services/user.service";
import {CanThrowErrorComponent} from "../can-throw-error.component";


@Component({
  selector: 'view-panels-of-support',
  templateUrl: './view-panels-of-support.component.html',
  styleUrls: ['./view-panels-of-support.component.scss']
})
export class ViewPanelsOfSupportComponent extends CanThrowErrorComponent implements OnInit
{

  selectedSupport: Support;
  loaded = false;
  displayDeleteSignPopup = false;
  _selectedSignForDelete: Sign;
  _selectedSign: Sign;

  compTitle = '';

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private supportService: SupportService,
              private injector: Injector,
              private userService: UserService,
              layoutService: LayoutService,
              confirmationService: ConfirmationDialogService,
              private signService: SignService,
              private confirmDialogService: ConfirmationDialogService) {
    super(layoutService, confirmationService);
  }

  async ngOnInit() {
    this.compTitle = await this.translate.get('SIGNS_OF_SUPPORT').toPromise();
    this.initComponent();
  }

  async initComponent() {
    try{
      this.layoutService.loading = true;
      this.selectedSupport = this.supportService.support;
      let signs: Sign[] = await this.signService.getSignsForSupportId(this.selectedSupport.id);
      this.selectedSupport.signs = signs;
      this.selectedSupport.signs.sort((a, b)=>{
        if(a.order < b.order){
          return 1;
        }else if(b.order < a.order){
          return -1;
        }else{
          return 0;
        }
      });
      this.loaded = true;
      this.layoutService.loading = false;
    }catch(e){
      this.confirmationDialogService.yesCallBack = this.errorCallBack.bind(this);
      this.layoutService.loading = false;
      this.onError();
    }
  }

  errorCallBack(){
    this.dismissErrorDialog();
    this.backToViewSupport();
  }

  updatePanel(sign:Sign) {
    if(this.canUpdate()){
      this.layoutService.loading = true;
      this.signService.sign = sign;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = UpdatePanelComponent;
      this.layoutService.loading = false;
    }
  }

  reorderPanels() {
    if(this.canUpdate()){
      this.layoutService.loading = true;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = ReorderPanelsComponent;
      this.layoutService.loading = false;
    }
  }

  backToViewSupport() {
    this.layoutService.loading = true;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = ViewSupportComponent;
    this.layoutService.loading = false;
  }

  showDeletePanel(sign: Sign) {
    if(this.canUpdate()){
      event.stopPropagation();
      event.preventDefault();
      this._selectedSignForDelete = sign;
      this.initAndShowConfirmDialog();
    }
  }

  showReaddPanel(sign: Sign){
    if(this.canUpdate()){
      event.stopPropagation();
      event.preventDefault();
      this._selectedSign = sign;
      this.initAndShowConfirmReaddSignDialog();
    }
  }

  hideConfirm() {
    this.layoutService.leftPanelVisible = false;
  }

  async confirmDelete() {
    try{
      this.layoutService.loading = true;
      this.hideConfirm();
      this._selectedSignForDelete.mobileStatus = this.signService.MOBILE_STATUS_DELETE;
      await this.signService.updateSign(this._selectedSignForDelete);
      this.signService.signs = await this.signService.getSignsForSupportId(this.selectedSupport.id);
      this.supportService.support.signs = this.signService.signs;
      this.initComponent();
      // this.layoutService.loading = false;
    }catch(e){
      this.confirmationDialogService.yesCallBack = this.errorCallBack.bind(this);
      this.layoutService.leftPanelVisible = true;
    }
  }

  async confirmReaddSign() {
    try{
      this.layoutService.loading = true;
      this.hideConfirm();
      this._selectedSign.mobileStatus = this.signService.MOBILE_STATUS_EDIT;
      await this.signService.updateSign(this._selectedSign);
      this.signService.signs = await this.signService.getSignsForSupportId(this.selectedSupport.id);
      this.supportService.support.signs = this.signService.signs;
      this.initComponent();
      // this.layoutService.loading = false;
    }catch(e){
      this.confirmationDialogService.yesCallBack = this.errorCallBack.bind(this);
      this.layoutService.leftPanelVisible = true;
    }
  }

  async initAndShowConfirmDialog(){
    let trad = await this.translate.get('DELETE_SIGN_CONFIRMATION').toPromise();
    this.confirmDialogService.content = trad;
    this.confirmDialogService.yesCallBack = this.confirmDelete.bind(this);
    this.confirmDialogService.noCallBack = this.hideConfirm.bind(this);
    this.layoutService.leftPanelContent = ConfirmationDialogComponent;
    this.layoutService.leftPanelVisible = true;
  }

  async initAndShowConfirmReaddSignDialog() {
    let trad = await this.translate.get('READD_SIGN_CONFIRMATION').toPromise();
    this.confirmDialogService.content = trad;
    this.confirmDialogService.yesCallBack = this.confirmReaddSign.bind(this);
    this.confirmDialogService.noCallBack = this.hideConfirm.bind(this);
    this.layoutService.leftPanelContent = ConfirmationDialogComponent;
    this.layoutService.leftPanelVisible = true;
  }

  showSignDetails(sign){
    this.signService.sign = sign;
    this.signService.readMode = true;
    this.layoutService.loading = true;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = CreatePanelReviewComponent;
    this.layoutService.loading = false;
  }

  getOpacity(sign: Sign){
    return sign.mobileStatus == this.signService.MOBILE_STATUS_DELETE ? 0.3 : 1;
  }

  addSign() {
    if(this.canUpdate()){
      this.layoutService.loading = true;
      this.layoutService.rightPanelVisible = true;
      this.signService.readMode = false;
      this.signService.addToSupportMode = 'FROM SUPPORT';
      this.layoutService.backComponent = ViewPanelsOfSupportComponent;
      this.layoutService.rightPanelContent = AddSignToSupportComponent;
      this.layoutService.loading = false;
    }
  }

  canUpdate() {
    return this.userService.canUpdateFromGeom(this.supportService.support.geom);
  }
}
