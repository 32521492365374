import {Injectable} from "@angular/core";

@Injectable()
export class ConfirmationDialogService
{
  private _yesCallBack: any;
  private _noCallBack: any;
  private _content: string;
  private _notificationMode: boolean;

  get yesCallBack(): any {
    return this._yesCallBack != null ? this._yesCallBack : function(){};
  }

  set yesCallBack(value: any) {
    this._yesCallBack = value;
  }

  get noCallBack(): any {
    return this._noCallBack != null ? this._noCallBack : function(){};;
  }

  set noCallBack(value: any) {
    this._noCallBack = value;
  }

  get content(): string {
    return this._content;
  }

  set content(value: string) {
    this._content = value;
  }

  get notificationMode(): boolean {
    return this._notificationMode;
  }

  set notificationMode(value: boolean) {
    this._notificationMode = value;
  }
}
