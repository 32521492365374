import {Component, Injector, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {LoggedComponent} from '../../shared/components/logged/logged.component';
import {Router} from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { LayoutService } from '../../core/services/layout.service';
import { NgViewerComponent} from '@nsi/gis-viewer-ui';
import { PanelSupportListComponent } from '../supports/panel-support-list/panel-support-list.component';
import { SupportService } from '../../core/services/support.service';
import {MapService} from '../../core/services/map.service';
import { MenuItem } from 'primeng/api';
import { environment } from 'environments/environment';
import { ContextMenu } from 'primeng/primeng';
import { PanelSignListComponent } from '../signs/panel-sign-list/panel-sign-list.component';
import {GeoserverService} from "../../core/services/geoserver.service";
import {Support} from '../../models/support.model';
import {SupportComponent} from '../supports/support/support.component';
import { TaskService } from '../../core/services/task.service';
import { Task } from '../../models/task.model';
import {TranslateService} from '@ngx-translate/core';
import * as ol from 'openlayers';
import { SignService } from '../../core/services/sign.service';
import {geom} from "openlayers";
import Polygon = geom.Polygon;
import {UpdateSupportComponent} from "../mobile/update-support/update-support.component";
import {ViewSupportComponent} from "../mobile/view-support/view-support.component";
import {async} from "rxjs/scheduler/async";
import {CreateSupportComponent} from "../mobile/create-support/create-support.component";
import {AddSignToSupportComponent} from "../mobile/add-sign-to-support/add-sign-to-support.component";
import {ViewPanelsOfSupportComponent} from "../mobile/view-panels-of-support/view-panels-of-support.component";
import {SupportManagementComponent} from "../mobile/support-management/support-management.component";
import {MunicipalityService} from "../../core/services/municipality.service";
import Utils from "../../shared/services/utils";
import {ConfirmationDialogService} from "../../core/services/confirmation-dialog.service";
import {ConfirmationDialogComponent} from "../mobile/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapComponent extends LoggedComponent implements OnInit
{
  translate: TranslateService = this.injector.get(TranslateService);

  _viewer:NgViewerComponent;
  _ngViewer: NgViewerComponent;
  @ViewChild('EsignViewer')
  set ngViewer(val: NgViewerComponent) {
    this._ngViewer = val;
    this.geoserverService.ngViewer = this.ngViewer;
    this._ngViewer.initializedHandler.subscribe(e => {
      if (this.geoserverService.ngViewer.initialized) {
        this.mapService.viewer = this.geoserverService.ngViewer.viewer;
        this.initViewer();
      }
    })
  };
  get ngViewer(): NgViewerComponent {
    return this._ngViewer;
  };

  displayInfoAddSign: boolean = false;
  displayAddSign: boolean = false;

  showTask: boolean = true;

  get viewerConfig():any{
    let conf = {
      'viewer':'./assets/viewer-config/viewer.json',
      'modules': './assets/viewer-config/modules.json',
      'layers':'./assets/viewer-config/'+environment.code+'/layers.json'};
    return conf;
  }

  constructor(private router: Router,
              public userService:UserService,
              public layoutService:LayoutService,
              public confirmationService: ConfirmationDialogService,
              protected supportService:SupportService,
              protected taskService:TaskService,
              protected signService:SignService,
              public mapService: MapService,
              public changeDetectorRef: ChangeDetectorRef,
              private geoserverService: GeoserverService,
              private injector: Injector,
              private municipalityService: MunicipalityService
  ) {
    super(userService);

    this.mapService.addSignModeActivated = false;
    this.layoutService.closeRightPanel();
    this.layoutService.closeLeftPanel();
  }
  selectedVectorSource: ol.source.Vector=new ol.source.Vector({wrapX: false});
  selectedVectorLayer: ol.layer.Vector=new ol.layer.Vector({
    source: this.selectedVectorSource,
    style:new ol.style.Style({
      image: new ol.style.Circle({
        radius: 5,
        fill: new ol.style.Fill({color: '#ffee00'}),
        stroke: new ol.style.Stroke({color: '#ffee00', width: 1})
      })
    })
  });
  highlightVectorSource: ol.source.Vector=new ol.source.Vector({wrapX: false});
  highlightVectorLayer: ol.layer.Vector=new ol.layer.Vector({
    source: this.highlightVectorSource,
    style:new ol.style.Style({
      image: new ol.style.Circle({
        radius: 10,
        fill: new ol.style.Fill({color: '#00ffff'}),
        stroke: new ol.style.Stroke({color: '#00ffff', width: 1})
      })
    })
  });

  userMunicipalitiesVectorSource: ol.source.Vector = new ol.source.Vector({ wrapX: false });
  userMunicipalitiesVectorLayer: ol.layer.Vector = new ol.layer.Vector({
    source: this.userMunicipalitiesVectorSource,
    style: new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: '#FFE510',
        width: 3
      }),
      fill: new ol.style.Fill({
        color: 'rgba(255, 229, 16,0.1)'
      })
    })
  });

  municipalitiesVectorSource: ol.source.Vector = new ol.source.Vector({ wrapX: false });
  municipalitiesVectorLayer: ol.layer.Vector = new ol.layer.Vector({
    source: this.municipalitiesVectorSource,
    style: new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: '#466794',
        width: 3
      }),
      fill: new ol.style.Fill({
        color: 'rgba(70, 103, 148, 0.1)'
      })
    })
  });

  highlightVectorZoneSource: ol.source.Vector=new ol.source.Vector({wrapX: false});
  highlightVectorZoneLayer: ol.layer.Vector=new ol.layer.Vector({
    source: this.highlightVectorZoneSource,
    style:new ol.style.Style({
      image: new ol.style.Circle({
        radius: 10,
        fill: new ol.style.Fill({color: '#00ffff'}),
        stroke: new ol.style.Stroke({color: '#00ffff', width: 1})
      })
    })
  });

  ngOnInit() {
    super.ngOnInit();
  }



  @ViewChild('contextMenu')
  set contextMenu(contextM:ContextMenu){
    this._contextMenu=contextM;
  }
  get contextMenu():ContextMenu{
    return this._contextMenu
  }

  _contextMenu:ContextMenu;

  private contextMenuItems:MenuItem[];

  async initViewer() {
    let map:ol.Map=this.mapService.olMap;
    map.addLayer(this.userMunicipalitiesVectorLayer);
    map.addLayer(this.municipalitiesVectorLayer);
    map.addLayer(this.selectedVectorLayer);
    map.addLayer(this.highlightVectorLayer);
    this.userMunicipalitiesVectorLayer.setZIndex(8);
    this.municipalitiesVectorLayer.setZIndex(7);
    let municipalities = await this.municipalityService.findAll();
    let userMunicipality = this.userService.user.municipalities[0];

    let features = municipalities.forEach( municipality => {
      let jsonGeom = JSON.parse(municipality.geom);
      let olGeom = new ol.geom.MultiPolygon(jsonGeom.coordinates);
      let olFeature = new ol.Feature(olGeom);
      if (!userMunicipality || (userMunicipality && userMunicipality.id == municipality.id)){
        this.userMunicipalitiesVectorSource.addFeature(olFeature);
      }else{
        this.municipalitiesVectorSource.addFeature(olFeature);
      }
      return olFeature;
    });

    this.mapService.mapClickedListener.subscribe(async (event)=>{
      this._contextMenu.hide();
    });
    this.mapService.olMap.on('movestart',async (event)=>{
      this._contextMenu.hide();
    });
    this.mapService.mapContextMenuListener.subscribe(async (event)=>{
      event.preventDefault();
      if(this.mapService.allowAddSupportFromButton) {
        this.contextMenuItems = [];
        this.supportPositionClicked= map.getEventCoordinate(event);
        let geometry = this.createBufferOnPoint(map.getEventCoordinate(event));
        let userCanAddSign = false;
        if(this.userService.ROLE_CREATE){
          this.supportService.support = null;
          let currentCoord = map.getEventCoordinate(event);
          let geom = '{"type":"Point","coordinates":['+currentCoord[0]+','+currentCoord[1]+']}';
          this.supportService.currentGeom = currentCoord;
          this.supportService.currentPoint = geom;
          if(this.userService.user.municipalities && this.userService.user.municipalities.length > 0){
            let olGeom = Utils.getOlGeomFromMunicipality(this.userService.geom);
            if(olGeom.intersectsCoordinate(this.supportPositionClicked)){
              userCanAddSign = true;
              await this.createAddSupportButton();
            }else{
              await this.createCantAddHereButton();
            }
          } else {
            let inBxl: boolean = false;
            let municipalities = await this.municipalityService.findAll();
            municipalities.forEach(async municipality =>{
              let olGeom = Utils.getOlGeomFromMunicipality(municipality.geom);
              if(olGeom.intersectsCoordinate(this.supportPositionClicked)){
                inBxl = true;
              }
            })

            if(inBxl){
              await this.createAddSupportButton();
            }else{
              await this.createCantAddHereButton();
            }
          }
          this.changeDetectorRef.detectChanges();
        }
        let ids = await this.identifyPolygon(geometry);
        if (ids.length>0){
          this.contextMenuItems = [];
          this.createShowSupportButton(ids[0]);
          if(userCanAddSign){
            this.createAddSignToSupportButton(ids[0]);
          }
        }
        this._contextMenu.show(<MouseEvent>event);
      }
    });
    this.mapService.language = this.translate.currentLang;
  }

  createBufferOnPoint(coordinates) {
    let radius= 2;
    let bufferedPoint = new ol.geom.Circle(coordinates, radius);
    return ol.geom.Polygon.fromCircle(bufferedPoint);
  }

  async createShowSupportButton(id) {
    this.contextMenuItems.push({
      label: await this.translate.get('SHOW_SUPPORT').toPromise(),
      command: (event) => {
        this._contextMenu.hide();
        this.showSupportOnRightPanel(id);
      },
      icon: 'icon fa-eye marginR5',
    });
  }

  async createCantAddHereButton(){
    this.contextMenuItems.push({
      label: await this.translate.get('CANT_ADD_SIGN_HERE').toPromise(),
    });
  }

  async createAddSupportButton() {
    this.contextMenuItems.push({
      label: await this.translate.get('ADD_SIGN').toPromise(),
      command: (event) =>  {
        this._contextMenu.hide();
        this.initAddSupportWizard();
      },
      icon: 'icon fa-plus-circle marginR5',
    });
  }

  async createAddSignToSupportButton(id: number) {
    this.contextMenuItems.push({
      label: await this.translate.get('ADD_TO_THIS_SUPPORT').toPromise(),
      command: (event) =>  {
        this._contextMenu.hide();
        this.addSignToSelectedSupport(id);
      },
      icon: 'icon fa-plus-circle marginR5',
    });
  }

  async initAddSupportWizard() {
    this.layoutService.loading = true;
    await this.getAdresseFromXY();
    this.layoutService.rightPanelVisible = true;
    this.supportService.createMode = true;
    this.layoutService.rightPanelContent = CreateSupportComponent;
    this.layoutService.loading = false;
  }

  async getAdresseFromXY() {
    let address = await this.supportService.getAddressFromXY(this.translate.currentLang, this.supportService.currentGeom[0], this.supportService.currentGeom[1]);
    if(!address.error && address.status === "success"){
      address.result.address.street.label = address.result.address.street.name + ' ( '+address.result.address.street.postCode+' )';
      if(this.supportService.support == null){
        this.supportService.support = new Support({});
      }
      this.supportService.support.address = JSON.stringify(address.result.address.street);
      this.supportService.support.geom = this.supportService.currentPoint;
      if(address.result.address.number){
        this.supportService.support.number = parseInt(address.result.address.number);
      }
    }
    return;
  }

  showSelectedSigns(ids:number[]){
    if (ids.length>0){
      this.supportService.selectedSupportsIds=ids;
    }
    this.layoutService.leftPanelContent = PanelSignListComponent;
    this.mapService.selectSignModeActivated = true;
    this.layoutService.leftPanelVisible = true;
  }

  showFilterPanel(){
    alert('Not implemented yet Filter Panel');
  }
  polygonVectorSource: ol.source.Vector=new ol.source.Vector({wrapX: false});
  polygonVectorLayer: ol.layer.Vector=new ol.layer.Vector({source: this.polygonVectorSource});
  polygonDrawInteraction: ol.interaction.Draw=new ol.interaction.Draw({
    source: this.polygonVectorSource,
    type: 'Polygon'
  });

  polygonActive:boolean=false;
  async identifyPolygon(geometry:ol.geom.Geometry):Promise<number[]>{
    console.info('indentify polygon',geometry);
    let projection = 'EPSG:31370';
    let ids:number[] = await this.geoserverService.identifyPolygon(<ol.geom.Polygon>geometry,"srpb_esign:v_identify_supports", projection);
    return ids;
  }

  async addSignToSelectedSupport(id:number|Support){
    this.layoutService.loading = true;
    try{
      if(id instanceof Support){
        this.layoutService.loading = false;
        this.supportService.support = id;
      }else{
        let support = await this.supportService.getSupport(id);
        this.layoutService.loading = false;
        this.supportService.support = new Support(support);
      }
      this._contextMenu.hide();
      this.layoutService.rightPanelVisible = true;
      this.signService.addToSupportMode = "FROM MAP";
      this.layoutService.backComponent = ViewPanelsOfSupportComponent;
      this.layoutService.rightPanelContent = AddSignToSupportComponent;
    }catch(error){
      //TODO handle error
      this._contextMenu.hide();
      this.layoutService.loading = false;
    }
  }

  async showSupportOnRightPanel(id:number|Support){
    this.layoutService.loading = true;
    try{
      if(id instanceof Support){
        this.layoutService.loading = false;
        this.supportService.support = id;
      }else{
        let support = await this.supportService.getSupport(id);
        this.layoutService.loading = false;
        this.supportService.support = new Support(support);
      }
      this._contextMenu.hide();
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = ViewSupportComponent;
    }catch(error){
      //TODO handle error
      this._contextMenu.hide();
      this.layoutService.loading = false;
    }
  }

  infoAddSign() {
    this.displayInfoAddSign = true;
  }

  supportPosition:any;
  supportPositionClicked:any;

  async handleAddSignAtCoordinates(){
    this.mapService.addSignModeActivated=true;

    let ids = await this.identifySupport(this.supportPositionClicked);

    if (ids.length>0){
      this.supportService.selectedSupportsIds=ids;
      this.displayAddSign=true;
    }else{
      this.newSupport();
    }
  }


  async identifySupport(coords:[number,number]):Promise<number[]>{
    let projection = 'EPSG:31370';
    let options = {
      'SRS':'EPSG:31370',
      'INFO_FORMAT': 'application/json',
      'FEATURE_COUNT':50
    };
    return await this.geoserverService.identifyItems(coords,"srpb_esign:v_identify_supports", projection, options);
  }



  addToExistingSupport(){
    this.displayAddSign = false;
    this.layoutService.leftPanelContent=PanelSupportListComponent;
    this.layoutService.leftPanelVisible=true;
  }
  selectSigns(){
    this.mapService.selectSignModeActivated = true;
    this.layoutService.leftPanelVisible=true;
    this.layoutService.leftPanelContent=PanelSignListComponent;
  }
  async newSupport(){
    this.displayAddSign = false;
    this.supportPosition=this.supportPositionClicked;
    let x = this.supportPosition[0];
    let y = this.supportPosition[1];
    let geom = '{"type":"Point","coordinates":['+x+','+y+']}';
    this.supportService.support = new Support({geom:geom});
    let address = await this.supportService.getAddressFromXY(this.translate.currentLang, this.supportPositionClicked[0], this.supportPositionClicked[1]);
    if(!address.error && address.status === "success"){
      address.result.address.street.label = address.result.address.street.name + ' ( '+address.result.address.street.postCode+' )';
      this.supportService.support.address = JSON.stringify(address.result.address.street);
      if(address.result.address.number){
        this.supportService.support.number = parseInt(address.result.address.number);
      }
    }
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = SupportComponent;
    this.supportService.editMode = true;
  }

  switchLeftPanelVisible(){
    this.layoutService.leftPanelVisible=!this.layoutService.leftPanelVisible;
  }

  async goToMyLocation() {
    try{
      let position = await this.mapService.mapManager.geocodingMgr.getGeolocation(this.mapService.mapManager.view.mapProjection);
      this.mapService.mapManager.zoomTo(position);
    }catch(e){
      this.onError(e.message);
    }
  }

  async addSupport() {
    this.supportService.support = new Support({});
    this.layoutService.rightPanelContent=SupportManagementComponent;
    this.layoutService.rightPanelVisible=true;
  }

  cancelSelectPositionFromMap() {
    this.mapService.allowAddSupportFromButton = true;
  }

  onError(e){
    this.confirmationService.yesCallBack = this.errorCallback.bind(this);
    this.confirmationService.content = 'Une erreur est survenue: '+e;
    this.confirmationService.notificationMode = true;
    this.layoutService.leftPanelContent = ConfirmationDialogComponent;
    this.layoutService.leftPanelVisible = true;
  }

  errorCallback() {
    this.layoutService.leftPanelVisible = false;
  }
}
