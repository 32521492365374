import {Component, OnInit} from '@angular/core';
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {LayoutService} from "../../../core/services/layout.service";


@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit
{
  constructor(public confirmationDialogService:ConfirmationDialogService,
              public layoutService: LayoutService){

  }

  ngOnInit(){

  }

  yesClicked(){
    this.confirmationDialogService.yesCallBack.apply();
  }

  noClicked(){
    this.confirmationDialogService.noCallBack.apply();
  }
}
