import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import {MenuItem} from "primeng/api";
import {LayoutService} from "../../../core/services/layout.service";
import {SupportManagementComponent} from "../../mobile/support-management/support-management.component";
import {TranslateService} from "@ngx-translate/core";
import {Menu} from "primeng/menu";
import {MapService} from "../../../core/services/map.service";

@Component({
  selector: 'mobile-top-menu',
  templateUrl: './mobile-top-menu.component.html',
  styleUrls: ['./mobile-top-menu.component.scss']
})
export class MobileTopMenuComponent implements OnInit
{
  items: MenuItem[];
  isConnected: boolean = false;

  translate: TranslateService = this.injector.get(TranslateService);

  @ViewChild('menu')
  menu: Menu;

  private _frButton = {label: '   FR => NL', icon: 'icon fa-flag', command: (event) => {
    this.changeLanguage('nl');
  }};

  private _nlButton = {label: '   NL => FR', icon: 'icon fa-flag', command: (event) => {
    this.changeLanguage('fr');
  }};

  constructor(public userService:UserService,
              public i18n:TranslateService,
              private mapService: MapService,
              private layoutService: LayoutService,
              private injector: Injector) {
  }

  ngOnInit() {
    this.isConnected = this.userService.user.id != null;
    this.initComponent();
  }

  async initComponent() {
    let logoutTrad = await this.translate.get("LOG_OUT").toPromise();
    this.items = [
      {label: '   Nederlands', icon: 'icon fa-flag', command: (event) => {
        this.changeLanguage('nl');
      }},
      {label: '   '+logoutTrad + ' ('+this.userService.user.name + ' ' + this.userService.user.surname+')', icon: 'icon fa-sign-out-alt', command: (event) => {
        this.disconnect();
      }}
    ];
    this.updateLanguagesButton();
  }
  updateLanguagesButton() {
    switch(this.i18n.currentLang) {
      case 'fr':
        this.items[0] = this._frButton;
        break;
      case 'nl':
        this.items[0] = this._nlButton;
        break;
    }
  }
  changeLanguage(language: string) {
    this.userService.changeUserLanguage(language.toUpperCase());
    this.mapService.language = language;
    this.i18n.use(language);
    this.updateLanguagesButton();
  }

  toggleMenu(event){
    this.initComponent();
    this.menu.toggle(event);
  }

  disconnect() {
    this.userService.logout();
  }
}
