import {Component, Injector, Input, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {Code, SelectCode} from "../../../models/code.model";
import {TranslateService} from "@ngx-translate/core";
import {SupportSpecificityService} from "../../../core/services/support-specificity.service";
import {SupportTypeService} from "../../../core/services/support-type.service";
import {FormBuilder} from "@angular/forms";
import {SupportGestionService} from "../../../core/services/support-gestion.service";
import {LayoutService} from "../../../core/services/layout.service";
import {MobileWizardComponentStep} from "../mobile-wizard-step.component";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";


@Component({
  selector: 'edit-support-technical-data',
  templateUrl: './edit-support-technical-data.component.html'
})
export class EditSupportTechnicalDataComponent extends MobileWizardComponentStep implements OnInit
{
  @Input()
  support: Support;

  translate: TranslateService = this.injector.get(TranslateService);

  supportSpecificityOptions: SelectCode[] = new Array();
  supportTypeOptions: SelectCode[] = new Array();
  supportGestionOptions: SelectCode[] = new Array();

  constructor(private injector: Injector,
              private supportSpecificityService: SupportSpecificityService,
              private supportTypeService: SupportTypeService,
              private supportGestionService: SupportGestionService,
              private fb: FormBuilder,
              layoutService: LayoutService,
              confirmationService: ConfirmationDialogService) {
    super(layoutService, confirmationService);
  }

  async ngOnInit() {
    this.layoutService.loading = true;
    this.form = this.fb.group({
      type: this.fb.control(''),
      specificity: this.fb.control(''),
      gestion: this.fb.control('')
    });
    this.registerListener();
    await Promise.all([
      this.loadCode(this.supportSpecificityService,this.supportSpecificityOptions, null),
      this.loadCode(this.supportTypeService,this.supportTypeOptions, null),
      this.loadCode(this.supportGestionService,this.supportGestionOptions, null)
    ])
    this.support.specificity = this.support.specificity != null ? this.support.specificity: this.supportSpecificityService.default;
    this.support.type = this.support.type != null ?this.support.type : this.supportTypeService.default;
    this.support.gestion = this.support.gestion != null ? this.support.gestion : this.supportGestionService.default;
    this.loaded = true;
    this.layoutService.loading = false;
  }

  async loadCode(service, options: SelectCode[], empty:string):Promise<undefined>{
    let values:Code[] = await service.findAll();
    values = empty?[new Code(null,empty)].concat(values):values;
    let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
    translatedValues.sort((a, b) => {
      if(a.label>b.label){
        return 1;
      }else if(a.label<b.label){
        return -1;
      }else{
        return 0;
      }
    });
    translatedValues.forEach(val => options.push(val));
    return;
  }

}
