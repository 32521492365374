import {Component, Input, OnInit} from '@angular/core';
import {Picture} from '../../../models/picture.model';
import {SignService} from '../../../core/services/sign.service';

@Component({
  selector: 'picture-view',
  templateUrl: './picture-view.component.html',
  styleUrls: ['./picture-view.component.scss']
})
export class PictureViewComponent implements OnInit
{
  @Input() imgs: Picture[];
  @Input() pictureService;
  @Input() sourceId: number;
  @Input() canEdit: boolean = false;

  displayPicture:boolean = false;
  img:Picture = new Picture({});

  constructor(private signService: SignService) {}
  ngOnInit() {
    this.pictureService.imgs = [];
  }

  async uploadhandler($event){
    this.pictureService.imgs = [];
    try{
      $event.files.forEach(pic=>{
        let file = pic;

        let reader = new FileReader();
        reader.readAsDataURL(file);
        let ctxt = this;
        reader.onloadend = ()=>{
          let base64data = reader.result;
          this.pictureService.imgs.push(new Picture({file:base64data, name:file.name}));
        };
      });
    }catch(error){
      console.info("//TODO error handler", error);
    }
  }

  async getPictureAsync(id:number){
    let url = "";
    try{
      let img = await this.pictureService.getPicture(id);
      url = img.pictureLink;
    }
    catch(error){

    }
    return url;
  }


  async showPicture(img:Picture){
    this.img = img;
    this.displayPicture = true;
  }

  async deletePicture(img:Picture){
    if(img.gedId){
      try{
        let index:number = this.imgs.map(img=>img.gedId).indexOf(img.gedId);
        if(index>=0){
          this.signService.deletedImgs.push(new Picture({gedId: img.gedId, objectId:img.objectId}));
          this.imgs.splice(index,1);
        }

      }catch(error){

      }
    }
  }

}
