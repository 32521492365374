import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Code, SignCategory, SignType} from '../../models/code.model';
import {BaseAbstractService} from './base-abstract.service';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {Session} from "../../app.session";
import {TranslationService} from "./translation.service";

@Injectable()
export class SignTypeService extends BaseAbstractService
{
  _codes:SignType[];
  _filteredCodes:SignType[];

  _default:string = null;
  _defaultFiltered:string = null;

  constructor(@Inject(TranslateService) private translate,
              _router: Router,
              _http: HttpClient,
              _session: Session,
              private translationService: TranslationService,
              configService: ConfigService) {
    super(_router, _http, _session, configService);
  }

  get url():string {
    return environment.backendURL + environment.signPath+ this.configService.appConfig.apiUrl + 'Sign/SignTypes';
  }

  async findAll(): Promise<Code[]> {
    this._codes= this._codes ? this._codes: (await this.get<Code[]>(`${this.url}`)).map(json => new SignType(json.id, json.code, json.typeCategorie));
    return this._codes;
  }

  async filter(signCatIds:number[]): Promise<SignType[]>{
    this._filteredCodes = (await this.post<SignType[]>(`${this.url}/filter`, signCatIds)).map(json => new SignType(json.id, json.code, json.typeCategorie));
    return this._filteredCodes;
  }

  get default():SignType{
    return this._codes.find((code)=>{
      return this._default==code.code?true:false;
    })||this._codes[0];
  }

  get defaultFiltered():SignType{
    return this._filteredCodes.find((code)=>{
      return this._defaultFiltered==code.code?true:false;
    })||this._filteredCodes[0];
  }

}
