import {Support} from './support.model';

export class SupportUpdate extends Support  {
  public signOrders?: {id, order}[];

  constructor(
    obj:Support
  ) {
    super(obj);
  }
}
