import {Component, OnInit} from '@angular/core';
import {Support} from '../../../models/support.model';
import {SupportService} from '../../../core/services/support.service';
import {Router} from '@angular/router';
import {routerLink} from '../../../../assets/config/routerLink';
import {LayoutComponent} from '../../../features/layout/layout.component';
import {MapService} from '../../../core/services/map.service';
import {SupportComponent} from '../../../features/supports/support/support.component';
import { LayoutService } from '../../../core/services/layout.service';
import {Sign} from '../../../models/sign.model';
import {SignService} from '../../../core/services/sign.service';
import {SignComponent} from '../../signs/sign/sign.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'panel-support-list',
  templateUrl: './panel-support-list.component.html',
  styleUrls: ['./panel-support-list.component.scss'],
})
export class PanelSupportListComponent
{
  multiSelect: boolean = false;

  supports: Support[] = new Array();

  supportsSelected: Support[] = new Array();
  supportSelectedBool: boolean = false;
  supportChanges:Subscription;

  constructor(private supportService: SupportService,
              private signService: SignService,
              private router: Router,
              protected layoutService: LayoutService,
              private mapService: MapService) {

  }
  ngOnInit() {
    let ids:number[] = this.supportService.selectedSupportsIds;
    ids && this.addSupportsId(ids);
    this.supportChanges = this.supportService.selectedSupportsIdsHandler.subscribe((ids:number[])=>{
      ids && this.addSupportsId(ids);
    });

  }
  ngOnDestroy() {
    this.supportChanges.unsubscribe();
  }

  async addSupportId(id: number){
    if (!this.supports.find(s => s.id === id)) {
      try{
        let support = await this.supportService.getSupport(id);
        this.supports.push(support);
      }catch(error){
        //TODO error handling
      }
    }
  }

  addSupportsId(ids: number[]){
    for(let id of ids){
      this.addSupportId(id);
    }
  }

  addSupport(support: Support){
    if (!this.supports.find(s => s.id === support.id)) {
      this.supports.push(support);
    }
  }

  addSupports(supports: Support[]){
    for(let support of supports){
      this.addSupport(support);
    }
  }

  removeSupportId(id: number){
    let index = this.supports.indexOf(this.supports.find(s => s.id === id));
    if (index != -1){
      this.supports.splice(index, 1);
    }
  }

  removeSupport(support: Support){
    let index = this.supports.indexOf(this.supports.find(s => s.id === support.id));
    if (index != -1){
      this.unselectSupport(support);
      this.supports.splice(index, 1);
    }
  }

  isSelected(support: Support): boolean{
    let index = this.supportsSelected.indexOf(this.supportsSelected.find(s => s.id === support.id));
    if (index != -1){
      return true;
    }
    return false;
  }

  onclick(support: Support){
    console.info(support);
    if(this.isSelected(support)){
      this.unselectSupport(support);
    }else{
      this.selectSupport(support);
    }
  }

  selectSupport(support: Support){
    if(this.multiSelect){
      if (!this.supportsSelected.find(s => s.id === support.id)) {
        this.supportsSelected.push(support);
      }
    }else{
      this.supportsSelected = new Array(support);
      this.showSupportComponent();
    }
    this.supportSelectedBool = true;
  }

  unselectSupport(support: Support){
    if(this.multiSelect){
      let index = this.supportsSelected.indexOf(this.supportsSelected.find(s => s.id === support.id));
      if (index != -1){
        this.supportsSelected.splice(index, 1);
      }
      if(this.supportsSelected.length>0)
        this.supportSelectedBool = false;
    }else{
      this.supportsSelected = new Array();
      this.supportSelectedBool = false;
      this.hideSupportComponent();
    }
  }

  get numberOfElements():number{
    return this.supports.length;
  }

  addSignToSupport(){
    if(!this.multiSelect && this.supportsSelected.length>0) {

      this.signService.sign = new Sign({});
      this.signService.sign.supportId = this.supportsSelected[0].id
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = SignComponent;
      this.signService.editMode = true;
    }
    this.cancelSelect();
  }

  showSupport(){
    if(!this.multiSelect && this.supportsSelected.length>0) {
      this.supportService.support = new Support(this.supportsSelected[0]);
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = SupportComponent;
    }
    this.cancelSelect();
  }

  cancelSelect(){
    this.mapService.addSignModeActivated = false;
    this.resetSelect();
    this.supportService._selectedSupportsIds=new Array();
    this.supports = new Array();
    this.layoutService.leftPanelVisible=false;
  }

  resetSelect(){
    this.supportsSelected = new Array();
    this.supportSelectedBool = false;
  }

  mouseEnter(support){
    console.info('in', support.geom);
  }
  mouseLeave(support){
    console.info('out', support.geom);
  }

  showSupportComponent(){
    console.info('test')
    this.layoutService.rightPanelVisible=true;
    this.supportService.support = this.supportsSelected[0];
    this.layoutService.rightPanelContent = SupportComponent;
  }
  hideSupportComponent(){
    this.layoutService.rightPanelVisible=false;
  }

  close(){
    this.layoutService.leftPanelVisible=false;
  }

}
