import {NgModule} from '@angular/core';

import {SharedModule} from 'app/shared/shared.module';
import { TaskModule } from '../tasks/task.module';
import { LanguageIconComponent } from './language/layout.language-icon.component';
import { ProfilIconComponent } from './profile/layout.profil-icon.component';
import { LogoutIconComponent } from './logout/layout.logout-icon.component';
import { LayoutActionIconsComponent } from './layout.action-icons.component';
import { UserService } from '../../core/services/user.service';
import { AdministrationIconComponent } from './administration/layout.administration-icon.component';
/* yang-add-component-import - Yang will add components imports here */

const MODULES = [
    SharedModule
  ];

  const DECLARATIONS = [
    LanguageIconComponent,
    LogoutIconComponent,
    ProfilIconComponent,
    AdministrationIconComponent,
    LayoutActionIconsComponent

  ];

@NgModule({
    declarations: DECLARATIONS,
    imports: MODULES,
    exports: [LayoutActionIconsComponent]
})
export class LayoutActionIconsModule {}
