import {Component, Injector, Input, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {TranslateService} from "@ngx-translate/core";
import {Sign} from "../../../models/sign.model";
import { Ng2ImgMaxService } from 'ng2-img-max';
import {LayoutService} from "../../../core/services/layout.service";
import {Picture} from "../../../models/picture.model";
import {MobileWizardComponentStep} from "../mobile-wizard-step.component";
import {PictureService, PictureServiceSign} from "../../../core/services/picture.service";
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";


@Component({
  selector: 'create-panel-take-picture',
  templateUrl: './create-panel-take-picture.component.html'
})
export class CreatePanelTakePictureComponent extends MobileWizardComponentStep implements OnInit
{
  @Input()
  support: Support;

  @Input()
  sign: Sign;

  imgUploaded = false;

  base64data: any;

  buttonLabel = "";

  galleryImages: NgxGalleryImage[] = [];

  galleryOptions: NgxGalleryOptions[];

  imgs: Picture[];

  imagesLoaded = false;

  translate: TranslateService = this.injector.get(TranslateService);

  selectedFile: any;

  constructor(private injector: Injector,
              private ng2ImgMaxService: Ng2ImgMaxService,
              layoutService: LayoutService,
              confirmationService: ConfirmationDialogService,
              private pictureService: PictureServiceSign) {
    super(layoutService, confirmationService);
  }
  async ngOnInit() {
    this.buttonLabel = await this.translate.get("TAKE_PICTURE").toPromise();
    if(this.sign == null){
      this.sign = new Sign({});
    }
    if(this.mode == "EDIT"){
      try{
        this.imgs = await this.pictureService.getPictureFull(this.sign.id);
      }catch(error){
        this.confirmationDialogService.yesCallBack = this.backOnMapCallback.bind(this);
        this.onError();
      }
      this.galleryOptions = [
          { "imageSize": "contain", "width": "100%", "height": "200px", "thumbnailsColumns": 3, "previewCloseOnClick": true }
      ];
      if(this.imgs && this.imgs.length){
        this.imgs.forEach(image =>{
          this.galleryImages.push({
            small: image.file,
            medium: image.file,
            big: image.file
          })
        })
      }
      this.sign.imgs = this.imgs;
      this.imagesLoaded = true;
    }else{
      this.imgs = [];
    }
  }
  async onFileSelected(event)
  {
    this.buttonLabel = await this.translate.get("TAKE_ANOTHER_PICTURE").toPromise();
    this.layoutService.loading = true;
    if(event.target.files && event.target.files[0]){
      this.selectedFile = event.target.files[0];
      this.ng2ImgMaxService.resize([this.selectedFile], 500,500).subscribe(res =>{
        this.selectedFile = res;
        let reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onloadend = ()=>{
          this.base64data = reader.result;
          var imagePicture = new Picture({file: this.base64data, name:'New panel picture'});
          this.sign.imgs = JSON.parse(JSON.stringify(this.imgs));
          this.sign.imgs.push(imagePicture);
          this.imgUploaded = true;
          this.layoutService.loading = false;
        };
      })
    }else{
      this.layoutService.loading = false;
    }
  }
}
