import {Component, OnInit} from '@angular/core';
import {LoggedComponent} from '../../shared/components/logged/logged.component';

@Component({
   selector: 'supports',
   templateUrl: './supports.component.html',
   styleUrls: ['./supports.component.scss']
})
export class SupportsComponent extends LoggedComponent{

}
