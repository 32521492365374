import {Message} from 'primeng/components/common/api';
import * as ol from 'openlayers';

export default class Utils {

  static makeUniqueArray(sources: any[]):any[] {
    let results:any[] = [];

    sources.forEach(source => {
      console.log(source);
      if(results.findIndex(result=>{
        console.log(result);
        if(typeof result.isEqual === "function"){
          return result.isEqual(source);
        }else{
          return result === source;
        }
      }) == -1){
      results.push(source);
    }
    });

    return results;
  }


  static daysBetween ( dateStart:Date, dateEnd:Date ):number {   //Get 1 day in milliseconds
    let one_day=1000*60*60*24;
    // Convert both dates to milliseconds
    let date1_ms = dateStart.getTime();
    let date2_ms = dateEnd.getTime();
    // Calculate the difference in milliseconds
    let difference_ms = date2_ms - date1_ms;
    // Convert back to days and return
    return Math.round(difference_ms/one_day);
  }

  static showError( errorMessages: Message[] , error){
    if(typeof(error.error) == typeof("string")){
      errorMessages.push({severity:'error', summary:'Error', detail:error.error});
    }else{
      errorMessages.push({severity:'error', summary:'Error', detail:error.message});
    }
  }

  static getOlGeomFromMunicipality(municipality){
    let geom = JSON.parse(municipality);
    return new ol.geom.MultiPolygon(geom.coordinates);
  }

}
