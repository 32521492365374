import {EventEmitter, Input, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {CanThrowErrorComponent} from "./can-throw-error.component";
import {LayoutService} from "../../core/services/layout.service";
import {ConfirmationService} from "primeng/api";
import {ConfirmationDialogService} from "../../core/services/confirmation-dialog.service";

export class MobileWizardComponentStep extends CanThrowErrorComponent{

  @Input() mode: string;

  @Output()
  validityChanged = new EventEmitter<{}>();

  form: FormGroup;


  loaded = false;

  constructor(layoutService: LayoutService, confirmationService: ConfirmationDialogService){
    super(layoutService, confirmationService);
  }

  registerListener() {
    this.form.statusChanges.subscribe(
      result => {
        if(result == 'VALID'){
          this.validityChanged.emit({validity: true})
        }else{
          this.validityChanged.emit({validity: false})
        }
      }
    );
  }
}
