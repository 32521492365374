import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Support} from "../../../models/support.model";
import {SupportService} from "../../../core/services/support.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AutoComplete} from "primeng/primeng";
import {MobileWizardComponentStep} from "../mobile-wizard-step.component";
import {LayoutService} from "../../../core/services/layout.service";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";


@Component({
  selector: 'edit-support-address',
  templateUrl: './edit-support-address.component.html'
})
export class EditSupportAddressComponent extends MobileWizardComponentStep implements OnInit
{
  @Input()
  support: Support;

  newData:any;
  results:string[]=[];
  @ViewChild('autoComplete')
  autoComplete:AutoComplete;

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private supportService: SupportService,
              private injector: Injector,
              layoutService: LayoutService,
              confirmationService: ConfirmationDialogService,
              private fb: FormBuilder) {
    super(layoutService, confirmationService);
  }

  ngOnInit() {
    this.form = this.fb.group({
      street: this.fb.control(''),
      number: this.fb.control('')
    });
    this.registerListener();
  }

  set editAddress(street:any){
    this.support.address=JSON.stringify(street);
  }
  get editAddress():any{
    let address:any;
    try{
      address=JSON.parse(this.support.address);
    }catch(e){

    }
    return address;
  }

  public async search(event: any) {
    try{
      let res = await this.supportService.getStreets(this.translate.currentLang,event.query);
      let data:any[]=res.result.map(resVal=>{
        let street = resVal.address.street;
        street.label = street.name + ' ( '+street.postCode+' )';
        return street;
      });

      if(data && data.length == 0) this.newData = event.query;
      else this.newData = null;

      this.results = data;
    }catch(error){
      this.confirmationDialogService.yesCallBack = this.backOnMapCallback.bind(this);
      this.onError();
    }
  }

}
