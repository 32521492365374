import {NgModule, OnInit} from '@angular/core';
import {Routes} from '@angular/router';

import {LayoutModule} from './layout/layout.module';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { SupportComponent } from './supports/support/support.component';
import { SignComponent } from './signs/sign/sign.component';
import { SignsComponent } from './signs/signs.component';
import { MapModule } from './map/map.module';
import { SupportsModule } from './supports/supports.module';
import { SignsModule } from './signs/signs.module';
import { LoginModule } from './login/login.module';
import { LayoutComponent } from './layout/layout.component';
import { SupportsComponent } from './supports/supports.component';
import { SupportsRoutes } from './supports/supports-routing.module';
import { SignsRoutes } from './signs/signs-routing.module';
import { TaskModule } from './tasks/task.module';
import {MobileModule} from "./mobile/mobile.module";

const MODULES: any[] = [
  LayoutModule,
  MapModule,
  SupportsModule,
  SignsModule,
  LoginModule,
  TaskModule,
  MobileModule
];

export const FEATURES_ROUTES: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'map', component: MapComponent },
  {
    path: 'supports',
    component: SupportsComponent,
    children: [
      ...SupportsRoutes
    ]
  },

  {
    path: 'signs',
    component: SignsComponent,
    children: [
      ...SignsRoutes
    ]
  },
];

export const FEATURES_COMPONENTS: any[] = [
  LayoutComponent
];


@NgModule({
  imports: MODULES,
  exports: MODULES
})
export class FeaturesModule {}
