import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {LoginRoutingModule} from './login-routing.module';
import {LoginComponent} from "./login.component";

const MODULES = [
    SharedModule,
    LoginRoutingModule
];

const DECLARATIONS = [
   LoginComponent
];


@NgModule({
    imports: MODULES,
    declarations: DECLARATIONS
})
export class LoginModule {}
