import {Injectable} from '@angular/core';
import {Support} from '../../models/support.model';
import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';
import {Observable} from 'rxjs/Observable';
import { Subject } from 'rxjs';
import {SupportUpdate} from '../../models/support-update.model';
import {Sign} from '../../models/sign.model';

@Injectable()
export class SupportService extends BaseAbstractService
{
  _selectedSupportsIds:number[];
  private selectedSupportsIdsSource = new Subject<number[]>();
  selectedSupportsIdsHandler:Observable<number[]> = <Observable<number[]>>this.selectedSupportsIdsSource.asObservable();

  set selectedSupportsIds(ids:number[]){
    this._selectedSupportsIds = Array.from(new Set(ids.map(id => id)));
    this.selectedSupportsIdsSource.next(ids);
  }
  get selectedSupportsIds():number[]{
    return this._selectedSupportsIds;
  }

  _support: Support;
  private supportSource = new Subject<Support>();
  supportHandler:Observable<Support> = <Observable<Support>>this.supportSource.asObservable();

  set support(support:Support){
    this._support = support;
    this.supportSource.next(support);
  }
  get support():Support{
    return this._support;
  }

  _editMode: boolean;
  set editMode(val:boolean){
    this._editMode = val;
  }
  get editMode():boolean{
    return this._editMode;
  }

  _createMode: boolean;
  set createMode(val:boolean){
    this._createMode = val;
  }
  get createMode():boolean{
    return this._createMode;
  }

  _currentPoint: string;
  set currentPoint(val:string){
    this._currentPoint = val;
  }
  get currentPoint():string{
    return this._currentPoint;
  }


  _currentGeom: any[];
  set currentGeom(val:any[]){
    this._currentGeom = val;
  }
  get currentGeom():any[]{
    return this._currentGeom;
  }

  get url():string {
    return environment.backendURL + environment.supportPath+ this.configService.appConfig.apiUrl + 'Support/';
  }

  async getSupport(id: number): Promise<Support> {
    let jsonSupport:Support = await await this.get<any>(`${this.url}${id}`);
    return new Support(jsonSupport);
  }

  async getSupportFull(id: number): Promise<Support> {
    let jsonSupport:Support = await this.get<any>(`${this.url}full/${id}`);
    return new Support(jsonSupport);
  }

  async getSupports(ids: number[]): Promise<Support[]>{
    return (await this.post<any>(`${this.url}/ids`,ids)).map(json => new Support(json));
  }

  async getSupportsFull(ids: number[]): Promise<Support[]>{
    return (await this.post<any>(`${this.url}full/ids`,ids)).map(json => new Support(json));
  }

  async getSupportForSignId(signId: number): Promise<Support>{
    let jsonSupport:Support = await this.get<any>(`${this.url}signId/${signId}`);
    return new Support(jsonSupport);
  }

  async getSupportFullForSignId(signId: number): Promise<Support>{
    let jsonSupport:Support = await this.get<any>(`${this.url}full/signId/${signId}`);
    return new Support(jsonSupport);
  }

  async getSupportsForSignIds(signIds: number[]): Promise<Support[]>{
    return (await this.post<any[]>(`${this.url}signIds`,signIds)).map(json => new Support(json));
  }

  async getSupportsFullForSignIds(signIds: number[]): Promise<Support[]>{
    return (await this.post<any[]>(`${this.url}full/signIds`,signIds)).map(json => new Support(json));
  }

  createOrUpdate(supportUpdate: SupportUpdate): Promise<Support>{
    if(supportUpdate.id){
      return this.updateSupportAndSign(supportUpdate);
    }else{
      return this.createSupport(supportUpdate);
    }

  };

  async createSupport(support: Support): Promise<Support> {
    let jsonSupport:Support = await this.post<any>(this.url, support);
    return new Support(jsonSupport);
  }

  async updateSupport(support: Support): Promise<Support> {
    let jsonSupport:Support = await this.put<any>(this.url, support);
    return new Support(jsonSupport);
  }

  async updateSupportAndSign(supportUpdate: SupportUpdate): Promise<Support> {
    let jsonSupport:Support = await this.put<any>(`${this.url}supportandsignsorder`, supportUpdate);
    return new Support(jsonSupport);
  }

  async findAllSupports(): Promise<Support[]> {
    return (await this.get<any[]>(`${this.url}all`)).map(json => new Support(json));
  }

  async findAllSupportsFull(): Promise<Support[]> {
    return (await this.get<any[]>(`${this.url}full/all`)).map(json => new Support(json));
  }

  getStreets(lang:string,address:string):Promise<any>{
    return new Promise<any>((resolve,reject)=>this._http.get<any>('//geoservices.irisnet.be/localization/Rest/Localize/getaddresses?spatialReference=31370&language='+lang+'&address='+address+'&shortModeActive=false').subscribe(val=>resolve(val),reason=>reject(reason)));
  }
  getAddressFromXY(lang:string,x,y):Promise<any>{
    return new Promise<any>((resolve,reject)=>this._http.get<any>('//geoservices.irisnet.be/localization/Rest/Localize/getaddressfromxy?json={%22language%22:%22'+lang+'%22,%22point%22:{%22x%22:%22'+x+'%22,%22y%22:%22'+y+'%22},%22SRS_In%22:%2231370%22}').subscribe(val=>resolve(val),reason=>reject(reason)));
  }


  async deleteSupport(id: number): Promise<Support> {
    let jsonSupport:Support = await this.delete<any>(`${this.url}/${id}`);
    return new Support(jsonSupport);
  }

  async createWithSigns(support: Support, signs: Sign[]):Promise<Support>{
    support.signs = signs;
    let jsonSupport:Support = await this.post<any>(`${this.url}createWithSigns`, support);
    return new Support(jsonSupport);
  }


}
