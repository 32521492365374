import {Component, Injector, Input, OnInit} from '@angular/core';
import {Sign} from '../../../models/sign.model';
import {LoggedComponent} from '../../../shared/components/logged/logged.component';
import {UserService} from '../../../core/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SignService} from "../../../core/services/sign.service";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Code, SelectCode, SignType} from '../../../models/code.model';
import {Message} from 'primeng/components/common/api';
import {SignFormatService} from '../../../core/services/sign-format.service';
import {SignPlacementService} from '../../../core/services/sign-placement.service';
import {SignTypeService} from '../../../core/services/sign-type.service';
import {SignGestionService} from '../../../core/services/sign-gestion.service';
import {SignSpecificityService} from '../../../core/services/sign-specificity.service';
import {ConfirmationService} from 'primeng/api';
import {Location} from '@angular/common';
import {LayoutService} from '../../../core/services/layout.service';
import {SupportComponent} from '../../supports/support/support.component';
import {SupportService} from '../../../core/services/support.service';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Rc} from '../../../models/rc.model';
import {RcService} from '../../../core/services/rc.service';
import {
  PictureServiceSignPlacement, PictureServiceSignRemoval, PictureServiceSign
} from '../../../core/services/picture.service';
import {Support} from '../../../models/support.model';
import Utils from '../../../shared/services/utils';
import { ChangeDetectorRef } from '@angular/core';
import {SignOrderService} from '../../../core/services/sign-order.service';



@Component({
   selector: 'sign',
   templateUrl: './sign.component.html',
   styleUrls: ['./sign.component.scss'],
   providers: [ConfirmationService]
})
export class SignComponent extends LoggedComponent implements OnInit
{
  translate: TranslateService = this.injector.get(TranslateService);

  _editSign: Sign = new Sign({});

  signForm: FormGroup;

  errorMessages: Message[] = [];

  tmp:boolean;


  rcsPending: Rc[];
  rcsOk: Rc[];
  rcsRemoved: Rc[];


  /*------SELECT OPTIONS------*/
  signGestionOptions: SelectCode[] = new Array();
  signPlacementOptions: SelectCode[] = new Array();
  signSpecificityOptions: SelectCode[] = new Array();
  signFormatOptions: SelectCode[] = new Array();
  signTypeOptions: SelectCode[] = new Array();


  constructor(private cdRef:ChangeDetectorRef,
              public userService:UserService,
              public signService:SignService,
              private signOrderService:SignOrderService,
              private signTypeService: SignTypeService,
              private signGestionService: SignGestionService,
              private signSpecificityService : SignSpecificityService,
              private signFormatService: SignFormatService,
              private signPlacementService: SignPlacementService,
              private supportService: SupportService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private confirmationService: ConfirmationService,
              private router: Router,
              private location: Location,
              private layoutService: LayoutService,
              private injector: Injector,
              private rcService: RcService,
              private pictureServiceSign: PictureServiceSign,
              private pictureServiceSignPlacement: PictureServiceSignPlacement,
              private pictureServiceSignRemoval: PictureServiceSignRemoval,
              ) {

    super(userService);
  }
  signChanges:Subscription;
  async ngOnInit() {

    this.initComponent();

    this.rcsPending = [];
    this.rcsOk = [];
    this.rcsRemoved = [];

    this.signForm = this.fb.group({
      gestion: this.fb.control(''),
      placement: this.fb.control(''),
      requestType: this.fb.control('[mobile]'),
      type: this.fb.control(''),
      genericBool: this.fb.control(''),
      genericText: this.fb.control(''),
      genericCom: this.fb.control(''),
      format: this.fb.control(''),
      specificity: this.fb.control(''),
      note: this.fb.control(''),
      other: this.fb.control(''),
      placementDate: this.fb.control(''),
      removalDate: this.fb.control(''),
      placementUser: this.fb.control(''),
      removalUser: this.fb.control(''),
      placementNote: this.fb.control(''),
      removalNote: this.fb.control('')
    });

    this.signChanges = this.signService.signHandler.subscribe((sign:Sign)=>{
      this.editSign = new Sign(this.signService.sign);
    });

  }


  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.signChanges.unsubscribe();
  }
  get mode():string{
    return this.signService.sign && this.signService.sign.id?'EDIT':'NEW';
  }
  isNew():boolean{
    return this.mode=="NEW";
  }
  get id():number{
    return this.signService.sign.id;
  }
  get supportId():number{
    return this.signService.sign.supportId;
  }
  get editSign():Sign{
    return this._editSign;
  }
  set editSign(sign:Sign){
    this._editSign = new Sign(sign);
    if(this.isNew()){

      this.editSign.supportId = this.supportId;
      if(!this.editSign.gestion){
        this.editSign.gestion = this.signGestionService.default;
      }
      if(!this.editSign.placement){
        this.editSign.placement = this.signPlacementService.default;
      }
      if(!this.editSign.specificity){
        this.editSign.specificity = this.signSpecificityService.default;
      }
      if(!this.editSign.format){
        this.editSign.format = this.signFormatService.default;
      }
      if(!this.editSign.type){
        this.editSign.type = this.signTypeService.default;
      }
    }
  }
  async initComponent(){
    this.signService.deletedImgs = [];
    this.layoutService.loading = true;
    try{
      await this.loadLists();
      if(!this.signService.sign){
        this.signService.sign = new Sign({});
      }
      this.editSign = new Sign(this.signService.sign);

      if(this.signService.sign.id) {
        let rcs = await this.rcService.getRCForSignId(this.signService.sign.id);
        this.rcsPending = rcs.filter(rc => rc.status == "RC_PENDING");
        this.rcsOk = rcs.filter(rc => rc.status == "RC_ACTIVE");
        this.rcsRemoved = rcs.filter(rc => rc.status == "RC_OLD");
      }

      if(this.signService.sign.id){
        this.signService.sign.imgs = await this.pictureServiceSign.getPictureFull(this.signService.sign.id);
        this.editSign.imgs = Array.from(this.signService.sign.imgs);
        this.signService.sign.placementImgs = await this.pictureServiceSignPlacement.getPictureFull(this.signService.sign.id);
        this.editSign.placementImgs = Array.from(this.signService.sign.placementImgs);
        this.signService.sign.removalImgs = await this.pictureServiceSignRemoval.getPictureFull(this.signService.sign.id);
        this.editSign.removalImgs = Array.from(this.signService.sign.removalImgs);
      }
    }catch(error){
      console.log(error);
    }
    this.layoutService.loading = false;
  }

  async loadLists() {
    return await Promise.all([
      this.loadCode(this.signGestionService,this.signGestionOptions, null),
      this.loadCode(this.signPlacementService,this.signPlacementOptions, null),
      this.loadCode(this.signSpecificityService,this.signSpecificityOptions, null),
      this.loadCode(this.signFormatService,this.signFormatOptions, "EMPTY"),
      this.loadCode(this.signTypeService,this.signTypeOptions, "EMPTY")
    ]);
  }

  async loadCode(service, options: SelectCode[], empty:string):Promise<undefined>{
    let values:Code[] = await service.findAll();
    values = empty?[new Code(null,empty)].concat(values):values;
    let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
    translatedValues.sort((a, b) => {
      if(a.label>b.label){
        return 1;
      }else if(a.label<b.label){
        return -1;
      }else{
        return 0;
      }
    });
    translatedValues.forEach(val => options.push(val));
    return;
  }

  async submit(){
    if(!this.signForm.invalid){
      this.errorMessages = [];

      //partie suppretion des images
      this.editSign.deletedImgs = [];
      this.signService.deletedImgs.forEach(pic => {
        this.editSign.deletedImgs.push(pic)
      });
      this.signService.deletedImgs = [];


      //partie ajout des nouvelle images
      this.pictureServiceSign.imgs.forEach(pic => {
        this.editSign.imgs.push(pic);
      });
      this.pictureServiceSign.imgs = [];

      this.pictureServiceSignPlacement.imgs.forEach(pic => {
        this.editSign.placementImgs.push(pic);
      });
      this.pictureServiceSignPlacement.imgs = [];

      this.pictureServiceSignRemoval.imgs.forEach(pic => {
        this.editSign.removalImgs.push(pic);
      });
      this.pictureServiceSignRemoval.imgs = [];

      if(this.editSign.placementDate && this.editSign.removalDate && this.editSign.placementDate > this.editSign.removalDate){
        console.error('Problème entre les dates');
      }

      if(this.signService.newSupport && this.signService.newSupport.id === undefined){
        this.createSupportWithSign()

      }else{
        this.createOrUpdate();
      }

      this.signOrderService.signs = this.supportService.support.signs;

      this.initComponent();
    }
  }

  async createSupportWithSign(){
    try{
      let result: Support = await this.supportService.createWithSigns(this.signService.newSupport, [this.editSign]);
      if(result && result.id){
        this.signService.newSupport = null;
        this.supportService.support = result;
        this.signService.sign = result.signs[0];
        this.layoutService.rightPanelContent = SupportComponent;
        this.layoutService.rightPanelVisible = true;
      }
    }catch(error){
      Utils.showError(this.errorMessages, error);
    }
  }

  async createOrUpdate(){
    try {
      let sign:Sign = await this.signService.createOrUpdateSign(this.editSign);
      this.signService.sign  = new Sign(sign);
      this.signService.editMode = false;
    }catch(error){
      Utils.showError(this.errorMessages, error);
    }
  }

  cancel(){
    if(this.signService.newSupport && this.signService.newSupport.id === undefined){
      this.signService.sign = this.editSign;
      this.supportService.support = this.signService.newSupport;
      this.layoutService.rightPanelContent = SupportComponent;
      this.layoutService.rightPanelVisible = true;
    }
    this.signService.editMode = false;
    this.supportService.editMode = false;
  }

  remove(){
    this.confirmationService.confirm({
      message: '',
      accept: () => this.removeAccept()
    });
  }

  async removeAccept(){
    try{
      let val = await this.signService.deleteSign(this.signService.sign.id)
      try{
        let support = await this.supportService.getSupport(this.supportId);
        this.supportService.support = support;
        this.layoutService.rightPanelVisible = true;
        this.layoutService.rightPanelContent = SupportComponent;
      }catch(error){
        this.layoutService.closeRightPanel();
      }
    }catch(error){
      //TODO error handling
    }
  }

  async backSupport(){
    try{
      let support = await this.supportService.getSupport(this.signService.sign.supportId)
          this.supportService.support = support;
          this.layoutService.rightPanelVisible = true;
          this.layoutService.rightPanelContent = SupportComponent;
    }catch(error){
      //TODO error handling
    }
  }

  edit(){
    this.signService.editMode = true;
  }

  close(){
    this.layoutService.closeRightPanel();
  }

  expand(){
    this.layoutService.leftPanelFull = false;
    this.layoutService.rightPanelFull = !this.layoutService.rightPanelFull;
  }

  downloadRc(rc:Rc){
    alert('Not implemented');
    console.log("//TODO");
  }


}
