import {Component, Input, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {SupportService} from "../../../core/services/support.service";


@Component({
  selector: 'support-details',
  templateUrl: './support-details.component.html'
})
export class SupportDetailsComponent implements OnInit
{

  @Input()
  support: Support;

  stringAddress = '';

  constructor(private supportService: SupportService) {

  }

  ngOnInit() {
    if(this.support == null){
      this.support = this.supportService.support;
    }
    let address;
    this.stringAddress = '';
    if(this.support.address != null){
      address = JSON.parse(this.support.address);
      if(address != null){
        this.stringAddress = address.label;
      }
    }
  }

}
