import {Role} from './role.model';
import {Municipality} from './municipality.models';
import {Sign} from './sign.model';

export class User {

  public id?: number;
  public login?: string;
  public name?: string;
  public surname?: string;
  public email?: string;
  public password?: string;
  public language?: string;
  public isActive?: boolean;
  public roles?: Role[];
  public encoder?: boolean;
  public desktop?: boolean;
  public municipalities?: Municipality[];


  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
    if(obj && obj.roles){
      this.roles = obj.roles.map(role=>new Role(role));
    }
    if(obj && obj.municipalities){
      this.municipalities = obj.municipalities.map(municipality=>new Municipality(municipality));
    }
  }

  get municipality(): string{
    if(this.roles.find(role => role.code == "SPRB")){
      return "SPRB";
    }
    return this.municipalities.length>0?this.municipalities[0].name:""
  }

  get role():string{
    let userRole = '';
    let rolesAvaible = Role.rolesAvaible();
    this.roles.forEach(role => {
      switch (rolesAvaible[role.code]){
        case rolesAvaible.MUNICIPALITY_AGENT:
          userRole = rolesAvaible.MUNICIPALITY_AGENT;
          break;
        case rolesAvaible.SPRB_AGENT:
          userRole = rolesAvaible.SPRB_AGENT;
          return;
      }
    });

    return userRole;
  }

}
