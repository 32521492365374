import {Component, OnInit} from '@angular/core';
import {catchError} from 'rxjs/operators';
import { PanelTaskListComponent } from '../../tasks/panel-task-list/panel-task-list.component';
import { Task } from '../../../models/task.model';
import { User } from '../../../models/user.model';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { TaskService } from '../../../core/services/task.service';
import {Rc} from '../../../models/rc.model';
import {RcService} from '../../../core/services/rc.service';
import {PanelShowMultiRcComponent} from '../../rc/panel-show-multi-rc/panel-show-multi-rc.component';


@Component({
   selector: 'day-order-rc-button',
   templateUrl: './layout.day-order-rc-button.component.html',
   styleUrls: ['./layout.day-order-rc-button.component.scss']
})
export class DayOrderRCButtonComponent implements OnInit
{
  displayDialogDayOrder:boolean = false;

  get dayOrders(){
    return this.rcService.rcs;
  }
  set dayOrders(dayOrders:Rc[]){
    this.rcService.rcs = dayOrders;
  }

  constructor(
      private taskService: TaskService,
      private layoutService: LayoutService,
      private rcService: RcService,
      private userService : UserService) {
  }

  ngOnInit() {
  }

  async getDayOrder(){
    this.layoutService.loading = true;
    try{
      this.dayOrders = await this.rcService.getDayOrder();
    }catch(error){
      console.log('TODO HANDLE ERROR');
    }
    this.layoutService.loading = false;
  }

  async downloadDayOrder() {

    this.getDayOrder();
    this.displayDialogDayOrder = true;
  }

  showDayOrder(){
    this.displayDialogDayOrder = false;
    this.rcService.dayOrderMode = true;
    this.layoutService.rightPanelContent=PanelShowMultiRcComponent;
    this.layoutService.rightPanelVisible=true;
  }



  close(){
    this.displayDialogDayOrder = false;
  }

}
