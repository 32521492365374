import {Component, OnInit} from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { TaskService } from '../../../core/services/task.service';
import {PanelSearchRcComponent} from '../../rc/panel-search-rc/panel-search-rc.component';


@Component({
   selector: 'search-rc-button',
   templateUrl: './layout.search-rc-button.component.html',
   styleUrls: ['./layout.search-rc-button.component.scss']
})
export class SearchRCButtonComponent implements OnInit
{
  displayGrid:boolean = false;
  pendingLoad:boolean = true;

  constructor(
      private taskService: TaskService,
      private layoutService: LayoutService,
      private userService:UserService) {

  }

  ngOnInit() {

  }

  async showSearchRC() {
    this.layoutService.rightPanelContent=PanelSearchRcComponent;
    this.layoutService.rightPanelVisible=true;
  }

}
