import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {CreateSupportComponent} from "./create-support/create-support.component";
import {SupportManagementComponent} from "./support-management/support-management.component";
import {UpdateSupportComponent} from "./update-support/update-support.component";
import {StepperComponent} from "./stepper/stepper.component";
import {EditSupportAddressComponent} from "./edit-support-address/edit-support-address.component";
import {EditSupportTechnicalDataComponent} from "./edit-support-technical-data/edit-support-technical-data.component";
import {EditSupportTechnicalDataHeightComponent} from "./edit-support-technical-data-height/edit-support-technical-data-height.component";
import {SupportDetailsComponent} from "./support-details/support-details.component";
import {CreatePanelComponent} from "./create-panel/create-panel.component";
import {CreatePanelTechnicalDataComponent} from "./create-panel-technical-data/create-panel-technical-data.component";
import {CreatePanelTakePictureComponent} from "./create-panel-take-picture/create-panel-take-picture.component";
import {CreatePanelReviewComponent} from "./create-panel-review/create-panel-review.component";
import {SupportGeometrySelectionComponent} from "./support-geometry-selection/support-geometry-selection.component";
import {ViewSupportComponent} from "./view-support/view-support.component";
import {ViewPanelsOfSupportComponent} from "./view-panels-of-support/view-panels-of-support.component";
import {UpdatePanelComponent} from "./update-panel/update-panel.component";
import {ReorderPanelsComponent} from "./reorder-panels/reorder-panels.component";
import {NgxGalleryModule} from "ngx-gallery";
import {AddSignToSupportComponent} from "./add-sign-to-support/add-sign-to-support.component";
import {ConfirmationDialogComponent} from "./confirmation-dialog/confirmation-dialog.component";
import {CanThrowErrorComponent} from "./can-throw-error.component";
import {ConfirmationDialogService} from "../../core/services/confirmation-dialog.service";

const MODULES = [
  SharedModule,
  NgxGalleryModule
];

const DECLARATIONS = [
  CreateSupportComponent,
  SupportManagementComponent,
  UpdateSupportComponent,
  StepperComponent,
  EditSupportAddressComponent,
  EditSupportTechnicalDataComponent,
  EditSupportTechnicalDataHeightComponent,
  SupportDetailsComponent,
  CreatePanelComponent,
  CreatePanelTechnicalDataComponent,
  CreatePanelTakePictureComponent,
  CreatePanelReviewComponent,
  SupportGeometrySelectionComponent,
  ViewSupportComponent,
  ViewPanelsOfSupportComponent,
  UpdatePanelComponent,
  ReorderPanelsComponent,
  AddSignToSupportComponent,
  ConfirmationDialogComponent
];


@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  entryComponents: DECLARATIONS,
  providers: [ConfirmationDialogService]
})
export class MobileModule {
}
