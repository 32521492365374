import {Component, Injector, OnInit} from '@angular/core';
import {TaskService} from '../../../core/services/task.service';
import {LayoutService} from '../../../core/services/layout.service';
import {PanelShowMultiRcComponent} from '../../rc/panel-show-multi-rc/panel-show-multi-rc.component';
import {RcService} from '../../../core/services/rc.service';
import {UserService} from '../../../core/services/user.service';
import {Code, SelectCode} from '../../../models/code.model';
import {MunicipalityService} from '../../../core/services/municipality.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
   selector: 'unique-regulation-button',
   templateUrl: './layout.unique-regulation-button.component.html',
   styleUrls: ['./layout.unique-regulation-button.component.scss']
})
export class UniqueRegulationComponent implements OnInit
{

  translate: TranslateService = this.injector.get(TranslateService);

  displayCreateUniqueRegelement: boolean = false;

  municipalitiesOptions: SelectCode[] = new Array();
  municipality:Code;

  constructor(private taskService: TaskService,
              private layoutService: LayoutService,
              public rcService: RcService,
              public userService: UserService,
              private municipalityService: MunicipalityService,
              private injector: Injector,) {}

  ngOnInit() {
  }

  async createUniqueReglement() {
    this.layoutService.loading = true;
    if(this.userService.user && this.userService.user.municipalities.length > 0){
      this.rcService.municipalityId = this.userService.user.municipalities[0].id;
      this.showPanelUniqueReglement();
    }else{
      try{
        await this.loadMunicipality();
      }catch(error){
        alert("fakedata");
        this.municipalitiesOptions.push(new SelectCode("Bruxelles", new Code(1, "bxl")));
        this.municipalitiesOptions.push(new SelectCode("Schaerbeek", new Code(2, "aze")));
        this.municipalitiesOptions.push(new SelectCode("Anderlecht", new Code(3, "qsd")));
      }
      this.municipality = this.municipalitiesOptions[0].value;
      this.displayCreateUniqueRegelement = true;
    }
    this.layoutService.loading = false;
  }

  async loadMunicipality(){
    this.municipalitiesOptions.splice(0, this.municipalitiesOptions.length);
    let values:Code[] = await this.municipalityService.findAll();
    let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
    translatedValues.sort((a, b) => {
      if(a.label>b.label){
        return 1;
      }else if(a.label<b.label){
        return -1;
      }else{
        return 0;
      }
    });
    translatedValues.forEach(val => this.municipalitiesOptions.push(val));
  }

  createUniqueReglementMunicipality(){
    if(this.municipality){
      this.displayCreateUniqueRegelement = false;
      this.rcService.municipalityId = this.municipality.id;
      this.showPanelUniqueReglement();
    }
  }

  async showPanelUniqueReglement(){
    try{
      this.rcService.rcs = await this.rcService.getUniqueRegulation(this.rcService.municipalityId);
    }catch(error){
      console.log(error);
      alert("fake data");
      //TODO Remove
      let rc1 = await this.rcService.getRc(76);
      let rc2 = await this.rcService.getRc(77);
      this.rcService.rcs = [rc1, rc2];

    }

    this.rcService.ruMode = true;
    this.layoutService.rightPanelContent = PanelShowMultiRcComponent;
    this.layoutService.rightPanelVisible = true;
  }

  close(){
    this.displayCreateUniqueRegelement = false;
  }
}
