export const roleLink = {
  MUNICIPALITY_AGENT:{
    NAME:"MUNICIPALITY_AGENT",
    CREATE:true,
    UNACTIVATE:true,
    EDIT:true,
    PARTIAL_EDIT:true,
    READ:true,
    WORKFLOW:true,
    CREATE_RC:true},

  SPRB_AGENT:{
    NAME:"SPRB_AGENT",
    CREATE:true,
    UNACTIVATE:true,
    EDIT:true,
    PARTIAL_EDIT:true,
    READ:true,
    WORKFLOW:true,
    CREATE_RC:true},

  SECRETARY_CC:{
    NAME:"SECRETARY_CC",
    CREATE:false,
    UNACTIVATE:false,
    EDIT:false,
    PARTIAL_EDIT:false,
    READ:true,
    WORKFLOW:true,
    CREATE_RC:false},

  PARTNER:{
    NAME:"PARTNER",
    CREATE:false,
    UNACTIVATE:false,
    EDIT:false,
    PARTIAL_EDIT:false,
    READ:true,
    WORKFLOW:false,
    CREATE_RC:false},

  ENCODER:{
    NAME:"ENCODER",
    CREATE:false,
    UNACTIVATE:false,
    EDIT:false,
    PARTIAL_EDIT:false,
    READ:true,
    WORKFLOW:false,
    CREATE_RC:false},

  CONTRACTOR: {
    NAME: 'CONTRACTOR',
    CREATE: true,
    UNACTIVATE: true,
    EDIT: true,
    PARTIAL_EDIT: true,
    READ: true,
    WORKFLOW: false,
    CREATE_RC: false,
    RECENSE: true
  },

};
