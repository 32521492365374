import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LayoutComponent} from "app/features/layout/layout.component";
import { LoggedComponent } from '../../../shared/components/logged/logged.component';
import { UserService } from '../../../core/services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: './layout.header.component.html',
    styleUrls: ['./layout.header.component.scss']
})
export class LayoutHeaderComponent
{
    appName:string="eSign";
    constructor(public userService:UserService) {}


}
