import {Component, Injector, Input, OnInit} from '@angular/core';
import {Support} from "../../../models/support.model";
import {TranslateService} from "@ngx-translate/core";
import {Code, SelectCode} from "../../../models/code.model";
import {Sign} from "../../../models/sign.model";
import {SignFormatService} from "../../../core/services/sign-format.service";
import {SignSpecificityService} from "../../../core/services/sign-specificity.service";
import {FormBuilder} from "@angular/forms";
import {SignPlacementService} from "../../../core/services/sign-placement.service";
import {MobileWizardComponentStep} from "../mobile-wizard-step.component";
import {LayoutService} from "../../../core/services/layout.service";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {CalendarService} from "../../../core/services/calendar.service";
import {TranslationService} from "../../../core/services/translation.service";


@Component({
  selector: 'create-panel-technical-data',
  templateUrl: './create-panel-technical-data.component.html'
})
export class CreatePanelTechnicalDataComponent extends MobileWizardComponentStep implements OnInit
{
  @Input() support: Support;

  @Input() sign: Sign;

  myCalendarLocal: any;

  signFormatOptions: SelectCode[] = new Array();
  signSpecificityOptions: SelectCode[] = new Array();
  signPlacementOptions: SelectCode[] = new Array();

  translate: TranslateService = this.injector.get(TranslateService);

  constructor(private injector: Injector,
              private signFormatService: SignFormatService,
              private signSpecificityService: SignSpecificityService,
              private signPlacementService: SignPlacementService,
              layoutService: LayoutService,
              private translationService: TranslationService,
              public i18n: TranslateService,
              confirmationService: ConfirmationDialogService,
              private fb: FormBuilder) {
    super(layoutService, confirmationService);
  }

  async ngOnInit() {
    this.layoutService.loading = true;
    if(this.sign == null){
      this.sign = new Sign({});
    }
    this.form = this.fb.group({
      format: this.fb.control(''),
      placementDate: this.fb.control(''),
      specificity: this.fb.control('')
    });
    this.registerListener();
    this.myCalendarLocal = await this.translationService.localeCalendar();
    await Promise.all([
      this.loadCode(this.signFormatService,this.signFormatOptions, null),
      this.loadCode(this.signSpecificityService,this.signSpecificityOptions, null),
      this.loadCode(this.signPlacementService,this.signPlacementOptions, null)
    ]);
    if(this.mode != "EDIT"){
      this.sign.format = this.sign.format != null ? this.sign.format : this.signFormatOptions[0].value;
      this.sign.specificity = this.sign.specificity != null ? this.sign.specificity : this.signSpecificityService.default;
      this.sign.placement = this.signPlacementService.default;
    }
    this.layoutService.loading = false;
    this.loaded = true;
    this.i18n.onLangChange.subscribe(async res =>{
      this.myCalendarLocal = await this.translationService.localeCalendar();
    })
  }

  async loadCode(service, options: SelectCode[], empty:string):Promise<undefined>{
    let values:Code[] = await service.findAll();
    values = empty?[new Code(null,empty)].concat(values):values;
    let translatedValues = await Promise.all(values.map( async val=> new SelectCode(await this.translate.get(val.code).toPromise(), val)));
    translatedValues.sort((a, b) => {
      if(a.label>b.label){
        return 1;
      }else if(a.label<b.label){
        return -1;
      }else{
        return 0;
      }
    });
    translatedValues.forEach(val => options.push(val));
    return;
  }

}
