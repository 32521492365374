import {Code, SignFormat, SignGestion, SignPlacement, SignSpecificity, SignType} from './code.model';
import {Support} from './support.model';
import {Rc} from './rc.model';
import {Picture} from './picture.model';

export class Sign {

  public id?: number;
  public supportId?: number;
  public order?: number;
  public gestion?: SignGestion;
  public placement?: SignPlacement;
  public requestType?: string;
  public type?: SignType;
  public format?: SignFormat;
  public specificity?: SignSpecificity;
  public note?: string;
  public other?: string;
  public placementDate?: Date;
  public removalDate?: Date;
  public placementUser?: string;
  public removalUser?: string;
  public placementNote?: string;
  public removalNote?: string;
  public imgs?: Picture[];
  public deletedImgs?: Picture[];
  public placementImgs?: Picture[];
  public removalImgs?: Picture[];
  public genericText?: string;
  public genericTextComplementary?: string;
  public mobileStatus?:string;
  public mobileStatusDate?:Date;

  public support?:Support;
  public rcs?:Rc[];

  protected _signStatus?: Code;

  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
    this.placementDate = obj.placementDate?new Date(obj.placementDate):null;
    this.removalDate = obj.removalDate?new Date(obj.removalDate):null;
    if(obj && obj.support){
      this.support = new Support(obj.support);
    }
    if(obj && obj.rcs){
      this.rcs = new Array(obj.rcs.map(rc=>new Rc(rc)));
    }
    if(obj && obj.imgs){
      this.imgs = obj.imgs.map(img=>new Picture(img));
    }
    if(obj && obj.placementImgs){
      this.placementImgs = obj.placementImgs.map(img=>new Picture(img));
    }
    if(obj && obj.removalImgs){
      this.removalImgs = obj.removalImgs.map(img=>new Picture(img));
    }
    if(obj && obj.type){
      this.type = new SignType(obj.type.id, obj.type.code, obj.type.typeCategorie, obj.type.generic);
    }
  }

  set signStatus(status:Code){
    this._signStatus=status;
  }

  get signStatus():Code{
    return this._signStatus;
  }

  isEqual(sign: Sign): boolean{
    let change: boolean = true;
    if(this.id && sign.id){
      if(this.id != sign.id)
        change = false;
    }else{
      if(this.supportId != sign.supportId)
        change = false;
      if(this.order != sign.order)
        change = false;
      if(this.gestion && sign.gestion && this.gestion.code != sign.gestion.code)
        change = false;
      if(this.placement != sign.placement)
        change = false;
      if(this.requestType != sign.requestType)
        change = false;
      if(this.type && sign.type && this.type.code != sign.type.code)
        change = false;
      if(this.format && sign.format && this.format.code != sign.format.code)
        change = false;
      if(this.specificity && sign.specificity && this.specificity.code != sign.specificity.code)
        change = false;
      if(this.note != sign.note)
        change = false;
      if(this.other != sign.other)
        change = false;
      if(this.placementDate != sign.placementDate)
        change = false;
      if(this.removalDate != sign.removalDate)
        change = false;
    }
    return change;
  }
  get fullType(){
    let name = "";
    if(this.type){
      name = name.concat(this.type.code);
      if(this.type.typeCategorie){
        name = name.concat(". ", this.type.typeCategorie.code);
      }
    }
    return name;
  }

  get status():number{
    if(this.rcs){
      if(this.rcs.filter(rc=>rc.status == Rc.validStatus().pending).length>0){
        return 0;
      }
      if(this.rcs.filter(rc=>rc.status == Rc.validStatus().active).length>0){
        return 1;
      }
      if(this.rcs.filter(rc=>rc.status == Rc.validStatus().old).length>0){
        return -1;
      }
    }
    return null;
  }

  get isGenerique():boolean{
    if(this.type){
      return this.type.generic;
    }
    return false;
  }

}
