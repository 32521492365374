import {Component, OnInit} from '@angular/core';
import {catchError} from 'rxjs/operators';
import { PanelTaskListComponent } from '../../tasks/panel-task-list/panel-task-list.component';
import { Task } from '../../../models/task.model';
import { User } from '../../../models/user.model';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';
import { TaskService } from '../../../core/services/task.service';
import {RcService} from '../../../core/services/rc.service';
import {Rc} from '../../../models/rc.model';


@Component({
   selector: 'tasks-button',
   templateUrl: './layout.tasks-button.component.html',
   styleUrls: ['./layout.tasks-button.component.scss']
})
export class TasksButtonComponent implements OnInit
{
  displayGrid: boolean = false;
  pendingLoad:boolean = true;

  constructor(
              private taskService: TaskService,
              private rcService: RcService,
              private layoutService: LayoutService,
              private userService: UserService) {

  }

  ngOnInit() {

  }



  async showTasks() {
    this.layoutService.loading = true;
    let user:User = this.userService.user;
    try{
      let rcTasks:Rc[] = await this.rcService.getTasks();


    rcTasks = rcTasks.sort((a:Rc, b:Rc)=>{
      if (a.creationDate > b.creationDate) {
        return 1;
      }
      if (a.creationDate < b.creationDate) {
        return -1;
      }
      return 0;
    });

    this.taskService.rcTasks=rcTasks;
    this.layoutService.rightPanelContent=PanelTaskListComponent;
    this.layoutService.rightPanelVisible=true;
    }catch(error){
      console.info("Handler error", error);
    }
    this.layoutService.loading = false;

  }

}
