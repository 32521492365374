import {Component, OnDestroy, OnInit} from '@angular/core';
import {SupportService} from "../../../core/services/support.service";
import {MapService} from "../../../core/services/map.service";


@Component({
   selector: 'support-management',
   templateUrl: './support-management.component.html',
   styleUrls: ['./support-management.component.scss']
})
export class SupportManagementComponent implements OnInit
{

  constructor() {
  }

  ngOnInit() {}

}
