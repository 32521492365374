import {Component} from '@angular/core';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'logout-icon',
  templateUrl: './layout.logout-icon.component.html',
  styleUrls: ['./layout.logout-icon.component.scss']
})
export class LogoutIconComponent {
  constructor(public userService:UserService) {
  }

  disconnect(){
    this.userService.logout();
  }
  ngOnInit() {
    
  }
}
