import {Component, Injector, OnInit} from '@angular/core';
import {RcService} from '../../core/services/rc.service';
import {LayoutService} from '../../core/services/layout.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SignService} from '../../core/services/sign.service';
import {Sign} from '../../models/sign.model';
import {SupportService} from '../../core/services/support.service';
import {UserService} from '../../core/services/user.service';
import {Rc} from '../../models/rc.model';
import {Support} from '../../models/support.model';
import {Role} from '../../models/role.model';
import {TranslateService} from '@ngx-translate/core';
import {text} from '@angular/core/src/render3/instructions';

@Component({
  selector: 'rc',
  templateUrl: './rc.component.html',
  styleUrls: ['./rc.component.scss']
})
export class RcComponent implements OnInit
{
  translate: TranslateService = this.injector.get(TranslateService);

  rcForm: FormGroup;

  displayCancelRC: boolean = false;
  displayWordingMissingRC: boolean = false;
  displaySavingRC: boolean = false;

  get rc():Rc{
    return this.rcService.rc;
  }
  set rc(rc:Rc){
    this.rcService.rc = rc;
  }


  signsTypeAndRoad:{signType , roads}[] = [];

  constructor(public rcService: RcService,
              public layoutService: LayoutService,
              public signService: SignService,
              public supportService: SupportService,
              public userService: UserService,
              private fb: FormBuilder,
              private injector: Injector) {}

  async ngOnInit() {

    this.rcForm = this.fb.group({
      wording: this.fb.control(''),
      rcHead: this.fb.control(''),
      rcBody: this.fb.control(''),
      rcFooter: this.fb.control(''),
    });

    try{
      this.layoutService.loading = true;
      if(!this.rcService.rc || (this.rcService.rc && !this.rcService.rc.id)){
        let articleText = await this.translate.get('RC:ARTICLE').toPromise();
        this.rcService.rc = new Rc({});
        this.rcService.rc.header = await this.rcService.getRcTemplateGlobal('header');
        this.rcService.rc.footer = await this.rcService.getRcTemplateGlobal('footer');
        this.rcService.rc.body = "";
        let rcBodys = await this.rcService.getRcTemplateSignType(Array.from(new Set(this.rcService.signs.map(sign => sign.type.code))));
        if(rcBodys.length>0){

          this.rcService.signs = await this.signService.getSignsFull(this.rcService.signs.map(sign => sign.id));
          let i:number = 1;
          rcBodys.forEach(rcBody =>{
            let street:string = `<p>`;
            let found = false;
            let sign_text:string = ``;
            let sign_generic_text:string = `<p>`;
            this.rcService.signs.filter(sign => sign.type.code == rcBody.signType.code).forEach(sign=>{
              found = true;
              if(street.length>3){
                street = street.concat(', ');
              }
              street = street.concat(sign.support.addressLabel ? sign.support.addressLabel : sign.support.address, sign.support.number ? " "+sign.support.number+" " : " ");
              sign_text = sign.type.code;
              if(sign.genericTextComplementary){
                sign_generic_text = sign_generic_text.concat(sign.genericTextComplementary);
              }
              if(sign_generic_text.length>3){
                sign_generic_text = sign_generic_text.concat(', ');
              }
            });
            street = street.concat(`</p>`);
            sign_generic_text = sign_generic_text.concat(`</p>`);
            if(found) {
              let bodyText = ``;
              rcBody.rcTemplate.forEach( template =>{
                bodyText = bodyText.concat(`<p>` + template, `</p><p><br></p>`);
              });
              this.rcService.rc.body = this.rcService.rc.body.concat(`<div><h3>`+ articleText + ` ` + i.toString() + ` : ` + sign_text.toString() + `</h3></div>`, bodyText, street.length>7 ?`<p><br></p>`+ street : '', sign_generic_text.length>7 ?`<p><br></p>`+ sign_generic_text : '', `<p><br></p>`);
              i++;
            }
          });
        }
      }

    }catch(error){
      console.log('//TODO error handling');
    }
    this.layoutService.loading = false;
  }

  close(){
    this.layoutService.closeRightPanel();
  }

  async submit(){
    if(this.rcService.rc.title){

      if(this.userService.user.role == Role.rolesAvaible().SPRB_AGENT || this.userService.user.role == Role.rolesAvaible().MUNICIPALITY_AGENT){
        if(this.userService.user.municipalities.length>0){
          this.rcService.rc.municipalityId = this.userService.user.municipalities[0].id;
        }
        this.displaySavingRC = false;
        this.layoutService.loading = true;
        this.rcService.rc.signs = this.rcService.signs;
        try{
          let rc = await this.rcService.createOrUpdate(this.rcService.rc);
        }catch(error){
          console.info("//TODO HANDLE ERROR", error);
        }
      }else{
        this.displaySavingRC = false;
        console.info("//TODO HANDLE ERROR", "User not SPRB or Communal");
      }

      this.layoutService.loading = false;
      this.close();
    }else{
      this.displayWordingMissingRC = true;
    }
  }

  cancel(){
    this.displayCancelRC = true;
  }

  hideWordingMissingRC(){
    this.displayWordingMissingRC = false;
  }

  cancelRcYes(){
    this.layoutService.closeRightPanel();
    this.displayCancelRC = false;
  }

  cancelRcNo(){
    this.displayCancelRC = false;
  }

  validRc(){
    if(this.rcService.rc.title){
      this.displaySavingRC = true;
    }else{
      this.displayWordingMissingRC = true;
    }
  }

  validRcNo(){
    this.displaySavingRC = false;

  }

}
