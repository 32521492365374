import {Component, OnInit} from '@angular/core';
import {LayoutComponent} from '../../../features/layout/layout.component';
import { User } from '../../../models/user.model';
import { UserService } from '../../../core/services/user.service';

@Component({})
export abstract class LoggedComponent implements OnInit
{
  constructor(protected userService:UserService){
  }
  ngOnInit(): void {
    this.userService.checkLogin();
  }
  async checkLogin(): Promise<any> {
      await this.userService.checkCredentials();
      if (this.userService.hasToken()) {
        let user:User = await this.userService.getUser();
        this.userService.user = user;
        this.userService.checkRole();
      }
  }
}
