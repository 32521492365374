import {Injectable} from '@angular/core';
import {BaseAbstractService} from './base-abstract.service';
import {environment} from '../../../environments/environment';
import {Code} from '../../models/code.model';
import {Municipality} from '../../models/municipality.models';

@Injectable()
export class MunicipalityService extends BaseAbstractService
{
  _municipality: Municipality[];

  get url():string {
    return environment.backendURL + environment.identityPath+ this.configService.appConfig.apiUrl + 'User/';
  }

  async findAll(): Promise<Municipality[]> {
    this._municipality = this._municipality ? this._municipality: await this.get<Municipality[]>(`${this.url}municipalities/all`);
    return this._municipality;
  }

}
