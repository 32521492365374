import { Injectable } from '@angular/core';
import {UserService} from "./user.service";

@Injectable()
export class CalendarService {

  constructor(private userService: UserService) {

  }

  FR: any = {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: ["Janvier", "Février", "Mars", "Avril", "Mei", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"],
    today: "Aujourd'hui",
    clear: 'Vider'
  };

  NL: any = {
    firstDayOfWeek: 1,
    dayNames: ["[NL] Dimanche", "[NL]Lundi", "[NL]Mardi", "[NL]Mercredi", "[NL]Jeudi", "[NL]Vendredi", "[NL]Samedi"],
    dayNamesShort: ["[NL]Dim", "[NL]Lun", "[NL]Mar", "[NL]Mer", "[NL]Jeu", "[NL]Ven", "[NL]Sam"],
    dayNamesMin: ["[NL]Di", "[NL]Lu", "[NL]Ma", "[NL]Me", "[NL]Je", "[NL]Ve", "[NL]Sa"],
    monthNames: ["[NL]Janvier", "[NL]Février", "[NL]Mars", "[NL]Avril", "[NL]Mei", "[NL]Juin", "[NL]Juillet", "[NL]Août", "[NL]Septembre", "[NL]Octobre", "[NL]Novembre", "[NL]Décembre"],
    monthNamesShort: ["[NL]Jan", "[NL]Fev", "[NL]Mar", "[NL]Avr", "[NL]Mai", "[NL]Jui", "[NL]Jul", "[NL]Aou", "[NL]Sep", "[NL]Oct", "[NL]Nov", "[NL]Dec"],
    today: "[NL]Aujourd'hui",
    clear: '[NL]Vider'
  }

  getLocalForUser() {
    return this.userService.user.language == 'FR' ? this.FR : this.NL;
  }
}
