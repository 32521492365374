import {Component} from '@angular/core';
import {Message} from "primeng/primeng";
import {ActivatedRoute, Router} from "@angular/router";
import {routerLink} from '../../../assets/config/routerLink';
import { UserService } from '../../core/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../core/services/layout.service';

@Component({
   selector: 'login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss']
})
export class LoginComponent
{

  public credentials: any = {};
  public messageLogin: Message[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private translate: TranslateService,
    private layoutService: LayoutService) {}

  ngOnInit() {
    this.layoutService.loading = false;
    this.layoutService.closeRightPanel();
    this.layoutService.closeLeftPanel();
  }

  public async login() {
    this.layoutService.loading = true;
    this.messageLogin = [];
    try{
      await this.userService.obtainAccessToken(this.credentials);
      await this.userService.checkLogin();
      this.layoutService.loading = false;
      if(this.userService.user && this.userService.user.language)
        this.translate.use(this.userService.user.language.toLowerCase());
      this.router.navigate([routerLink.map]);
    }catch(err){
      this.layoutService.loading = false;
      console.log(err);
      let errorMsg = await this.translate.get(err).toPromise();
      this.messageLogin.push({
        severity: "error",
        detail: errorMsg,
        summary: "Erreur"
      });
    };
  }
}
