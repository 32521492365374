import { Injectable, Inject } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { BaseAbstractService } from "./base-abstract.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Session } from "../../app.session";
import { ConfigService } from "./config.service";
import { environment } from "../../../environments/environment";
import Utils from "../../shared/services/utils";

@Injectable()
export class TranslationService extends BaseAbstractService {

  _traductions: any = {};
  constructor(@Inject(TranslateService) private translate,
              _router: Router,
              _http: HttpClient,
              _session: Session,
              configService: ConfigService) {
    super(_router, _http, _session, configService);
  }

  get url(): string {
    return environment.backendURL + environment.resourcePath + this.configService.appConfig.apiUrl + 'Resource/translation';
  }

  async getTranslationFor(code: string, language: string): Promise<string> {
    !this._traductions[language] && await this.loadTranslation(language);
    return this._traductions[language][code];
  }
  async localeCalendar(){

    let sunday : string = await this.translate.get('CALANDAR:DAYNAMES:SUNDAY').toPromise();
    let monday : string = await this.translate.get('CALANDAR:DAYNAMES:MONDAY').toPromise();
    let tuesday : string = await this.translate.get('CALANDAR:DAYNAMES:TUESDAY').toPromise();
    let wednesday: string = await this.translate.get('CALANDAR:DAYNAMES:WEDNESDAY').toPromise();
    let thursday : string = await this.translate.get('CALANDAR:DAYNAMES:THURSDAY').toPromise();
    let friday : string = await this.translate.get('CALANDAR:DAYNAMES:FRIDAY').toPromise();
    let saturday : string = await this.translate.get('CALANDAR:DAYNAMES:SATURDAY').toPromise();

    let sun: string = await this.translate.get('CALANDAR:DAYNAMESSHORT:SUN').toPromise();
    let mon: string = await this.translate.get('CALANDAR:DAYNAMESSHORT:MON').toPromise();
    let tue: string = await this.translate.get('CALANDAR:DAYNAMESSHORT:TUE').toPromise();
    let wed: string = await this.translate.get('CALANDAR:DAYNAMESSHORT:WED').toPromise();
    let thu: string = await this.translate.get('CALANDAR:DAYNAMESSHORT:THU').toPromise();
    let fri: string = await this.translate.get('CALANDAR:DAYNAMESSHORT:FRI').toPromise();
    let sat: string = await this.translate.get('CALANDAR:DAYNAMESSHORT:SAT').toPromise();

    let su: string = await this.translate.get('CALANDAR:DAYNAMESMIN:SU').toPromise();
    let mo: string = await this.translate.get('CALANDAR:DAYNAMESMIN:MO').toPromise();
    let tu: string = await this.translate.get('CALANDAR:DAYNAMESMIN:TU').toPromise();
    let we: string = await this.translate.get('CALANDAR:DAYNAMESMIN:WE').toPromise();
    let th: string = await this.translate.get('CALANDAR:DAYNAMESMIN:TH').toPromise();
    let fr: string = await this.translate.get('CALANDAR:DAYNAMESMIN:FR').toPromise();
    let sa: string = await this.translate.get('CALANDAR:DAYNAMESMIN:SA').toPromise();

    let january: string = await this.translate.get('CALANDAR:MONTHNAMES:JANUARY').toPromise();
    let february: string = await this.translate.get('CALANDAR:MONTHNAMES:FEBRUARY').toPromise();
    let march: string = await this.translate.get('CALANDAR:MONTHNAMES:MARCH').toPromise();
    let april: string = await this.translate.get('CALANDAR:MONTHNAMES:APRIL').toPromise();
    let may: string = await this.translate.get('CALANDAR:MONTHNAMES:MAY').toPromise();
    let june: string = await this.translate.get('CALANDAR:MONTHNAMES:JUNE').toPromise();
    let july: string = await this.translate.get('CALANDAR:MONTHNAMES:JULY').toPromise();
    let august: string = await this.translate.get('CALANDAR:MONTHNAMES:AUGUST').toPromise();
    let september: string = await this.translate.get('CALANDAR:MONTHNAMES:SEPTEMBER').toPromise();
    let october: string = await this.translate.get('CALANDAR:MONTHNAMES:OCTOBER').toPromise();
    let november: string = await this.translate.get('CALANDAR:MONTHNAMES:NOVEMBER').toPromise();
    let december: string = await this.translate.get('CALANDAR:MONTHNAMES:DECEMBER').toPromise();

    let sjan: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:JAN').toPromise();
    let sfeb: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:FEB').toPromise();
    let smar: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:MAR').toPromise();
    let sapr: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:APR').toPromise();
    let smay: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:MAY').toPromise();
    let sjun: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:JUN').toPromise();
    let sjul: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:JUL').toPromise();
    let saug: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:AUG').toPromise();
    let ssep: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:SEP').toPromise();
    let soct: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:OCT').toPromise();
    let snov: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:NOV').toPromise();
    let sdec: string = await this.translate.get('CALANDAR:MONTHNAMESSHORT:DEC').toPromise();

    let today: string = await this.translate.get('CALANDAR:TODAY').toPromise();
    let clear: string = await this.translate.get('CALANDAR:CLEAR').toPromise();

    let localeCalendar = {
      firstDayOfWeek: 0,
      dayNames: [sunday, monday, tuesday, wednesday, thursday, friday, saturday],
      dayNamesShort: [sun, mon, tue, wed, thu, fri, sat],
      dayNamesMin: [su,mo,tu,we,th,fr,sa],
      monthNames: [ january,february,march,april,may,june,july,august,september,october,november,december ],
      monthNamesShort: [ sjan, sfeb, smar, sapr, smay, sjun, sjul, saug, ssep, soct, snov, sdec ],
      today: today,
      clear: clear
    };


    return localeCalendar;
  }

  async loadTranslation(language: string) {
    this._traductions[language] = await this.translate.getTranslation(language).toPromise();
    return;
  }

  async getTranslation(code: string) {
    return this.get(`${this.url}/${code}`);
  }

}
