import {Injectable} from '@angular/core';
import {User} from "./models/user.model";
import { Role } from './models/role.model';

@Injectable()
export class Session
{
  private STORAGE_USER: string = "user";
  private STORAGE_TOKEN: string = "token";
  private STORAGE_OAUTH_TOKEN: string = "oauth_token";

  public lastErrorCode: number;
  public lastErrorDetails: string;

  get user() : User {
    return this.parseJson(sessionStorage.getItem(this.STORAGE_USER));
  }

  set user(user: User) {
    sessionStorage.setItem(this.STORAGE_USER, JSON.stringify(user));
  }

  get roles():Role[]{
    return this.user.roles;
  }

  get token() : string {
    return sessionStorage.getItem(this.STORAGE_TOKEN)
  }

  set token(token: string) {
    sessionStorage.setItem(this.STORAGE_TOKEN, token);
  }

  get oAuthtoken()  {
    return this.parseJson(sessionStorage.getItem(this.STORAGE_OAUTH_TOKEN));
  }

  set oAuthtoken(token) {
    sessionStorage.setItem(this.STORAGE_OAUTH_TOKEN, JSON.stringify(token));
  }

  parseJson(txt:string){
    let json =null;
    try{
      json = JSON.parse(txt);
    }catch(e){}
    return json;
    
  }


  get isLogged(): boolean {
    return !!sessionStorage.getItem(this.STORAGE_TOKEN);
  }

  public invalidate(): void {
    this.user = null;
    this.oAuthtoken = null;
    this.user = null;
    this.oAuthtoken = null;
    sessionStorage.removeItem(this.STORAGE_USER);
    sessionStorage.removeItem(this.STORAGE_TOKEN);
    sessionStorage.removeItem(this.STORAGE_OAUTH_TOKEN);
  }
}
