import {Code, SignFormat, SignGestion, SignPlacement, SignSpecificity, SignType} from './code.model';
import {Support} from './support.model';
import {Rc} from './rc.model';
import {Picture} from './picture.model';
import {Sign} from './sign.model';

export class SignHistory {

  public id?: number;
  public supportId?: number;
  public order?: number;
  public gestion?: SignGestion;
  public placement?: SignPlacement;
  public requestType?: string;
  public type?: SignType;
  public format?: SignFormat;
  public specificity?: SignSpecificity;
  public note?: string;
  public other?: string;
  public placementDate?: Date;
  public removalDate?: Date;
  public placementUser?: string;
  public removalUser?: string;
  public placementNote?: string;
  public removalNote?: string;
  public imgs?: Picture[];
  public placementImgs?: any[];
  public removalImgs?: any[];
  public genericText?: string;
  public genericTextComplementary?: string;

  public support?:Support;
  public rc?:Rc;

  public signStatus?: Code;

  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
    this.placementDate = obj.placementDate?new Date(obj.placementDate):null;
    this.removalDate = obj.removalDate?new Date(obj.removalDate):null;
    if(obj && obj.support){
      this.support = new Support(obj.support);
    }
    if(obj && obj.rc){
      this.rc = new Rc(obj.rc);
    }
    if(obj && obj.imgs){
      this.imgs = obj.imgs.map(img=>new Picture(img));
    }
  }


  get fullType(){
    let name = "";
    if(this.type){
      name = name.concat(this.type.code);
      if(this.type.typeCategorie){
        name = name.concat(". ", this.type.typeCategorie.code);
      }
    }
    return name;
  }

  get status():number{
    if(this.rc){
      if(this.rc.status == Rc.validStatus().pending){
        return 0;
      }
      if(this.rc.status == Rc.validStatus().active){
        return 1;
      }
      if(this.rc.status == Rc.validStatus().old){
        return -1;
      }
    }
    return null;
  }

}
