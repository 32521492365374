import {Injectable} from '@angular/core';
import {NgViewerComponent} from "@nsi/gis-viewer-ui";
import {environment} from "../../../environments/environment";
import {BaseAbstractService} from "./base-abstract.service";
import * as ol from 'openlayers';
import { Layer, WMSLayer } from '@nsi/gis-core/dist/layer';
import { Map, View } from '@nsi/gis-core';

@Injectable()
export class GeoserverService extends BaseAbstractService
{
  _viewer: NgViewerComponent;

  set ngViewer(val:NgViewerComponent){
    this._viewer=val;
  };
  get ngViewer():NgViewerComponent{
    return this._viewer;
  };
 get map():Map{
   return this.ngViewer.viewer.mapMgr.map;
 }
 get view():View{
   return this.ngViewer.viewer.mapMgr.view;
 }
  getLayer(id):WMSLayer{
    let layer:WMSLayer = <WMSLayer> this.map.layers.filter(layer=>{
      return layer.id==id;
    })[0];
    return layer;
  }
  async identifyItems(coords:[number,number], layerId:String, projection, options):Promise<number[]>{

    let layer:WMSLayer = this.getLayer(layerId);
    let source:ol.source.ImageWMS = <ol.source.ImageWMS>(<ol.layer.Tile>layer.olLayer).getSource();
    var viewResolution = /** @type {number} */ (this.view.olView.getResolution());
    var url = source.getGetFeatureInfoUrl( coords, viewResolution, projection, options);

    let result:any = await this.getAny(url);
    let features = result.features;
    let ids:number[]=(<any[]>features).map((feature:any)=>{
      return feature.properties.id;
    });
    return ids;
  }

  async identifyPolygon(geom:ol.geom.Polygon, layerId:String, projection):Promise<number[]>{

    let layer:any =  this.getLayer(layerId);
    let source = layer.olLayer.getSource();

    var featureRequest = new ol.format.WFS().writeGetFeature({
      srsName: projection,
      featureNS: source.getUrls()[0],
      featurePrefix: (<string>source.getParams()['LAYERS']).split(':')[0],
      featureTypes: [source.getParams()['LAYERS']],
      outputFormat: 'application/json',
      filter: ol.format.filter.intersects('geom',geom)
    });
    let result:any = await fetch(source.getUrls()[0]+'?SERVICE=WFS', {
        method: 'POST',
        body: new XMLSerializer().serializeToString(featureRequest)
      });
    console.info('wfsResult',result);

    let ids:number[]=(<any[]>(await result.json()).features).map((feature:any)=>{
      return feature.properties.id;
    });
    return ids;
  }

  get url():string {
    return environment.backendURL + environment.resourcePath + 'geoserver/srpb_esign/';
  }


  getAny(url:String): Promise<any>{
    return this.get<any>(`${url}`)
  }
}
