import {Injectable} from '@angular/core';
import {BaseAbstractService} from './base-abstract.service';
import {environment} from '../../../environments/environment';
import {Rc} from '../../models/rc.model';
import {Sign} from '../../models/sign.model';
import {SignTemplate} from '../../models/sign-template.models';
import {Support} from '../../models/support.model';
import { HttpEvent } from '@angular/common/http';

@Injectable()
export class RcService extends BaseAbstractService
{

  _dayOrderMode:boolean = false;
  _ruMode:boolean = false;


  set ruMode(bol:boolean){
    this._dayOrderMode = false;
    this._ruMode = bol;
  }
  set dayOrderMode(bol:boolean){
    this._ruMode = false;
    this._dayOrderMode = bol;
  }
  get ruMode(){
    return this._ruMode;
  }
  get dayOrderMode(){
    return this._dayOrderMode;
  }


  _rc:Rc = null;
  get rc():Rc{
    return this._rc;
  }
  set rc(rc:Rc){
    this._rc = rc;
  }

  _rcs:Rc[] = null;
  get rcs():Rc[]{
    return this._rcs;
  }
  set rcs(rcs:Rc[]){
    this._rcs = rcs;
  }

  _signs: Sign[];
  get signs():Sign[]{
    return this._signs;
  }
  set signs(signs:Sign[]){
    this._signs = signs;
  }

  _editMode: boolean;
  set editMode(val:boolean){
    this._editMode = val;
  }
  get editMode():boolean{
    return this._editMode;
  }


  _rcSearchResult:Rc[];
  get rcSearchResult():Rc[]{
    return this._rcSearchResult;
  }
  set rcSearchResult(rcSearchResult:Rc[]){
    this._rcSearchResult = rcSearchResult;
  }


  _dayOrders:Rc[] = new Array();
  get dayOrders():Rc[]{
    return this._dayOrders;
  }
  set dayOrders(dayOrders:Rc[]){
    this._dayOrders = dayOrders;
  }

  get validStatus(){
    return Rc.validStatus;
  }


  _municipalityId:number;
  get municipalityId():number{
    return this._municipalityId;
  }
  set municipalityId(municipalityId:number){
    this._municipalityId = municipalityId;
  }

  fromTaskView:Rc = null;

  get url():string {
    return environment.backendURL + environment.rcPath+ this.configService.appConfig.apiUrl + 'RC/';
  }

  async getRc(id: number):Promise<Rc>{
    return new Rc(await this.get<any>(`${this.url}${id}`));
  }

  async getRCForSignId(signId:number): Promise<Rc[]> {
    return (await this.get<any[]>(`${this.url}signId/${signId}`)).map(json => new Rc(json));
  }

  getRCForSupportId(supportId:number): Promise<Rc[]> {
    return this.get<Rc[]>(`${this.url}supportId/${supportId}`);
  }

  async getGedDocument(gedId:string):Promise<any>{
    let blob:Response =  await this.getBlob(`${this.url}gedId/${gedId}`);

    let downloadUrl= URL.createObjectURL(blob);
    window.open(downloadUrl , '_blank');
  }

  async generateRcDocument(id:number){
    event.stopPropagation();
    let blob:Response =  await this.getBlob(`${this.url}generateRcDocument/${id}`);

    let downloadUrl= URL.createObjectURL(blob);
    window.open(downloadUrl , '_blank');
  }

  createOrUpdate(rc: Rc): Promise<Rc>{
    if(rc.id){
      return this.updateRc(rc);
    }
    return this.createRc(rc);
  };

  createRc(rc: Rc): Promise<Rc> {
    return this.post<Rc>(this.url, rc);
  }

  updateRc(rc: Rc): Promise<Rc> {
    return this.put<Rc>(this.url, rc);
  }

  getRcTemplateGlobal(name: string):Promise<string>{
    return this.get<string>(`${this.url}templateGlobal/${name}`);
  }

  async getRcTemplateSignType(signsTypeCodes:string[]):Promise<SignTemplate[]>{
    return (await this.post<any[]>(`${this.url}templatesSignsTypeCodes`,signsTypeCodes)).map(json => new SignTemplate(json));
  }

  async getDayOrder():Promise<Rc[]>{
    return (await this.get<any[]>(`${this.url}dayOrder`)).map(json => new Rc(json));
  }

  generateDayOrderPDF(rcs: Rc[]):Promise<any>{
    return this.post<any>(`${this.url}generateDayOrderPDF`, rcs);
  }

  async search(data: any):Promise<Rc[]>{
    return (await this.post<any[]>(`${this.url}search`, data)).map(json => new Rc(json));
  }

  async getUniqueRegulation(municipalityId: number):Promise<Rc[]>{
    return (await this.get<any[]>(`${this.url}uniqueRegulation/${municipalityId}`)).map(json => new Rc(json));
  }

  async generateWord(text: string):Promise<any>{
    let data = {
      text:text
    };

    let blob:Response =  await this.postBlob(`${this.url}generateWord`, data);

    let downloadUrl= URL.createObjectURL(blob);
    window.open(downloadUrl , '_blank');

  }

  async getTasks():Promise<Rc[]>{
    return (await this.get<any[]>(`${this.url}tasks`)).map(json => new Rc(json));
  }

}
