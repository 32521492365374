import {ViewChild} from "@angular/core";
import {StepperComponent} from "./stepper/stepper.component";
import {UserService} from "../../core/services/user.service";
import {ConfirmationDialogService} from "../../core/services/confirmation-dialog.service";
import {LayoutService} from "../../core/services/layout.service";
import {CanThrowErrorComponent} from "./can-throw-error.component";
import {TranslateService} from "@ngx-translate/core";

export class MobileWizardComponent extends CanThrowErrorComponent{

  config: any;
  stepsNumber: number;
  title = "";
  titleStepsCount = "";
  concatTitle = "";
  actualStep: number;
  componentStepTab = [];

  @ViewChild('stepper')
  stepper: StepperComponent;

  constructor(config: any,
              layoutService: LayoutService,
              confirmationDialogService: ConfirmationDialogService,
              private i18n: TranslateService){
    super(layoutService, confirmationDialogService);
    this.config = config;
  }

  getTitleForStep(id: number){
    return this.config[id].title;
  }

  initWizard() {
    for(let i = 0; i < this.stepsNumber; i++){
      this.componentStepTab[i] = false;
    }
    this.componentStepTab[0] = true;
    this.title = this.getTitleForStep(0);
    this.titleStepsCount = "(1/"+this.stepsNumber+")";
    this.concatTitle = this.title + " " + this.titleStepsCount;
    this.actualStep = 0;
    this.i18n.onLangChange.subscribe(async res =>{
      this.refreshTitle();
    })
  }

  async refreshTitle() {
    await this.initConfig();
    this.concatTitle = this.getTitleForStep(this.actualStep) + " " +this.titleStepsCount;
  }

  stepperMoved(actualStep){
    if(actualStep != -1){
      this.resetTab();
      this.actualStep = actualStep-1;
      this.componentStepTab[actualStep-1] = true;
      this.titleStepsCount = "("+actualStep+"/"+this.stepsNumber+")";
      this.concatTitle = this.getTitleForStep(actualStep-1) + " " +this.titleStepsCount;
    }else{
      this.save();
    }
  }

  childValidityChanged(event) {
    this.stepper.forwardDisabled = !event.validity;
  }

  async save() {}

  async initConfig(){}

  resetTab(){
    for(let i = 0; i < this.stepsNumber; i++){
      this.componentStepTab[i] = false;
    }
  }

}
