import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {ConfigService} from './config.service';
import {Sign} from '../../models/sign.model';
import {environment} from '../../../environments/environment';
import {BaseAbstractService} from './base-abstract.service';
import {Support} from '../../models/support.model';
import {Subject} from "rxjs/Rx";
import Utils from '../../shared/services/utils';
import {Rc} from '../../models/rc.model';
import {SignHistory} from '../../models/sign-history.model';
import {Picture} from '../../models/picture.model';

@Injectable()
export class SignService extends BaseAbstractService
{

  MOBILE_STATUS_ADD = 'ADD';
  MOBILE_STATUS_EDIT = 'EDIT';
  MOBILE_STATUS_DELETE = 'DELETE';

  _sign: Sign;
  private signSource = new Subject<Sign>();
  signHandler:Observable<Sign> = <Observable<Sign>>this.signSource.asObservable();

  set sign(sign:Sign){
    this._sign = sign;
    this.signSource.next(sign);
  }
  get sign():Sign{
    return this._sign;
  }

  _newSupport: Support;
  get newSupport():Support{
    return this._newSupport;
  }
  set newSupport(support : Support){
    this._newSupport = support;
  }


  _addToSupportMode: string;
  set addToSupportMode(val:string){
    this._addToSupportMode = val;
  }
  get addToSupportMode():string{
    return this._addToSupportMode;
  }

  _editMode: boolean;
  set editMode(val:boolean){
    this._editMode = val;
  }
  get editMode():boolean{
    return this._editMode;
  }

  _readMode: boolean;
  set readMode(val:boolean){
    this._readMode = val;
  }
  get readMode():boolean{
    return this._readMode;
  }

  _signs: Sign[];
  private signsSource = new Subject<Sign[]>();
  signsHandler:Observable<Sign[]> = <Observable<Sign[]>>this.signsSource.asObservable();
  get signs():Sign[]{
    return this._signs;
  }
  set signs(signs:Sign[]){
    //let tmpSigns = Array.from(new Set(signs.map(sign => new Sign(sign))));
    let tmpSigns = Utils.makeUniqueArray(signs);
    this._signs = tmpSigns;
    this.signsSelectedSource.next(tmpSigns);

  }

  _signsSelected: Sign[] = [];
  private signsSelectedSource = new Subject<Sign[]>();
  signsSelectedHandler:Observable<Sign[]> = <Observable<Sign[]>>this.signsSelectedSource.asObservable();
  get signsSelected():Sign[]{
    return this._signsSelected;
  }
  set signsSelected(signs:Sign[]){
    //let tmpSigns = Array.from(new Set(signs.map(sign => new Sign(sign))));
    let tmpSigns = Utils.makeUniqueArray(signs);
    this._signsSelected = tmpSigns;

    this.signsSelectedSource.next(tmpSigns);
  }

  cleanPicture(sign:Sign) {
    if(sign.imgs) {
      sign.imgs.forEach(pic => {
        if (pic.gedId) {
          pic.file = null;
        }
      });
    }

    if(sign.placementImgs) {
      sign.placementImgs.forEach(pic => {
        if (pic.gedId) {
          pic.file = null;
        }
      });
    }
    if(sign.removalImgs) {
      sign.removalImgs.forEach(pic => {
        if (pic.gedId) {
          pic.file = null;
        }
      });
    }
  }

  signOrder = new Array();

  deletedImgs: Picture[] = [];

  get url():string {
    return environment.backendURL + environment.signPath+ this.configService.appConfig.apiUrl + 'Sign/';
  }
  get urlHistory():string {
    return environment.backendURL + environment.signPath+ this.configService.appConfig.apiUrl + 'Sign/history/';
  }


  async getSign(id: number): Promise<Sign>{
    let jsonSign:Sign = await this.get<any>(`${this.url}${id}`);
    return new Sign(jsonSign);
  }

  async getSignFull(id: number): Promise<Sign>{
    let jsonSign:Sign = await this.get<any>(`${this.url}full/${id}`);
    return new Sign(jsonSign);
  }

  async getSigns(ids: number[]): Promise<Sign[]>{
    return (await this.get<any[]>(`${this.url}/ids`,ids)).map(json => new Sign(json));
  }

  async getSignsFull(ids: number[]): Promise<Sign[]>{
    return (await this.post<any[]>(`${this.url}full/ids`,ids)).map(json => new Sign(json));
  }

  async getSignsForSupportId(supportId: number): Promise<Sign[]>{
    return (await this.get<any[]>(`${this.url}supportId/${supportId}`)).map(json => new Sign(json));
  }

  async getSignsFullForSupportId(supportId: number): Promise<Sign[]>{
    return (await this.get<any[]>(`${this.url}full/supportId/${supportId}`)).map(json => new Sign(json));
  }

  async getSignsForSupportsIds(supportIds:number[]): Promise<Sign[]>{
    return (await this.post<any[]>(`${this.url}supportIds`, supportIds)).map(json => new Sign(json));
  }

  async getSignsFullForSupportsIds(supportIds:number[]): Promise<Sign[]>{
    return ( await this.post<any[]>(`${this.url}full/supportIds`, supportIds)).map(sign => new Sign(sign));
  }

  createOrUpdateSign(sign: Sign): Promise<Sign>{
    let tmpSign = new Sign(sign);
    this.cleanPicture(tmpSign);

    if (!tmpSign.id){
      return this.createSign(tmpSign);
    }else{
      return this.updateSign(tmpSign);
    }
  }

  async createSign(sign: Sign): Promise<Sign> {
    sign.mobileStatusDate = new Date();
    let jsonSign:Sign = await this.post<any>(this.url, sign);
    return new Sign(jsonSign);
  }

  async updateSign(sign: Sign): Promise<Sign> {
    sign.mobileStatusDate = new Date();
    let jsonSign:Sign = await this.put<Sign>(this.url, sign);
    return new Sign(jsonSign);
  }

  async findAllSign(): Promise<Sign[]> {
    return (await this.get<Sign[]>(`${this.url}all/`)).map(json => new Sign(json));
  }

  async deleteSign(id: number): Promise<Sign> {
    let jsonSign:Sign = await this.delete<any>(`${this.url}${id}`);
    return new Sign(jsonSign);
  }

  async findOldSignsForSupport(supportId: number): Promise<Sign[]>{
    return (await this.get<any[]>(`${this.url}old/support/${supportId}`)).map(json => new Sign(json));
  }

  async search(data): Promise<Sign[]>{
    return (await this.post<any[]>(`${this.url}search`, data)).map(json => new Sign(json));
  }

  async searchFull(data): Promise<Sign[]>{
    return (await this.post<any[]>(`${this.url}search/full`, data)).map(json => new Sign(json));
  }

  // async export(data:number[]): Promise<any>{
  //   return this.post<any[]>(`${this.url}export`, data);
  // }

  async export(data:number[]){
    let blob:Response =  await this.postBlob(`${this.url}export`, data);

    let downloadUrl= URL.createObjectURL(blob);
    window.open(downloadUrl , '_blank');
  }

  async getSignsForRcIds(rcIds:number[]): Promise<Sign[]>{
    return (await this.post<any[]>(`${this.url}rcIds`, rcIds)).map(json => new Sign(json));
  }

  async getSignsFullForRcIds(rcIds:number[]): Promise<Sign[]>{
    return ( await this.post<any[]>(`${this.url}full/rcIds`, rcIds)).map(sign => new Sign(sign));
  }




  //HISTORY
  async historyGetSign(id: number): Promise<SignHistory>{
    let jsonSign:SignHistory = await this.get<any>(`${this.urlHistory}${id}`);
    return new SignHistory(jsonSign);
  }

  async historyGetSignFull(id: number): Promise<SignHistory>{
    let jsonSign:SignHistory = await this.get<any>(`${this.urlHistory}full/${id}`);
    return new SignHistory(jsonSign);
  }

  async historyGetSigns(ids: number[]): Promise<SignHistory[]>{
    return (await this.get<any[]>(`${this.urlHistory}/ids`,ids)).map(json => new SignHistory(json));
  }

  async historyGetSignsFull(ids: number[]): Promise<SignHistory[]>{
    return (await this.post<any[]>(`${this.urlHistory}full/ids`,ids)).map(json => new SignHistory(json));
  }

  async historyGetSignsByRcIds(rcIds:number[]): Promise<SignHistory[]> {
    return (await this.post<any[]>(`${this.urlHistory}rcIds`, rcIds)).map(json => new SignHistory(json));
  }

  async historyGetSignsFullByRcIds(rcIds:number[]): Promise<SignHistory[]>{
    return ( await this.post<any[]>(`${this.urlHistory}full/rcIds`, rcIds)).map(sign => new SignHistory(sign));
  }

}
