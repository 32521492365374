import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {OauthService} from './oauth.service';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { Router } from '@angular/router';
import { Session } from '../../app.session';
import {reject} from 'q';
import {UserService} from "./user.service";

@Injectable()
export class BaseAbstractService extends OauthService
{
  constructor(protected _router: Router,
              protected _http: HttpClient,
              protected _session: Session,
              protected configService: ConfigService) {
                super(_router,_http,_session);
  }

  async get<T>(url:string, options?):Promise<any>{
    options = await this.handleOAuthHeader(options);
    return new Promise<any>((resolve,reject)=>this._http.get<T>(url, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

  async getBlob(url:string, options?):Promise<Response> {
    options = await this.handleOAuthHeader(options);
    options.responseType =  'blob';
    return new Promise<any>((resolve,reject)=>this._http.get<Response>(url, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }
  async postBlob(url:string, data , options?):Promise<Response> {
    options = await this.handleOAuthHeader(options);
    options.responseType =  'blob';
    return new Promise<any>((resolve,reject)=>this._http.post<Response>(url, data, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }



  async post<T>(url:string, data ,options?):Promise<any>{
    options = await this.handleOAuthHeader(options);
    return new Promise<any>((resolve,reject)=>this._http.post<T>(url, data, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

  async put<T>(url:string, data, options?):Promise<any>{
    options = await this.handleOAuthHeader(options);
    return new Promise<any>((resolve,reject)=>this._http.put<T>(url, data, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

  async delete<T>(url:string, options?):Promise<any>{
    options = await this.handleOAuthHeader(options);
    return new Promise<any>((resolve,reject)=>this._http.delete<T>(url, options).subscribe(val=>resolve(val),reason=>reject(reason)));
  }

}
