import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SupportService} from "../../../core/services/support.service";
import {Support} from "../../../models/support.model";
import {MobileWizardComponent} from "../mobile-wizard.component";
import {LayoutService} from "../../../core/services/layout.service";
import {ViewSupportComponent} from "../view-support/view-support.component";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationDialogService} from "../../../core/services/confirmation-dialog.service";
import {MapService} from "../../../core/services/map.service";
import {SupportGeometrySelectionComponent} from "../support-geometry-selection/support-geometry-selection.component";


@Component({
   selector: 'update-support',
   templateUrl: './update-support.component.html',
   styleUrls: ['./update-support.component.scss']
})
export class UpdateSupportComponent extends MobileWizardComponent implements OnInit
{
  selectedSupport: Support;
  translate: TranslateService = this.injector.get(TranslateService);
  @ViewChild('geometrySelection')
  geometrySelection: SupportGeometrySelectionComponent;

  constructor(private supportService: SupportService,
              private injector: Injector,
              private mapService: MapService,
              confirmationService: ConfirmationDialogService,
              i18n: TranslateService,
              layoutService: LayoutService) {
    super({}, layoutService, confirmationService, i18n);
    this.stepsNumber = 4;
  }

  async ngOnInit() {
    await this.initConfig();
    this.initWizard();
    this.selectedSupport = this.supportService.support;
  }

  async initConfig() {
    let title = await this.translate.get('UPDATE_SUPPORT').toPromise();
    this.config = {
      0: {
        title: title
      },
      1: {
        title: title
      },
      2: {
        title: title
      },
      3: {
        title: title
      }
    }
  }

  onClose = function() {
    this.geometrySelection.ngOnDestroy();
    this.backToViewSupport();
  }.bind(this);

  async save() {
    try{
      this.layoutService.loading = true;
      await this.supportService.updateSupport(this.selectedSupport);
      this.layoutService.loading = false;
      this.layoutService.rightPanelVisible = true;
      this.layoutService.rightPanelContent = ViewSupportComponent;
      this.mapService.refresh();
      this.layoutService.loading = false;
    }catch(e){
      this.confirmationDialogService.yesCallBack = this.errorCallBack.bind(this);
      this.onError();
    }
  }

  errorCallBack() {
    this.dismissErrorDialog();
    this.backToViewSupport();
  }

  async backToViewSupport() {
    this.layoutService.loading = true;
    this.layoutService.rightPanelVisible = true;
    this.layoutService.rightPanelContent = ViewSupportComponent;
    this.layoutService.loading = false;
  }

}
