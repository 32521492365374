import {Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewChild} from '@angular/core';
import {MapMenuContentDirective} from '../../../features/map/map.menu.content.directive';
import {MapService} from '../../../core/services/map.service';
import { ContainerContentDirective } from './container.content.directive';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'nsi-container',
  template: `<ng-template nsi-container-content></ng-template>`
})
export abstract class ContainerComponent implements OnInit
{

  reset: boolean;
  component:ComponentRef<any>;

  @ViewChild(ContainerContentDirective) 
  content:ContainerContentDirective;

  constructor(protected componentFactoryResolver:ComponentFactoryResolver) {}

  abstract  get contentHandler():Observable<any>;
  contentChanges:Subscription;
  setContent(component){
    let compFact = this.componentFactoryResolver.resolveComponentFactory(component);
    let viewContainerRef = this.content.viewContainerRef;
    if(this.reset){
      viewContainerRef.clear();
    }
    if (this.component){
      //TODO remove the component because the handler is still active
      this.component.destroy();
    }
    this.component = viewContainerRef.createComponent(compFact);
  }

  ngOnInit() {
    this.contentChanges = this.contentHandler.subscribe((component:any)=>{
      this.setContent(component);
    });
  }
  ngOnDestroy() {
    this.contentChanges.unsubscribe();
  }
}
