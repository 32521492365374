import {Component, Injector, OnInit} from '@angular/core';
import {LayoutService} from '../../../core/services/layout.service';
import {MunicipalityService} from '../../../core/services/municipality.service';
import {Code, SelectCode} from '../../../models/code.model';
import {Municipality} from '../../../models/municipality.models';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Sign} from '../../../models/sign.model';
import {Rc} from '../../../models/rc.model';
import {Message} from 'primeng/components/common/api';
import {RcService} from '../../../core/services/rc.service';
import {Role} from '../../../models/role.model';
import {UserService} from '../../../core/services/user.service';
import {PanelSearchRcResultComponent} from '../panel-search-rc-result/panel-search-rc-result.component';

@Component({
   selector: 'panel-search-rc',
   templateUrl: './panel-search-rc.component.html',
   styleUrls: ['./panel-search-rc.component.scss']
})
export class PanelSearchRcComponent implements OnInit
{

  translate: TranslateService = this.injector.get(TranslateService);
  errorMessages: Message[] = [];

  rcSearch = {
    municipality: [],
    title: null,
    status: null,
    introductionDateStart: null,
    introductionDateEnd: null,
  };

  showCommune:boolean = true;

  searchRcForm: FormGroup;

  municipalityOptions: SelectCode[] = new Array();

  displayNoResult:boolean = false;

  constructor(private layoutService: LayoutService,
              private rcService: RcService,
              private municipalityService: MunicipalityService,
              private userService: UserService,
              private injector: Injector,
              private fb: FormBuilder,) {}

  ngOnInit() {
    this.initComponent();

    this.searchRcForm = this.fb.group({
      municipalities: this.fb.control(''),
      title: this.fb.control(''),
      status: this.fb.control(''),
      introductionDate: this.fb.control(''),
    });
  }

  async initComponent(){

    this.layoutService.loading = true;
    try{
      this.rcSearch = {
        municipality: [],
        title: null,
        status: null,
        introductionDateStart: null,
        introductionDateEnd: null,
      };
      await this.loadLists();

    }catch(e){

    }
    this.layoutService.loading = false;

  }

  async loadLists(){
    return await Promise.all([
      this.loadMunicipality(this.municipalityService,this.municipalityOptions)
    ]);
  }

  async loadMunicipality(service, options: SelectCode[]):Promise<undefined>{
    try{
      options.splice(0,options.length);
      if(this.userService.user.role == Role.rolesAvaible().MUNICIPALITY_AGENT){
        this.showCommune = false;
        this.rcSearch.municipality = [new Code(this.userService.user.municipalities[0].id, this.userService.user.municipalities[0].name)];
      }else{
        let values:Municipality[] = await service.findAll();
        values.sort((a, b) => {
          if(a.name>b.name){
            return 1;
          }else if(a.name<b.name){
            return -1;
          }else{
            return 0;
          }
        });
        options.push(new SelectCode(await this.translate.get("ALL").toPromise(), new Code(0,"all")));
        values.forEach(val => {
          let selectCode:SelectCode = new SelectCode (val.name, new Code(val.id, val.name));
          options.push(selectCode);
        });
        this.rcSearch.municipality = [new Code(0, "all")];
      }

    }catch(error){
      //TODO REMOVE
      options.push(new SelectCode(await this.translate.get("ALL").toPromise(), new Code(0, "all")));
      options.push(new SelectCode("Bruxelle", new Code(1, "Bruxelle")));
      options.push(new SelectCode("Anderlecht", new Code(2, "Anderlecht")));
      options.push(new SelectCode("Scharbeek", new Code(3, "Scharbeek")));
      this.rcSearch.municipality = [new Code(0, "all")];
    }


    return;
  }

  async submit(){
    if(!this.searchRcForm.invalid){
      this.layoutService.loading = true;
      this.errorMessages = [];

      try {
        this.rcService.rcSearchResult = await this.rcService.search(this.rcSearch);
        if(this.rcService.rcSearchResult.length==0){
          this.displayNoResult = true;
        }else{
          this.layoutService.leftPanelContent = PanelSearchRcResultComponent;
          this.layoutService.leftPanelVisible = true;
          this.layoutService.loading = false;
        }
      }catch(error){
        console.log(error);
        if(typeof(error.error) == typeof("string")){
          this.errorMessages.push({severity:'error', summary:'Error', detail:error.error});
        }else{
          this.errorMessages.push({severity:'error', summary:'Error', detail:error.message});
        }

        //TODO Remove
        let rc1 = await this.rcService.getRc(76);
        let rc2 = await this.rcService.getRc(77);
        this.rcService.rcSearchResult = [rc1, rc2];
        this.layoutService.leftPanelContent = PanelSearchRcResultComponent;
        this.layoutService.leftPanelVisible = true;
        this.layoutService.loading = false;

      }
    }

  }

  reset(){
    this.initComponent();
  }



  close(){
    this.layoutService.closeRightPanel();
  }
}
