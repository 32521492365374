import {Injectable} from '@angular/core';
import {Subject} from '../../../../node_modules/rxjs';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class LayoutService
{

  private leftPanelContentSource = new Subject<any>();
  leftPanelContentHandler:Observable<number[]> = <Observable<number[]>>this.leftPanelContentSource.asObservable();

  set leftPanelContent(content:any){
    this.leftPanelContentSource.next(content);
  }

  _leftPanelVisible:boolean=false;
  private visibilityLeftPanelSource = new Subject<boolean>();

  visibilityLeftPanelHandler:Observable<boolean> = <Observable<boolean>>this.visibilityLeftPanelSource.asObservable();

  set leftPanelVisible(val:boolean){
    this.leftPanelClosed = (this.leftPanelClosed && !val);
    this._leftPanelVisible = val;
    this.visibilityLeftPanelSource.next(val);
  }
  get leftPanelVisible():boolean{
    return this._leftPanelVisible && !this.leftPanelClosed;
  }

  _leftPanelFull: boolean = false;
  private leftPanelFullSource = new Subject<boolean>();

  leftPanelFullHandler:Observable<boolean> = <Observable<boolean>>this.leftPanelFullSource.asObservable();

  set leftPanelFull(val:boolean){
    this._leftPanelFull = val;
    this.leftPanelFullSource.next(val);
  }
  get leftPanelFull():boolean{
    return this._leftPanelFull && !this.leftPanelClosed;
  }

  _leftPanelClosed: boolean = true;
  private leftPanelClosedSource = new Subject<boolean>();

  leftPanelHideHandler:Observable<boolean> = <Observable<boolean>>this.leftPanelClosedSource.asObservable();

  set leftPanelClosed(val:boolean){
    this._leftPanelClosed = val;
    this.leftPanelClosedSource.next(val);
  }
  get leftPanelClosed():boolean{
    return this._leftPanelClosed;
  }

  closeLeftPanel(){
    this.leftPanelFull = false;
    this.leftPanelVisible = false;
    this.leftPanelClosed = true;
  }

  hideLeftPanel() {
    this.leftPanelVisible = false;
  }

  private rightPanelContentSource = new Subject<any>();
  rightPanelContentHandler:Observable<any> = <Observable<any>>this.rightPanelContentSource.asObservable();

  set rightPanelContent(content:any){
    this.rightPanelContentSource.next(content);
  }

  _rightPanelVisible:boolean=false;
  private visibilityRightPanelSource = new Subject<boolean>();

  visibilityRightPanelHandler:Observable<boolean> = <Observable<boolean>>this.visibilityRightPanelSource.asObservable();

  set rightPanelVisible(val:boolean){
    this.rightPanelClosed = (this.rightPanelClosed && !val);
    this._rightPanelVisible = val;
    this.visibilityRightPanelSource.next(val);
  }
  get rightPanelVisible():boolean{
    return this._rightPanelVisible;
  }

  _rightPanelFull: boolean = false;
  private rightPanelFullSource = new Subject<boolean>();

  rightPanelFullHandler:Observable<boolean> = <Observable<boolean>>this.rightPanelFullSource.asObservable();

  set rightPanelFull(val:boolean){
    this._rightPanelFull = val;
    this.rightPanelFullSource.next(val);
  }
  get rightPanelFull():boolean{
    return this._rightPanelFull;
  }

  _rightPanelClosed: boolean = true;
  private rightPanelClosedSource = new Subject<boolean>();

  rightPanelClosedHandler:Observable<boolean> = <Observable<boolean>>this.rightPanelClosedSource.asObservable();

  set rightPanelClosed(val:boolean){
    this._rightPanelClosed = val;
    this._rightPanelVisible = !val;
    this.rightPanelClosedSource.next(val);
  }
  get rightPanelClosed():boolean{
    return this._rightPanelClosed;
  }

  closeRightPanel(){
    this.rightPanelFull = false;
    this.rightPanelVisible = false;
    this.rightPanelClosed = true;
  }

  private actionIconsContentSource = new Subject<any>();
  actionIconsContentHandler:Observable<any> = <Observable<any>>this.actionIconsContentSource.asObservable();

  set actionIconsContent(content:any){
    this.actionIconsContentSource.next(content);
  }

  _actionIconsVisible:boolean=false;
  private visibilityActionIconsSource = new Subject<boolean>();

  visibilityActionIconsHandler:Observable<boolean> = <Observable<boolean>>this.visibilityActionIconsSource.asObservable();

  set actionIconsVisible(val:boolean){
    this._actionIconsVisible = val;

    this.visibilityActionIconsSource.next(val);
  }
  get actionIconsVisible():boolean{
    return this._actionIconsVisible;
  }

  private _loading:boolean = false;

  get loading():boolean{
    return this._loading;
  }
  set loading(val:boolean){
    this._loading = val;
  }

  private _backComponent: any;

  get backComponent():any{
    return this._backComponent;
  }
  set backComponent(val:any){
    this._backComponent = val;
  }

}
