export class Picture{
  public gedId?: string;
  public file?: string; //base64
  public objectId?: number;
  public name?: string;

  constructor(
    obj:any
  ) {
    Object.assign(this,obj);
  }
}
