import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {SignsComponent} from "./signs.component";
import {SignComponent} from './sign/sign.component';
import {SignsRoutingModule} from './signs-routing.module';
import {PanelSearchSignComponent} from './panel-search-sign/panel-search-sign.component';

const MODULES = [
  SharedModule,
  SignsRoutingModule
];

const DECLARATIONS = [
  SignsComponent,
  SignComponent,
  PanelSearchSignComponent
];


@NgModule({
    imports: MODULES,
    declarations: DECLARATIONS,
    entryComponents: [SignComponent, PanelSearchSignComponent]
})
export class SignsModule {}
