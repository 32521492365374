import {Component} from '@angular/core';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'profil-icon',
  templateUrl: './layout.profil-icon.component.html',
  styleUrls: ['./layout.profil-icon.component.scss']
})
export class ProfilIconComponent {
  constructor(public userService:UserService) {
  }

  showProfile(){
    alert('No implemented yet SHOW PROFILE');
  }
  ngOnInit() {
    
  }
}
