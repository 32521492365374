import {Injectable} from '@angular/core';
import {Subject} from '../../../../node_modules/rxjs';
import {Observable} from 'rxjs/Observable';
import {Sign} from '../../models/sign.model';

@Injectable()
export class SignOrderService
{

  _signs: Sign[];
  private signsSource = new Subject<Sign[]>();
  signsHandler:Observable<Sign[]> = <Observable<Sign[]>>this.signsSource.asObservable();

  set signs(signs:Sign[]){
    this._signs = signs;
    this.signsSource.next(signs);
  }
  get signs():Sign[]{
    return this._signs;
  }

   constructor() {}
}
